<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="search-hero">
        <label>Search:</label>
        <input
          class="form-control"
          type="text"
          name="search"
          autocomplete="off"
          placeholder="&#61442;  Start searching"
          (input)="search($event.target.value)"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <!--div class="col-6">
      <label>Sectors:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="sectorText">
        <option *ngFor="let sector of sectors"  [value]="sector.name">{{sector.name}}</option>
      </select>
    </div>

    <div class="col-6">
      <label>Annual Contribution Revenue:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="annualText">
        <option *ngFor="let annual of annuals"  [value]="annual.name">{{annual.name}}</option>
      </select>
    </div>

    <div class="col-6">
      <label># of Users:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="usersText">
        <option *ngFor="let user of users"  [value]="user.name">{{user.name}}</option>
      </select>
    </div>

    <div class="col-6">
      <label>Overall Rating:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="ratingText">
        <option *ngFor="let rating of ratings"  [value]="rating.name">{{rating.name}}</option>
      </select>
    </div-->

    <!--div class="col-6">
      <label>Categories:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="catText">
        <option *ngFor="let category of categories"  [value]="category.name">{{category.name}}</option>
      </select>
    </div-->
  </div>
</div>

<div class="container  text-center">
  <h2 class="bigtitle"><strong>{{this.title}}</strong></h2>

  <div class="container">
    Sort by:
    <div class="btn-group sort-btn" (click)="sortBy()">
      <button
        class="btn btn-primary"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style="text-transform: capitalize;"
      >
         {{sortByField}}
      </button>
      <button class="btn btn-primary " data-sort="none" >
        <i class="fa fa-sort" *ngIf="!sortByFirst"></i>
        <i class="fa fa-sort-asc" *ngIf="sortByFirst && sortByQuery === 'asc'"></i>
        <i class="fa fa-sort-desc" *ngIf="sortByFirst && sortByQuery === 'desc'"></i>
      </button>
      <!-- .dropdown-toggle adds rounded borders and reduces padding. It does not trigger dropdowns. -->
      <ul class="dropdown-menu">
        <li (click)="changeSort('name')">
          <a tabindex="-1" data-type="alpha">Name</a>
        </li>
        <li (click)="changeSort('rating')">
          <a tabindex="-1" data-type="numeric">Rating</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div
      *ngFor="let hero of heroes | filter:searchText | filter:catText | filter:sectorText"
      style="padding:1em"
      class="col-12"
    >
      <div>
        <div class="card">
          <div class="card-body" style="padding: 1em;">
            <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-home-{{hero.id}}-tab"
                  data-toggle="pill"
                  href="#pills-home-{{hero.id}}"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  >{{hero.name}}</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-contact-{{hero.id}}-tab"
                  data-toggle="pill"
                  href="#pills-contact-{{hero.id}}"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                  >Pricing & Demo</a
                >
              </li>
            </ul>
            <div class="tab-content" id="pills-tab-{{hero.id}}-Content">
              <div
                class="tab-pane fade show active"
                id="pills-home-{{hero.id}}"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                style="padding:1em"
              >
                <div class="row">
                  <div class="col-md-3">
                    <div>
                      <img
                        style="padding:1em"
                        class="card-img-top"
                        src="{{hero.img}}"
                        width="128em"
                        alt="Card image cap"
                      />
                    </div>
                    <div>
                      <strong>{{hero.category}}</strong>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="dantdesc">
                        {{hero.bio}}
                      </div>
                    </div>
                    <div class="row" style="padding: 1em">
                      <div class="col-md-6">
                        <strong
                          ><p class="dantRatingP">
                            Overall Rating: {{hero.rating}}
                          </p></strong
                        >
                      </div>
                      <div class="col-md-6">
                        <a
                          class="navbar-brand"
                          routerLink="/crm-reviews"
                          [queryParams]="{search: hero.name}"
                        >
                          <strong
                            ><p class="dantReviewP">
                              Number of Reviews: {{hero.review}}
                            </p></strong
                          >
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <button class="btn btn-md btn-outline-success rightButton" style="float: right;" type="button" (click)="openChat()">Get Expert Advice Now</button>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact-{{hero.id}}"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <form
                  id="contact-form"
                  [formGroup]="addEditForm"
                  (ngSubmit)="onSubmit()"
                  role="form"
                >
                  <div class="messages"></div>
                  <div class="controls" style="padding:1em">
                    <div class="row">
                      <div class="col-md-12">
                        <p>
                          To receive pricing and information on scheduling a
                          demonstration with the vendor, please tell us a little
                          about yourself.
                        </p>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_name">First Name *</label>
                          <input
                            id="form_name"
                            type="text"
                            formControlName="name"
                            class="form-control"
                            placeholder="Please enter your first name *"
                            required="required"
                            data-error="Firstname is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_lastname">Last Name *</label>
                          <input
                            id="form_lastname"
                            type="text"
                            formControlName="surname"
                            class="form-control"
                            placeholder="Please enter your last name *"
                            required="required"
                            data-error="Lastname is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_email">Email *</label>
                          <input
                            id="form_email"
                            type="email"
                            formControlName="email"
                            class="form-control"
                            placeholder="Please enter your email *"
                            required="required"
                            data-error="Valid email is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_need">Organization Name *</label>
                          <input
                            id="form_name"
                            type="text"
                            formControlName="org_name"
                            class="form-control"
                            placeholder="Please enter your organization name *"
                            required="required"
                            data-error="Firstname is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="form_message">Message *</label>
                          <textarea
                            id="form_message"
                            formControlName="message"
                            class="form-control"
                            placeholder="Please share your needs or concerns so we can best help you *"
                            rows="4"
                            required="required"
                            data-error="Please, leave us a message."
                          ></textarea>
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-12" *ngIf="addEditForm.valid">
                        <input
                          type="submit"
                          class="btn btn-success btn-send"
                          value="Submit"
                        />
                      </div>
                    </div>
                    <div class="row" *ngIf="addEditForm.invalid">
                      <div class="col-md-12">
                        <p class="text-muted">
                          <strong>*</strong> These fields are required
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
