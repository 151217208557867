<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="search-hero">
        <label>Search:</label>
        <input
          class="form-control"
          type="text"
          name="search"

          [(ngModel)]="searchText"
          (input)="search($event.target.value)"
          autocomplete="off"
          placeholder="&#61442;"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <label>Sectors:</label>
      <select
        name="city"
        class="form-control"
        (change)="onChange($event.target.value)"
        [(ngModel)]="sectorText"
      >
        <option *ngFor="let sector of sectors" [value]="sector.name"
          >{{sector.name}}</option
        >
      </select>
    </div>

    <div class="col-6">
      <label>Annual Contribution Revenue:</label>
      <select
        name="city"
        class="form-control"
        (change)="onChange($event.target.value)"
        [(ngModel)]="annualText"
      >
        <option *ngFor="let annual of annuals" [value]="annual.name"
          >{{annual.name}}</option
        >
      </select>
    </div>

    <div class="col-6">
      <label># of Users:</label>
      <select
        name="city"
        class="form-control"
        (change)="onChange($event.target.value)"
        [(ngModel)]="usersText"
      >
        <option *ngFor="let user of users" [value]="user.name"
          >{{user.name}}</option
        >
      </select>
    </div>

    <div class="col-6">
      <label>Overall Rating:</label>
      <select
        name="city"
        class="form-control"
        (change)="onChange($event.target.value)"
        [(ngModel)]="ratingText"
      >
        <option *ngFor="let rating of ratings" [value]="rating.name"
          >{{rating.name}}</option
        >
      </select>
    </div>

    <!--div class="col-6">
      <label>Categories:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="catText">
        <option *ngFor="let category of categories"  [value]="category.name">{{category.name}}</option>
      </select>
    </div-->
  </div>
</div>

<div class="container  text-center">
  <h6 tyle="color:grey">Results:</h6>
  <div class="row">
    <div
      *ngFor="let hero of heroes  | filter:usersText | filter:sectorText | filter:annualText | filter:ratingText"
      style="padding:1em"
      class="col-12"
    >
      <div>
        <div class="card">
          <div class="card-body" style="padding: 1em;">
            <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-home-{{hero.id}}-tab"
                  data-toggle="pill"
                  href="#pills-home-{{hero.id}}"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  >{{hero.software}}</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-contact-{{hero.id}}-tab"
                  data-toggle="pill"
                  href="#pills-contact-{{hero.id}}"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                  >Pricing & Demo</a
                >
              </li>
            </ul>
            <div class="tab-content" id="pills-tab-{{hero.id}}-Content">
              <div
                class="tab-pane fade show active"
                id="pills-home-{{hero.id}}"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                style="padding:1em"
              >
                <div class="row">
                  <div class="col-md-4">
                    <div class="text-left">
                      <p>
                        <strong>Reviewer Name:</strong> {{hero.first}}
                        {{hero.last}}
                      </p>

                      <p><strong>Organization:</strong> {{hero.org}}</p>
                      <p><strong>Sector:</strong> {{hero.sector}}</p>
                      <p><strong>Size:</strong> {{hero.income}}</p>
                      <p><strong>Users:</strong> {{hero.users}}</p>
                      <p><strong>Verified by:</strong> The NonProfit Times</p>
                      <strong>Ratings:</strong>
                      <ul class="text-left">
                        <li><strong>Net User Score:</strong> {{hero.a5}}</li>
                        <li><strong>Ease of Use:</strong> {{hero.a6}}</li>
                        <li><strong>Value: </strong>{{hero.a7}}</li>
                        <li><strong>Functionality:</strong> {{hero.a8}}</li>
                        <li><strong>Effeciency: </strong>{{hero.a9}}</li>
                        <li><strong>Reputation: </strong>{{hero.a10}}</li>
                        <li><strong>Support: </strong>{{hero.a11}}</li>
                      </ul>
                      <h6><strong>Overall Rating:</strong> {{hero.rating}}</h6>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="text-left">
                      <h6><strong>What user loves:</strong></h6>
                      <p>{{hero.a1}}</p>
                      <h6><strong>What user dislikes the most:</strong></h6>
                      <p>{{hero.a2}}</p>
                      <h6>
                        <strong
                          >What would they tell a vendor to improve:</strong
                        >
                      </h6>
                      <p>{{hero.a3}}</p>
                      <h6><strong>Response from vendor:</strong></h6>
                      <p>{{hero.response}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact-{{hero.id}}"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <form
                  id="contact-form"
                  method="post"
                  action="contact.php"
                  role="form"
                >
                  <div class="messages"></div>
                  <div class="controls" style="padding:1em">
                    <div class="row">
                      <div class="col-md-12">
                        <p>
                          To receive pricing and information on scheduling a
                          demonstration with the vendor, please tell us a little
                          about yourself.
                        </p>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_name">First Name *</label>
                          <input
                            id="form_name"
                            type="text"
                            name="name"
                            class="form-control"
                            placeholder="Please enter your first name *"
                            required="required"
                            data-error="Firstname is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_lastname">Last Name *</label>
                          <input
                            id="form_lastname"
                            type="text"
                            name="surname"
                            class="form-control"
                            placeholder="Please enter your last name *"
                            required="required"
                            data-error="Lastname is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_email">Email *</label>
                          <input
                            id="form_email"
                            type="email"
                            name="email"
                            class="form-control"
                            placeholder="Please enter your email *"
                            required="required"
                            data-error="Valid email is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_need">Organization Name *</label>
                          <input
                            id="form_name"
                            type="text"
                            name="name"
                            class="form-control"
                            placeholder="Please enter your organization name *"
                            required="required"
                            data-error="Firstname is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="form_message">Message *</label>
                          <textarea
                            id="form_message"
                            name="message"
                            class="form-control"
                            placeholder="Please share your needs or concerns so we can best help you *"
                            rows="4"
                            required="required"
                            data-error="Please, leave us a message."
                          ></textarea>
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <input
                          type="submit"
                          class="btn btn-success btn-send"
                          value="Submit"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <p class="text-muted">
                          <strong>*</strong> These fields are required
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
