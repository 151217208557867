import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from "@angular/router";
import { AppRoutingModule } from './app-routing.module';
import { LivechatWidgetModule } from '@livechat/angular-widget';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { DataService } from "./data.service";
// search module
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { AppComponent } from './app.component';
import { LandingComponent } from './landing.component';
import { HumanResourcesManagementSoftwareComponent } from './human-resources-management-software.component';
import { ReviewsHumanResourcesManagementSoftwareComponent } from './reviews-human-resources-management-software.component';
import { AccountingSoftwareComponent } from './accounting-software.component';
import { ReviewsAccountingSoftwareComponent } from './reviews-accounting-software.component';
import { AssociationManagementComponent } from './association-management.component';
import { ReviewsAssociationManagementComponent } from './reviews-association-management.component';
import { ReviewsComponent } from './reviews.component';
import { CrmSoftwareComponent } from './crm-software.component';
import { ReviewsCrmSoftwareComponent } from './reviews-crm-software.component';
import { ReviewsSalesforceComponent } from './reviews-salesforce/reviews-salesforce.component';
import { ReviewsRaisersEdgeComponent } from './reviews-raisers-edge/reviews-raisers-edge.component';
import { ReviewsBloomerangComponent } from './reviews-bloomerang.component';
import { ReviewsDonorPerfectComponent } from './reviews-donor-perfect/reviews-donor-perfect.component';
import { ReviewsFastfundComponent } from './reviews-fastfund/reviews-fastfund.component';
import { ReviewsLittleGreenLightComponent } from './reviews-little-green-light/reviews-little-green-light.component';
import { ReviewsRaisersEdgeNxtComponent } from './reviews-raisers-edge-nxt.component';
import { ReviewsSalsaCrmComponent } from './reviews-salsa-crm/reviews-salsa-crm.component';
import { ReviewsEtapestryComponent } from './reviews-etapestry/reviews-etapestry.component';
import { ReviewsMicrosoftExcelComponent } from './reviews-microsoft-excel/reviews-microsoft-excel.component';
import { ReviewsNeonOneComponent } from './reviews-neon-one/reviews-neon-one.component';
import { ReviewsQuickbooksComponent } from './reviews-quickbooks/reviews-quickbooks.component';

@NgModule({
  imports:      [ BrowserModule, FormsModule,ReactiveFormsModule, Ng2SearchPipeModule, AppRoutingModule, LivechatWidgetModule, HttpClientModule, HttpClientJsonpModule ],
  declarations: [ AppComponent, LandingComponent, HumanResourcesManagementSoftwareComponent, ReviewsHumanResourcesManagementSoftwareComponent, AccountingSoftwareComponent, ReviewsAccountingSoftwareComponent, AssociationManagementComponent, ReviewsAssociationManagementComponent, ReviewsComponent, CrmSoftwareComponent, ReviewsCrmSoftwareComponent, ReviewsSalesforceComponent, ReviewsRaisersEdgeComponent, ReviewsBloomerangComponent, ReviewsDonorPerfectComponent, ReviewsFastfundComponent, ReviewsLittleGreenLightComponent, ReviewsRaisersEdgeNxtComponent, ReviewsSalsaCrmComponent, ReviewsEtapestryComponent, ReviewsMicrosoftExcelComponent, ReviewsNeonOneComponent, ReviewsQuickbooksComponent ],
  providers: [
    DataService
  ],
  bootstrap:    [ AppComponent ]
})
export class AppModule { }
