<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="search-hero">
        <label>Search:</label>
        <input
          class="form-control  bigForm"
          type="text"
          name="search"
          [(ngModel)]="searchText"
          (input)="search($event.target.value)"
          autocomplete="off"
          placeholder="&#61442;"
        />
      </div>
    </div>
  </div>

  <!--div class="row">
    <div class="col-6">
      <label>Sectors:</label>
      <select
        name="city"
        class="form-control"
        (change)="onChange($event.target.value,'sector')"
        [(ngModel)]="sectorText"
      >
        <option *ngFor="let sector of sectors;let i = index" [value]="(i === 0)?'':sector.name"
          >{{sector.name}}</option
        >
      </select>
    </div>

    <div class="col-6">
      <label>Annual Contribution Revenue:</label>
      <select
        name="city"
        class="form-control"
        (change)="onChange($event.target.value,'annual')"
        [(ngModel)]="annualText"
      >
        <option *ngFor="let annual of annuals;let i = index" [value]="(i === 0)?'':annual.name"
          >{{annual.name}}</option
        >
      </select>
    </div>

    <div class="col-6">
      <label># of Users:</label>
      <select
        name="city"
        class="form-control"
        (change)="onChange($event.target.value,'no_of_user')"
        [(ngModel)]="usersText"
      >
        <option *ngFor="let user of users;let i = index" [value]="(i === 0)?'':user.name"
          >{{user.name}}</option
        >
      </select>
    </div>

    <div class="col-6">
      <label>Overall Rating:</label>
      <select
        name="city"
        class="form-control"
        (change)="onChange($event.target.value,'rating')"
        [(ngModel)]="ratingText"
      >
        <option *ngFor="let rating of ratings;let i = index" [value]="(i === 0)?'':rating.name"
          >{{rating.name}}</option
        >
      </select>
    </div>

    <div class="col-6">
      <label>Categories:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="catText">
        <option *ngFor="let category of categories"  [value]="category.name">{{category.name}}</option>
      </select>
    </div>
  </div-->
</div>

<div class="container  text-center">
  <h2 class="bigtitle"><strong>{{this.title}}</strong></h2>
  <div class="row">
    <div
      *ngFor="let hero of heroes  | filter:usersText | filter:sectorText| filter:annualText | filter:ratingText | filter:softwareText"
      style="padding:1em"
      class="col-12"
    >
      <div>
        <div class="card">
          <div class="card-body" style="padding: 1em;">
            <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-home-{{hero.id}}-tab"
                  data-toggle="pill"
                  href="#pills-home-{{hero.id}}"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  (click)="labelSearch(hero.software)"
                  >{{hero.software}}</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-contact-{{hero.id}}-tab"
                  data-toggle="pill"
                  href="#pills-contact-{{hero.id}}"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                  >Pricing & Demo</a
                >
              </li>
            </ul>
            <div class="tab-content" id="pills-tab-{{hero.id}}-Content">
              <div
                class="tab-pane fade show active"
                id="pills-home-{{hero.id}}"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                style="padding:1em"
              >
                <div class="row">
                  <div class="col-md-4">
                    <div class="text-left">
                      <p>
                        <strong>Reviewer Name:</strong> {{hero.first}}
                        {{hero.last}}
                      </p>

                      <p><strong>Organization:</strong> {{hero.org}}</p>
                      <p><strong>Sector:</strong> {{hero.sector}}</p>
                      <p><strong>Size:</strong> {{hero.income}}</p>
                      <p><strong>Users:</strong> {{hero.users}}</p>
                      <p><strong>Verified by:</strong> The NonProfit Times</p>
                      <strong>Ratings:</strong>
                      <ul class="text-left">
                        <li><strong>Net User Score:</strong> {{hero.a5}}</li>
                        <li><strong>Ease of Use:</strong> {{hero.a6}}</li>
                        <li><strong>Value: </strong>{{hero.a7}}</li>
                        <li><strong>Functionality:</strong> {{hero.a8}}</li>
                        <li><strong>Effeciency: </strong>{{hero.a9}}</li>
                        <li><strong>Reputation: </strong>{{hero.a10}}</li>
                        <li><strong>Support: </strong>{{hero.a11}}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="text-left">
                      <h6><strong>What user loves:</strong></h6>
                      <p class="dantP">{{hero.a1}}</p>
                      <h6><strong>What user dislikes the most:</strong></h6>
                      <p class="dantP">{{hero.a2}}</p>
                      <h6>
                        <strong
                          >What would they tell a vendor to improve:</strong
                        >
                      </h6>
                      <p class="dantP">{{hero.a3}}</p>
                      <h6><strong>Response from vendor:</strong></h6>
                      <p class="dantP">{{hero.response}} N/A</p>
                      <h6>
                        <strong
                          ><p class="dantRatingP">
                            Overall Rating: {{averageRating(hero) | number:'1.2-2'}}
                          </p></strong
                        >
                      </h6>
                      <button class="btn btn-md btn-outline-success rightButton" type="button" (click)="openChat()">Get Expert Advice Now</button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact-{{hero.id}}"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
              <form id="contact-form" [formGroup]="addEditForm" (ngSubmit)="onSubmit(hero)"
              role="form">
              <div class="messages"></div>
              <div class="controls" style="padding:1em">
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      To receive pricing and information on scheduling a
                      demonstration with the vendor, please tell us a little
                      about yourself.
                    </p>
                  </div>
                  <!-- <input type="hidden" formControlName="soft_name" ngModel="{{hero.name}}" /> -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="form_name">First Name *</label>
                      <input
                    id="form_name"
                    type="text"
                    formControlName="name"
                    class="form-control"
                    placeholder="Please enter your first name *"
                    required="required"
                    data-error="Firstname is required."
                  />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="form_lastname">Last Name *</label>
                      <input
                    id="form_lastname"
                    type="text"
                    formControlName="surname"
                    class="form-control"
                    placeholder="Please enter your last name *"
                    required="required"
                    data-error="Lastname is required."
                  />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="form_email">Email *</label>
                      <input
                    id="form_email"
                    type="email"
                    formControlName="email"
                    class="form-control"
                    placeholder="Please enter your email *"
                    required="required"
                    data-error="Valid email is required."
                  />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="form_need">Organization Name *</label>
                      <input
                    id="form_name"
                    type="text"
                    formControlName="org_name"
                    class="form-control"
                    placeholder="Please enter your organization name *"
                    required="required"
                    data-error="Firstname is required."
                  />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="form_need">Number of Donors *</label>
                      <select class="form-control" formControlName="no_donor">
                        <option value="Under 500 Donors">Under 500 Donors</option>
                        <option value="500 - 2,500">500 - 2,500</option>
                        <option value="2,500 - 10,000">2,500 - 10,000</option>
                        <option value="10,000 - 25,000">10,000 - 25,000</option>
                        <option value="25,000 - 100,000">25,000 - 100,000</option>
                        <option value="100,000+ Donors">100,000+ Donors</option>
                      </select>

                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="form_message">Message *</label>
                      <textarea
                    id="form_message"
                    formControlName="message"
                    class="form-control"
                    placeholder="Please share your needs or concerns so we can best help you *"
                    rows="4"
                    required="required"
                    data-error="Please, leave us a message."
                  ></textarea>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-12" >
                    <input
                  type="submit"
                  [disabled] = "!addEditForm.valid"
                  class="btn btn-success btn-send"
                  value="Submit"
                />
              </div>
                  </div>
                  <div class="row" *ngIf="addEditForm.invalid && !successMess">
                    <div class="col-md-12">
                      <p class="text-muted">
                        <strong>*</strong> These fields are required
                      </p>
                    </div>
                  </div>
                  <div class="row" *ngIf="successMess">
                    <div class="col-md-12">
                      <p class="text-muted">
                        Your message has been sent, thank you!
                      </p>
                    </div>
                  </div>

                </div>
            </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
