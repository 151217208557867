import { Component, OnInit } from "@angular/core";
import { Router,ActivatedRoute } from "@angular/router";
import { DataService } from "./data.service";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { HttpClient, HttpParams } from "@angular/common/http";

@Component({
  selector: "app-reviews-crm-software",
  templateUrl: "./reviews-crm-software.component.html",
  styleUrls: ["./reviews-crm-software.component.css"]
})
export class ReviewsCrmSoftwareComponent implements OnInit {
  title = "Reviews";
  searchName;
  searchText;
  addEditForm = new FormGroup({});
  successMess = false;
  sectorText = '';
  annualText = '';
  usersText = '';
  ratingText = '';
  softwareText = '';
  heroes = [];
  onChange(event,type){
    // console.log(event,type);
    // if(type == 'sector'){

    // }

  }

  constructor(private _route:ActivatedRoute, private data: DataService, private formBuilder: FormBuilder, private http: HttpClient) {

   this._route.queryParams.subscribe(params => {
     if(params.search){
       //console.log(params);

       this.searchText = params.search;

     }

       });

  }

  ngOnInit() {
    this.initForm();
    this.heroes = this.heroess;
    this.search(this.searchText);
  }

  initForm() {
    this.addEditForm = this.formBuilder.group({
      name: new FormControl("", [Validators.required]),
      soft_name: new FormControl(""),
      surname: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
      org_name: new FormControl("", [Validators.required]),
      message: new FormControl("", [Validators.required]),
      no_donor: new FormControl("Under 500 Donors", [Validators.required]),
    });
  }

  onSubmit(hero) {
    if (this.addEditForm.invalid) {
      return;
    }

    this.addEditForm.value.soft_name = hero.software;
    const message = `Name: ${this.addEditForm.value.name} ${
      this.addEditForm.value.surname
    } <br> From Email: ${
      this.addEditForm.value.email
    } <br> Organisation name: ${
      this.addEditForm.value.org_name
    } <br> Software name: ${
      this.addEditForm.value.soft_name
     } <br> No of donors: ${
      this.addEditForm.value.no_donor
    } <br> Comments: ${this.addEditForm.value.message} <br>`;

    const obj = {
      to: this.addEditForm.value.email,
      subject: `Name: ${this.addEditForm.value.name} ${
        this.addEditForm.value.surname
      }`,
      mess: message
    };
    this.sendEmail(obj).subscribe((data: any) => {});
    this.addEditForm.reset();
    this.successMess = true;
    setTimeout(() => {
      this.successMess = false;
    }, 3000)
  }

  sendEmail(email) {
    const url =
      "https://us-central1-reviews-db2ee.cloudfunctions.net/app?to=hmanshukumar077@gmail.com.com&subject=" +
      email.subject +
      "&mess=" +
      email.mess;
    console.log(url);
    //nptapp@nptimes.com
    return this.http.get(url);
  }

  labelSearch(val){
    this.searchText = val;
  }

  search(text){
    this.searchText = text;
    let newArray = [];
    for (var i=0; i < this.heroess.length; i++) {
     // newArray = [];
      if(this.heroess[i].software.toLowerCase().indexOf(text.toLowerCase()) > -1) {
        newArray.push(this.heroess[i]);
      }
    }
    this.heroes = newArray;
 }

  categories = [
     {id: 1, name: 'All'},
    { id: 2, name: "Software" },
    { id: 3, name: "Insurance" },
    { id: 4, name: "Financial" },
    { id: 5, name: "Grants" }
  ];
  ratings = [
     {id: 1, name: 'All'},
    { id: 2, name: "8-10" },
    { id: 3, name: "5-7" },
    { id: 4, name: "Below 5" }
  ];
  users = [
    {id: 1, name: 'All'},
    { id: 2, name: "1 (Just Me)" },
    { id: 3, name: "2-4" },
    { id: 4, name: "5-10" },
    { id: 5, name: "10-49" },
    { id: 6, name: "50+" }
  ];
  annuals = [
    {id: 1, name: 'All'},
    { id: 2, name: "$0 - $100k" },
    { id: 3, name: "$100k - $250k" },
    { id: 4, name: "$250 - $750k" },
    { id: 5, name: "$750k+" }
  ];
  sectors = [
    {id: 1, name: 'All'},
    { id: 2, name: "Tribal" },
    { id: 3, name: "Advocacy" },
    { id: 4, name: "Non-Profit" },
    { id: 5, name: "Food Bank" },
    { id: 6, name: "Fiscal Sponsorship" },
    { id: 7, name: "Community Organizing" },
    { id: 8, name: "Affordable Housing" },
    { id: 9, name: "Faith Based" },
    { id: 10, name: "Education" },
    { id: 11, name: "Foundations" },
    { id: 12, name: "Associations & Memberships" },
    { id: 13, name: "Environmental" },
    { id: 14, name: "Medical & Wellness" },
    { id: 15, name: "Software" },
    { id: 16, name: "Insurance" },
    { id: 17, name: "Financial" },
    { id: 18, name: "Grants" }
  ];

  openChat() {
    this.data.changeMessage("chat-open")
  }

  averageRating(hero){
    return (Number(hero.a5)+Number(hero.a6)+Number(hero.a7)+Number(hero.a8)+Number(hero.a9)+Number(hero.a10)+Number(hero.a11))/7;
  }

  heroess = [
    {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661905088,
    "first": "Greg",
    "last": "Tondi",
    "org": "USCIB",
    "email": "gtondi@uscib.org",
    "sector": "Associations & Memberships",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "Abila NetForum",
    "rating": 7,
    "a1": "It is simple and easy to use. It is has comprehensive reports to analyze members and their contributions.",
    "a2": "It is not integrated with our accounting software.  There has to be a reconciliation done between our accounting software and its system.",
    "a3": "We need more training on integrating it with our accounting software along with using more features available in the software.",
    "a4": "Abila NetForum",
    "a5": 7,
    "a6": 7,
    "a7": 6,
    "a8": 7,
    "a9": 7,
    "a10": 7,
    "a11": 6,
    "a12": "Abila Netforum.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "gtondi@uscib.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628367469,
    "first": "Robin",
    "last": "Antonson",
    "org": "KIDS Center",
    "email": "rantonson@kidscenter.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$250,000,000+",
    "software": "Abilia Fundraising50",
    "rating": 7,
    "a1": "Pretty easy to use and has basic capability.  Good for smaller organizations.  basic reporting works fine.",
    "a2": "Advanced reporting is a little complicated.  While you can input a fair amount of data for the donor, the information is very segmented .  The grant management is very basic and does not include the ability to send reminders when deadlines are approaching.  therefore, we have  separate Grant management system.  We migrated to FR50 so that it would integrate with MIP our Abila acctg syetm and in the end this didn't work.",
    "a3": "Add e-mail distribution/communication capability",
    "a4": "Abilia Fundraising50",
    "a5": 8,
    "a6": 9,
    "a7": 6,
    "a8": 6,
    "a9": 5,
    "a10": 8,
    "a11": 6,
    "a12": "KIDS Center FR50 User log-in.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "rantonson@kidscenter.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689204491,
    "first": "Melpo",
    "last": "Mankin",
    "org": "Vanderbilt University",
    "email": "melpo.mankin@vanderbilt.edu",
    "sector": "Education",
    "users": "50+ Users",
    "income": "$250,000,000+",
    "software": "Advance",
    "rating": 5,
    "a1": "Comprehensive data, well organized, lots of options for searching records. Fairly easy to navigate the program.",
    "a2": "Would love the option of running my own reports using the software. Some fields tend to be hidden and hard to find. The platform design is a bit dated.",
    "a3": "The UX could be improved.  The design of the page hasn't changed in years (I've used Advance for nearly 15 years and it looks exactly the same)",
    "a4": "Advance",
    "a5": 3,
    "a6": 5,
    "a7": 7,
    "a8": 5,
    "a9": 4,
    "a10": 2,
    "a11": 6,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11624668484,
    "first": "Geoffrey",
    "last": "Peters",
    "org": "Moore Group",
    "email": "gpeters@mooredmgroup.com",
    "sector": "Other (please specify)",
    "users": "50+ Users",
    "income": "$250,000,000+",
    "software": "Aegis Premier Solutions",
    "rating": 9,
    "a1": "UX is modern; provides support for multiple media - mail, digital, etc.; fast; can be used as stand alone software or as service bureau; some great commonly used reports that are in friendly formats",
    "a2": "Would like more robust analytics but it does work through APIs with many advanced analytic tools; not yet integrated completely with Big Data sources but supposedly that will happen soon",
    "a3": "Would like more robust analytics but it does work through APIs with many advanced analytic tools",
    "a4": "Aegis Premier Solutions",
    "a5": 8,
    "a6": 9,
    "a7": 9,
    "a8": 10,
    "a9": 9,
    "a10": 8,
    "a11": 9,
    "a12": "DSC_5872.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "gpeters@mooredmgroup.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662525819,
    "first": "August",
    "last": "Carlino",
    "org": "Rivers of Steel",
    "email": "arcarlino@riversofsteel.cm",
    "sector": "Arts & Culture",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "Altru",
    "rating": 9,
    "a1": "Cross-platform integration of all data Cross-platform integration of all data Cross-platform integration of all data",
    "a2": "Costly expensive a lot of money not affordable to all nonprofits Costly expensive a lot of money not affordable to all nonprofits",
    "a3": "Nothing",
    "a4": "Altru",
    "a5": 7,
    "a6": 5,
    "a7": 10,
    "a8": 8,
    "a9": 10,
    "a10": 10,
    "a11": 8,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11636427794,
    "first": "Suzanne",
    "last": "Anthony",
    "org": "United Way of Greater Chattanooga",
    "email": "suzyanthony@uwchatt.org",
    "sector": "Human Services",
    "users": "10 - 49",
    "income": "$10,000,000 - $50,000,000",
    "software": "Andar",
    "rating": 6,
    "a1": "History with the platform - know how to use it. We can share licenses. We purchase 12 licenses that are shared among our 46 staff.",
    "a2": "Need a better digital engagement component. Most useful reporting is created by us - dumping out data into modules we have created.",
    "a3": "Add a robust digital engagement component. Improve reporting.",
    "a4": "Andar",
    "a5": 6,
    "a6": 7,
    "a7": 4,
    "a8": 6,
    "a9": 7,
    "a10": 4,
    "a11": 6,
    "a12": "sshot andar.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "suzyanthony@uwchatt.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11624602613,
    "first": "Margery",
    "last": "Shapiro",
    "org": "United Way of Massachusetts Bay, Inc.",
    "email": "mshapiro@supportunitedway.org",
    "sector": "Human Services",
    "users": "50+ Users",
    "income": "$10,000,000 - $50,000,000",
    "software": "Andar",
    "rating": 4,
    "a1": "has been in place for decades and holds historical information on donors; fairly easy to run reports; customizable dashboards and reports",
    "a2": "not compatible with accounting systems and not easy to track due dates; customizable reporting only available to superusers, not to me, an end user",
    "a3": "Software may have more capability, but we don't have the modules.  If possible - grant management module and compatibility with accounting software",
    "a4": "Andar",
    "a5": 3,
    "a6": 4,
    "a7": 2,
    "a8": 4,
    "a9": 5,
    "a10": 4,
    "a11": 3,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662129954,
    "first": "Jason",
    "last": "Pyrah",
    "org": "Habitat for Humanity of Chester County",
    "email": "jason@hfhcc.org",
    "sector": "Other (please specify)",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "Better Impact",
    "rating": 8,
    "a1": "It combines donor management with volunteer management, making it easy to track donors who are also volunteers..",
    "a2": "The reporting function is awkward and involves too many steps. It requires running an excel report and then I need to sort that data manually in some cases.",
    "a3": "The reporting function needs to be streamlined.",
    "a4": "Better Impact",
    "a5": 10,
    "a6": 6,
    "a7": 6,
    "a8": 5,
    "a9": 8,
    "a10": 10,
    "a11": 6,
    "a12": "Screenshot_2020-06-03 Habitat for Humanity of Chester County - Home.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jpyrah9@gmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663763568,
    "first": "Jordan",
    "last": "Dancer",
    "org": "Next Door Solutions to Domestic Violence",
    "email": "jdancer@nextdoor.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$3,000,000 - $10,000,000",
    "software": "Bloomerang",
    "rating": 8,
    "a1": "The versatility - being able to complete many functions and tasks without having to use another product.",
    "a2": "Nothing at this time.  When we've run into issues, Bloomerang has assisted with solutions, often adding them as a new feature.",
    "a3": "Nothing at this time - other than Thank You - and keep it up!",
    "a4": "Bloomerang",
    "a5": 7,
    "a6": 8,
    "a7": 8,
    "a8": 8,
    "a9": 8,
    "a10": 8,
    "a11": 8,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663566905,
    "first": "Kennedee",
    "last": "Blanchard",
    "org": "Center for Nursing at the Foundation of NYS Nurses",
    "email": "kblanchard@cfnny.org",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "Bloomerang",
    "rating": 10,
    "a1": "I love that they are constantly updating their system and provide great resources and learning tools",
    "a2": "The one thing I dislike is that they changed their email template designs to one that is similar to MailChimp",
    "a3": "Cross functionality with other platforms",
    "a4": "Bloomerang",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 8,
    "a10": 10,
    "a11": 9,
    "a12": "Bloomerang - Google Chrome 6_3_2020 4_16_30 PM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "kblanchard@cfnny.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662165857,
    "first": "Karyn",
    "last": "Pratt",
    "org": "Adelphoi",
    "email": "karyn.pratt@adelphoi.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$50,000,000 - $250,000,000",
    "software": "Bloomerang",
    "rating": 9,
    "a1": "Bloomerang offers quick and easy dashboards so metrics are at hand.  They also offer report writing that is intuitive.  In addition, they work to constantly improve the product, with an online board of all of the current improvement projects underway, plus an option to suggest product improvements.",
    "a2": "We had a breakdown of the site on GivingTuesday, and they never addressed the issue with their customers.",
    "a3": "Better communication",
    "a4": "Bloomerang",
    "a5": 9,
    "a6": 9,
    "a7": 8,
    "a8": 9,
    "a9": 9,
    "a10": 8,
    "a11": 9,
    "a12": "Annotation 2020-06-03 100443.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "karyn.pratt@adelphoi.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661738530,
    "first": "Karen",
    "last": "DeHart",
    "org": "NCHSAA",
    "email": "karen@nchsaa.org",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "Bloomerang",
    "rating": 9,
    "a1": "easy to use platform; aesthetically pleasing; filtering features are great; option to create acknowledgment letters from within system is terrific. Duplicate record merge feature is good. Support from the Boomerang support staff has always been timely and helpful.",
    "a2": "Sending a tribute letter is tedious. Bloomerang needs to find a way to make the tribute letter generate once the tribute option is clicked on the donation page. Cost is so-so. For nonprofits, it would be great to have some sort of discounting available rather than increasing cost per number of donors in the database.",
    "a3": "For the tribute letter, Bloomerang needs to find a way to make the tribute letter generate once the tribute option is clicked on the donation page. Also, letters do not generate with bad or missing information.",
    "a4": "Bloomerang",
    "a5": 9,
    "a6": 7,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 9,
    "a12": "Screen Shot 2020-06-03 at 9.26.03 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "karen@nchsaa.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11636886814,
    "first": "Susan",
    "last": "Reichard",
    "org": "TRI-CAP",
    "email": "susan@tri-cap.net",
    "sector": "Other (please specify)",
    "users": "5 - 10",
    "income": "$0 - $100,000",
    "software": "Bloomerang",
    "rating": 9,
    "a1": "Ease of use and reporting - many standard reports are usable and custom reporting is fairly easy to produce",
    "a2": "Does not connect with Paypal - if it did that information could transfer directly into our database.",
    "a3": "Connect with Paypal - more standard reports - provide more templates and/or custom reporting each year",
    "a4": "Bloomerang",
    "a5": 9,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 9,
    "a12": "Screen shot.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "susan@tri-cap.net",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11625207114,
    "first": "Melanie",
    "last": "Wilson",
    "org": "Cancer Care Services",
    "email": "melanie@cancercareservices.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "Bloomerang",
    "rating": 7,
    "a1": "Bloomerang is relatively user-friendly. I also like that it is cloud-based. Bloomerang also interacts with a donor profile software.",
    "a2": "Reports are difficult to run in Bloomerang. It is easy to put data in, but not easy to get it out in report-form.",
    "a3": "We need canned reports that allow us to quickly and easily analyze data. It is also not as easy as it should be to generate letters and mailings.",
    "a4": "Bloomerang",
    "a5": 7,
    "a6": 6,
    "a7": 7,
    "a8": 7,
    "a9": 7,
    "a10": 6,
    "a11": 7,
    "a12": "bloomerang invoice.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "melanie@cancercareservices.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628465710,
    "first": "Lori",
    "last": "Whittington",
    "org": "Pine Castle",
    "email": "lwhittington@pinecastle.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "Bloomerang",
    "rating": 9,
    "a1": "Simple and user friendly - most intuitive database I have worked with in 30 years in fundraising.  It's great that it integrates with the Benevon model, which we use.   I like the tasks feature and that you can track who's hot.",
    "a2": "Not much, it's a pretty good system. I haven't had any problems with the functionality or the support.  Reporting may not be as robust as other systems, but we haven't really missed it.",
    "a3": "Calendar integration for tasks",
    "a4": "Bloomerang",
    "a5": 10,
    "a6": 9,
    "a7": 8,
    "a8": 10,
    "a9": 8,
    "a10": 8,
    "a11": 9,
    "a12": "Bloomerang Screen Shot.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "lwhittington@pinecastle.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11624480303,
    "first": "Dorothy",
    "last": "Bastian",
    "org": "The Homeless Families Foundation",
    "email": "dbastian@homelessfamiliesfoundation.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$3,000,000 - $10,000,000",
    "software": "Bloomerang",
    "rating": 10,
    "a1": "Converting our donor files from our old system was efficient and quick. Set up was easy.  It is easy to use, reliable and flexible. It provides us with the customization that was necessary for what we are trying to accomplish.",
    "a2": "We have found nothing to dislike about this solution, however there was recently a capacity issue around Giving Tuesday that Bloomerang resolved quickly.",
    "a3": "Do not have anything that comes to mind at this time",
    "a4": "Bloomerang",
    "a5": 9,
    "a6": 10,
    "a7": 9,
    "a8": 10,
    "a9": 9,
    "a10": 9,
    "a11": 10,
    "a12": "survey shot.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "dbastian@homelessfamiliesfoundation.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628976316,
    "first": "Jean",
    "last": "Smith",
    "org": "Wild Connections",
    "email": "jean@wildconnections.org",
    "sector": "Environmental",
    "users": "5 - 10",
    "income": "$100,000 - $250,000",
    "software": "Bloomerang",
    "rating": 9,
    "a1": "Focus on the individual donor; integration with mass emails; easy to use; flexible reports; excellent settings menus; can add custom fields",
    "a2": "Takes some dedicated oversite by a manager. Report filter selection is not intuitive. Photo gallery is hard to manage.",
    "a3": "Make report filters easier to understand and select Integrate with Quick Books. Allow full size downloads from photo gallery.",
    "a4": "Bloomerang",
    "a5": 8,
    "a6": 10,
    "a7": 9,
    "a8": 9,
    "a9": 5,
    "a10": 10,
    "a11": 9,
    "a12": "Screenshot_2020-05-22 Bloomerang.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jean@wildconnections.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661962718,
    "first": "WL",
    "last": "Hanson",
    "org": "UC Berkeley",
    "email": "whanson@library.berkeley.edu",
    "sector": "Education",
    "users": "50+ Users",
    "income": "$250,000,000+",
    "software": "CADS",
    "rating": 4,
    "a1": "Has a large amount of data that is wide ranging in scope.  may contain historical information crucial to understanding a prospect",
    "a2": "cumbersome to use.  often the names of fields can be interpreted to apply and mean many different things.",
    "a3": "easier to manipulate data",
    "a4": "CADS",
    "a5": 2,
    "a6": 5,
    "a7": 3,
    "a8": 4,
    "a9": 4,
    "a10": 5,
    "a11": 3,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661852233,
    "first": "CeeCee",
    "last": "Collins",
    "org": "Little Miami River Chamber Alliance",
    "email": "ceecee@lmrchamberalliance.org",
    "sector": "Associations & Memberships",
    "users": "1 - Just Me",
    "income": "$100,000 - $250,000",
    "software": "Chamber Master",
    "rating": 8,
    "a1": "One Source for member content. Ease of use and real time updates. xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
    "a2": "the payment feature still needs work, need more options for members to pay. xxxxxxxxxxxxxxxxxxxxxxxxx",
    "a3": "allow for split screen, more payment option for members",
    "a4": "Chamber Master",
    "a5": 7,
    "a6": 8,
    "a7": 8,
    "a8": 9,
    "a9": 7,
    "a10": 9,
    "a11": 8,
    "a12": "Screenshot 2020-06-03 08.53.07.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "ceecee@lmrchamberalliance.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11629753178,
    "first": "Philecia",
    "last": "McCain",
    "org": "Catholic Legal Immigration Network",
    "email": "pmccain@cliniclegal.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$10,000,000 - $50,000,000",
    "software": "CIVI-CRM",
    "rating": 1,
    "a1": "The cost is free and is a good option for start up and small nonprofits. I don't have anything else to add to my answer.",
    "a2": "The functionality...hard to use, not an efficient database. I don't have any thing else to add to my answer.",
    "a3": "Ease to use  Expand modules  I don't like anything about the software that the organization uses.",
    "a4": "CIVI-CRM",
    "a5": 0,
    "a6": 1,
    "a7": 0,
    "a8": 0,
    "a9": 2,
    "a10": 0,
    "a11": 0,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662312809,
    "first": "Debi",
    "last": "Pfaffenberger",
    "org": "United Way of Noble County",
    "email": "debi.pfaffenberger@uwnoble.org",
    "sector": "Human Services",
    "users": "1 - Just Me",
    "income": "$100,000 - $250,000",
    "software": "Donation Tracker",
    "rating": 4,
    "a1": "Donation Tracker is relatively user friendly and provides fairly prompt response to user inquiries. Reports are easy to access.",
    "a2": "Donation Tracker does not track designations adequately. Additionally, preparing letters is rather cumbersome.",
    "a3": "I'd prefer be able to accurately track designations within the program rather than have to maintain a separate tracking system. I'd also like to be allowed easier editing of letters.",
    "a4": "Donation Tracker",
    "a5": 5,
    "a6": 3,
    "a7": 2,
    "a8": 1,
    "a9": 5,
    "a10": 5,
    "a11": 5,
    "a12": "2020_DonationTrackerReceipt.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "debi.pfaffenbrger@uwnoble.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11625142877,
    "first": "Melody",
    "last": "Amaral",
    "org": "The Carolyn E. Wylie Center for Children, Youth & Families",
    "email": "twc@wyliecenter.org",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "Donor Express",
    "rating": 7,
    "a1": "cheap and fairly easy to use. Is a good fit for our size of organization.  It has good listing abilities among other things.",
    "a2": "Could be easier to use, if it integrated it's different functions better. Can't move from letter templates to donors super easy.  Adding categories of donations isn't easy. If more than one person inputs the categories can get messed up.",
    "a3": "Make it super simple to use. Have different models for different size agencies.",
    "a4": "Donor Express",
    "a5": 4,
    "a6": 9,
    "a7": 3,
    "a8": 7,
    "a9": 5,
    "a10": 9,
    "a11": 7,
    "a12": "donor express.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "mamaral52@yahoo.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662275712,
    "first": "ezra",
    "last": "berenholz",
    "org": "btj",
    "email": "ezra@boystownjerusalem.org",
    "sector": "Education",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 8,
    "a1": "can access remotely, has an app for out of the office, the, can access remotely, has an app for out of the office, the,",
    "a2": "overall it is a good one, can access remotely, has an app for out of the office, the, can access remotely, has an app for out of the office, the,",
    "a3": "b",
    "a4": "DonorPerfect",
    "a5": 7,
    "a6": 7,
    "a7": 7,
    "a8": 7,
    "a9": 7,
    "a10": 7,
    "a11": 8,
    "a12": "20200603_103033.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "ezra@boystownjerusalem.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689214741,
    "first": "Claudia",
    "last": "Ramirez",
    "org": "St. VIncent de Paul of Contra Costa County",
    "email": "c.ramirez@svdp-cc.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "Mobile platform, Easily customizable, Cost-effective, the Mobile platform makes it quick and easy to see donor history before meeting with a donor and entering folllow up notes is simple.",
    "a2": "Cumbersome to manage duplicates. Also, when we imported information from a prior CRM we lost much detail of our historical data.",
    "a3": "Although we integrate with DonorPerfect's recommended SafeServe payments portal, it is not not always recognizing online donations as existing donors resulting in duplicates that require extra maintenance.",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 8,
    "a7": 7,
    "a8": 8,
    "a9": 8,
    "a10": 9,
    "a11": 9,
    "a12": "DP Login.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "c.ramirez@svdp-cc.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661949257,
    "first": "Michael",
    "last": "Leventhal",
    "org": "Israel Guide Dog Center for the Blind",
    "email": "mike@israelguidedog.org",
    "sector": "Other (please specify)",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "User friendly and constantly evolving and getting better based on user suggestions. They also have a \"suggest and vote\" feature to encourage users to vote on changes they would like to see.",
    "a2": "Always changing! Yes, it is positive, but you get used to something, and need to change procedures because old feature was upgraded.",
    "a3": "Offer free webinars to discuss new screens and changes - quarterly.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 10,
    "a7": 9,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Israel Guide Dog Center for the Blind - DPO Subscription Renewal.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "mike@israelguidedog.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11668300785,
    "first": "Jill",
    "last": "Troutman",
    "org": "The Children's Home of Reading",
    "email": "jtroutman@choreading.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 5,
    "a1": "I do appreciate the changes and upgrades that have been made especially integrations with Constant Contact..",
    "a2": "It isn't easy to navigate unless you have been trained and use it regularly. The inability to utilize the software as a full CRM for all our needs.",
    "a3": "It is expensive to be your be all CRM because you are charged by the number of records.  It's as though you are being punished for using the software and being a loyal customer.",
    "a4": "DonorPerfect",
    "a5": 5,
    "a6": 4,
    "a7": 5,
    "a8": 5,
    "a9": 5,
    "a10": 6,
    "a11": 5,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628355059,
    "first": "Lisa",
    "last": "Maher",
    "org": "Children's Aid Society of Alabama",
    "email": "LMaher@childrensaid.org",
    "sector": "Human Services",
    "users": "50+ Users",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 7,
    "a1": "Easy to manage individual donations and campaigns. Quick search features. Can assign multiple roles and campaigns to single entities.",
    "a2": "Reporting is an ongoing frustration. Interface for a single acknowledgement is clunky. We tend not to use batch features because we post daily.",
    "a3": "Keep expanding the knowledgebase and training. This has vastly improved in the past few years.",
    "a4": "DonorPerfect",
    "a5": 7,
    "a6": 5,
    "a7": 6,
    "a8": 8,
    "a9": 8,
    "a10": 5,
    "a11": 7,
    "a12": "DP screen grab LMaher.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "LMaher@childrensaid.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628409526,
    "first": "Jeannette",
    "last": "Barr",
    "org": "PMI educational foundation",
    "email": "Jeannette.barr@pmi.org",
    "sector": "Foundations",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 8,
    "a1": "Donor perfect is simple to use and is very cost efficient.  Using the web version also means you don’t have to worry about software upgrades.",
    "a2": "Setting up corps is a little weird.  You have to use the last name field when setting up the organization’s name.  It makes reporting kind and f winky.",
    "a3": "Revise how it sets up business contacts vs. individual’s",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 9,
    "a7": 7,
    "a8": 8,
    "a9": 8,
    "a10": 8,
    "a11": 8,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11614577436,
    "first": "JonTest",
    "last": "BiedermannTest",
    "org": "Biedermann Group",
    "email": "jon@biedermanngroup.com",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "I love the fact it's easy to use and I wrote it.  And this answer isn't long enough yet. asdddddddddddddddddddddddddddddddddddddddd",
    "a2": "Too much old technology and crap everywhere.  Interesting step to make people fill out the form.dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd",
    "a3": "Listen to your customers",
    "a4": "DonorPerfect",
    "a5": 7,
    "a6": 9,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 9,
    "a11": 9,
    "a12": "panic-740x410.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jon@asd.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628542884,
    "first": "Robert",
    "last": "Malone",
    "org": "History of Science Society",
    "email": "jay@hssonline.org",
    "sector": "Associations & Memberships",
    "users": "1 - Just Me",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 7,
    "a1": "I like that it plays well with Quickbooks Online. Streamlining the financial aspects is essential. The less time we spend on the administrative end, the more time we can devote to programs.",
    "a2": "Non US donations can be tricky. And this is complicated by some countries having automatic blocks due to fraudulent activity.",
    "a3": "Non US donations",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 7,
    "a7": 7,
    "a8": 8,
    "a9": 5,
    "a10": 7,
    "a11": 7,
    "a12": "DP Shot.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jay@hssonline.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663619248,
    "first": "Cyndy",
    "last": "Baggett",
    "org": "Feeding the Gulf Coast",
    "email": "cbaggett@feedingthegulfcoast.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorQuest",
    "rating": 7,
    "a1": "Very robust, low cost. We have appreciated times when we have suggested an enhancement and support has worked with us to incorporate or build out features to accommodate our request.",
    "a2": "Generating reports can be complex even for intermediate users, the layout and appearance are very basic. Customer service could be more friendly and accepting of the varying skill levels of the end user.",
    "a3": "There is nothing currently we would ask to be improved.",
    "a4": "DonorQuest",
    "a5": 5,
    "a6": 10,
    "a7": 7,
    "a8": 6,
    "a9": 5,
    "a10": 7,
    "a11": 7,
    "a12": "Screenshot_2020-06-03 NPT Research Get an Amazon gift card for sharing your insight - cbaggett feedingthegulfcoast org - Fe[...].png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "harrisof6@msn.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628573966,
    "first": "Kimberly",
    "last": "Balkovec",
    "org": "Columban Fathers",
    "email": "kbalkovec@columban.org",
    "sector": "Faith Based",
    "users": "1 - Just Me",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorStudio/StudioEnterprise",
    "rating": 5,
    "a1": "Development is informed by other faith-based orgs much larger than ours, so new features are relevant.",
    "a2": "Being a small client, larger clients take precedence, which often means waiting indefinitely for improvement requests.",
    "a3": "Make it easier to create custom reports a la Raiser's Edge",
    "a4": "DonorStudio/StudioEnterprise",
    "a5": 5,
    "a6": 5,
    "a7": 5,
    "a8": 5,
    "a9": 5,
    "a10": 5,
    "a11": 5,
    "a12": "KBalkovec-loggedin-StudioEnterprise.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "kbalkovec@columban.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661988978,
    "first": "Ray",
    "last": "Woody",
    "org": "The Rose House",
    "email": "ray@therosehouse.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "ELEO",
    "rating": 7,
    "a1": "Simple and inexpensive for our needs.  Ability to add on modules inexpensively as needed.  +++++++++++++++++++++++++",
    "a2": "Interface isn't intuitive, have to click too many times to get to what you need. ++++++++++++++++++++++++++++++++++++++++++++++",
    "a3": "Make the interface more logical, less clicks to get to what you need. Easier report interface.",
    "a4": "ELEO",
    "a5": 4,
    "a6": 8,
    "a7": 7,
    "a8": 6,
    "a9": 5,
    "a10": 6,
    "a11": 7,
    "a12": "Capture.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "ray@therosehouse.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662093401,
    "first": "Inger",
    "last": "de Montecinos",
    "org": "National Museum of the American Indian",
    "email": "demontecinosik@si.edu",
    "sector": "Arts & Culture",
    "users": "2 - 4",
    "income": "$3,000,000 - $10,000,000",
    "software": "Ellucian Advance",
    "rating": 4,
    "a1": "It's OK. I didn't pick it, so I have to use it. It is nice that you can upload PDFs of letters, and you can store a lot of information.",
    "a2": "Its not built for direct mail, and we have had to have a lot of customization in order to make it work.",
    "a3": "Add features for direct mail. Some of the pathways are also not intuitive. Viewing gifts is very complicated and confusing.",
    "a4": "Ellucian Advance",
    "a5": 3,
    "a6": 5,
    "a7": 2,
    "a8": 2,
    "a9": 5,
    "a10": 5,
    "a11": 5,
    "a12": "Doc1.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "demontecinosik@si.edu",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11627263935,
    "first": "Tim",
    "last": "Strzalkowski",
    "org": "Wayne State University",
    "email": "at4602@wayne.edu",
    "sector": "Education",
    "users": "50+ Users",
    "income": "$50,000,000 - $250,000,000",
    "software": "Ellucian Advance",
    "rating": 7,
    "a1": "The platform is fairly robust with the ability to maintain a wide variety of data for individuals and organizations.",
    "a2": "The GUI is tedious and not intuitive. The \"look-up\" function is very limited and does not offer the ability to generate quick \"mini reports\".",
    "a3": "Put a greater importance on the functionality for fundraisers. There are numerous solutions to store data, but what is needed is more \"relationship\" in the CRM.",
    "a4": "Ellucian Advance",
    "a5": 6,
    "a6": 6,
    "a7": 7,
    "a8": 7,
    "a9": 5,
    "a10": 7,
    "a11": 7,
    "a12": "Ellucian Advance Screensho.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "at4602@wayne.edu",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689197130,
    "first": "Steven",
    "last": "Anderson",
    "org": "Forest History Society",
    "email": "stevena@duke.edu",
    "sector": "Education",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "eTapestry",
    "rating": 6,
    "a1": "We originally chose it for the online access.  We can see membership information remotely, at a conference, at home, etc.",
    "a2": "The donation and online bookstore functions are limiting.   They only allow a certain number of donation options.   It is difficult to modify the bookstore options for sales, etc.",
    "a3": "soft credit capability",
    "a4": "eTapestry",
    "a5": 5,
    "a6": 7,
    "a7": 3,
    "a8": 4,
    "a9": 6,
    "a10": 6,
    "a11": 5,
    "a12": "IMG_3424.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "stevena@duke.edu",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661788843,
    "first": "Roberta",
    "last": "Longworth",
    "org": "Fairfax County Park Foundation",
    "email": "Roberta.longworth@fairfaxcounty.gov",
    "sector": "Environmental",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "eTapestry",
    "rating": 9,
    "a1": "What our staff and volunteers love Most about the CRM/Donor Management Software solution that we current have  is the cloud based access and the Simplicity Of eTapestry  And relative user friendliness is great for our staff to use with ease for entry and reporting",
    "a2": "What we dislike about the solution is The ENewsletter functionality that we were told was similar to constant contact is limited and clunky  can be improved",
    "a3": "Make it similar to constant contact",
    "a4": "eTapestry",
    "a5": 8,
    "a6": 9,
    "a7": 8,
    "a8": 9,
    "a9": 8,
    "a10": 8,
    "a11": 7,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628361729,
    "first": "Steven",
    "last": "Anderson",
    "org": "Forest History Society",
    "email": "stevena@duke.edu",
    "sector": "Environmental",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "eTapestry",
    "rating": 6,
    "a1": "remote access - I can access data when attending a conference, at home, on my phone, at a hotel, etc.",
    "a2": "soft credit reporting - difficult to account for this when making report queries to contact donors.  Relationship function seems to automatically add data for the relationship to the home page journal entries.",
    "a3": "relationships",
    "a4": "eTapestry",
    "a5": 6,
    "a6": 7,
    "a7": 3,
    "a8": 4,
    "a9": 5,
    "a10": 4,
    "a11": 7,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628905132,
    "first": "Shannon",
    "last": "MacCarrick",
    "org": "Habitat for Humanity of Tompkins and Cortland Counties",
    "email": "shannon@tchabitat.com",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "eTapestry",
    "rating": 7,
    "a1": "I'm not afraid of losing our data, it's reliable and has a lot of features.  Blackbaud has a good reputation so I trust their products.",
    "a2": "I find eTapestry to be less intuitive than I'd like and to require a lot of steps to accomplish one action sometimes",
    "a3": "Make it more user friendly - terminology, number of steps to complete a task, etc.",
    "a4": "eTapestry",
    "a5": 6,
    "a6": 5,
    "a7": 8,
    "a8": 7,
    "a9": 8,
    "a10": 7,
    "a11": 7,
    "a12": "eTap screenshot.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "shannon@tchabitat.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11630337621,
    "first": "Jenny",
    "last": "Lai",
    "org": "Dress for success worldwide",
    "email": "Partnerships@dressforsuccess.org",
    "sector": "Other (please specify)",
    "users": "10 - 49",
    "income": "$10,000,000 - $50,000,000",
    "software": "eTapestry",
    "rating": 2,
    "a1": "Ability to track historical information and  review performance.  Across by multi users xxxxxxxxxxxxxxxxxxxx",
    "a2": "It is not intuitive.  Reports are complicated.  It doesn’t always work in my browser and access is unreliable",
    "a3": "Fewer steps for reporting.  More intuitive  Complicated to fix incorrect historical entries",
    "a4": "eTapestry",
    "a5": 2,
    "a6": 0,
    "a7": 1,
    "a8": 2,
    "a9": 1,
    "a10": 5,
    "a11": 1,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628382767,
    "first": "Derria",
    "last": "Ford",
    "org": "Legal Aid Society of Middle Tennessee and the Cumberlands",
    "email": "dford@las.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "eTapestry",
    "rating": 4,
    "a1": "It is easy to use. We can easily enter and retrieve information from the database.  I also like the support services and training models.",
    "a2": "Many of the processes are manual. The system should have more reminders and automated processes. I think the system could include more reminder to help with donor stewardship.",
    "a3": "Make the processes more manual",
    "a4": "eTapestry",
    "a5": 10,
    "a6": 0,
    "a7": 2,
    "a8": 1,
    "a9": 5,
    "a10": 10,
    "a11": 0,
    "a12": "e-tap.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "dford@las.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11668347880,
    "first": "Juline",
    "last": "Bodnar",
    "org": "Bend-Redmond Habitat for Humanity",
    "email": "jbodnar@brhabitat.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "FastFund by Araize",
    "rating": 9,
    "a1": "We particularly appreciate how easily the software integrates our project-based expenses and reporting needs, with the donors that fund these projects.",
    "a2": "We have found that our development/fundraising department desires a greater level of detail, particularly when they desire to plan events and begin inviting and including donors.",
    "a3": "This software is very strong from an accounting and project expense tracking perspective. It is also excellent for payroll. However, it can use some \"flair\" from the more creative thinkers and party planners in the bunch.",
    "a4": "FastFund by Araize",
    "a5": 8,
    "a6": 10,
    "a7": 8,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 9,
    "a12": "FastFunds.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jbodnar@brhabitat.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661806991,
    "first": "Joseph",
    "last": "Scarano",
    "org": "Araize",
    "email": "joe@araize.com",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "FastFund by Araize",
    "rating": 10,
    "a1": "Integration with accounting.   Easy to use.  Single database for all names in the system, so there is only point of entry to maintain constituents, vendors, volunteers and clients.  The ability to track donor designations and enter donor gift splits.",
    "a2": "Lack of an direct link to Mailchimp.   Also would like to automate the integration of web-site donations, peer-to-peer fund raising.  Improve the custom query report writer to make it easier to build queries.",
    "a3": "Build an interface for social media marketing, peer-to-peer fund raising, web site donations",
    "a4": "FastFund by Araize",
    "a5": 9,
    "a6": 10,
    "a7": 10,
    "a8": 9,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Constituent List.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662797447,
    "first": "Cara",
    "last": "Braskat",
    "org": "World Institute on Disability",
    "email": "cara@wid.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "FastFund by Araize",
    "rating": 7,
    "a1": "The flexibility of the system to track details, the customer service and responsiveness are great, so are the frequent trainings they offer.",
    "a2": "The Purchase Order function is not a true dynamic purchase order - it needs some work and we are happy to be testers for it should they address this.",
    "a3": "Make the Purchase Order function truly dynamic, where Vendor invoices related to PO's are entered starting from the PO with the ability to post single or selected lines without closing the PO when there are items, or quantities of items, still outstanding.",
    "a4": "FastFund by Araize",
    "a5": 8,
    "a6": 8,
    "a7": 5,
    "a8": 6,
    "a9": 5,
    "a10": 8,
    "a11": 7,
    "a12": "FF Screen Shot for WID.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "caradwen@gmail.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663352076,
    "first": "Nancy",
    "last": "Hein",
    "org": "SAFE, Inc. of Transylvania County",
    "email": "nancy@safetransylvania.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$100,000 - $250,000",
    "software": "FastFund by Araize",
    "rating": 9,
    "a1": "the fund accounting options, the fact that it's online and easy to access remotely, it's very easy to add users",
    "a2": "lack of duplicate function, lack of ability to drill down on reports, lack of chart of accounts you can drill down on",
    "a3": "I don't like the lack of duplicate function, lack of ability to drill down on reports, lack of chart of accounts you can drill down on.  Steal these ideas from QuickBooks, please.",
    "a4": "FastFund by Araize",
    "a5": 9,
    "a6": 9,
    "a7": 8,
    "a8": 7,
    "a9": 8,
    "a10": 9,
    "a11": 9,
    "a12": "FastFund.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "nancy@safetransylvania.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662502215,
    "first": "Greg",
    "last": "Spart",
    "org": "McLeod Center",
    "email": "greg.spart@mcleodcenter.com",
    "sector": "Medical & Wellness",
    "users": "5 - 10",
    "income": "$10,000,000 - $50,000,000",
    "software": "FastFund by Araize",
    "rating": 9,
    "a1": "Ease of use, updates when needed, training online                                                                                                             .",
    "a2": "It doesn't integrate with our A/R software                                                                                                                            .",
    "a3": "Not much",
    "a4": "FastFund by Araize",
    "a5": 8,
    "a6": 10,
    "a7": 7,
    "a8": 9,
    "a9": 8,
    "a10": 9,
    "a11": 8,
    "a12": "fastfund.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "greg.spart@mcleodcenter.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11679074722,
    "first": "Riché",
    "last": "Colcombe",
    "org": "Back In The Saddle Equine Therapy Center",
    "email": "bitsetc2003@aol.com",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "FastFund by Araize",
    "rating": 6,
    "a1": "What I LIKE about this solution is: Email capabilities directly from system, ease of exporting data to Excel, Cloud backup service, regular training opportunities, timely responses to support questions, there may be more, that I can't think of right now.",
    "a2": "Cannot enter a cash disbursement from an AP Vendor account without creating an invoice first, then cash disbursement. Unnecessary extra steps. The back button isn't user friendly, sometimes it takes you back a screen, other times it brings you out to the main dashboard. When leaving a screen there is no option to select save when getting the warning message of data will be lost.  It's a Yes/No question to are you sure you want to leave. It would be better if the question and command was do you want to save your data before leaving yes/no? Limitations with reporting, weak payroll reports - they lack pertinent details. Bank reconciliations lack detail of Journal Entries, creating extra steps. Need to know HTML coding to send a professional looking email, otherwise it's limited to constant wrap around.",
    "a3": "Seek customer input on functionality improvements. Simplify the Lists for entry so there are less tabs, less separation of data and incorporate more info on one screen. Reduce extra steps and utilize the real estate on the screen in a more efficient manner.",
    "a4": "FastFund by Araize",
    "a5": 9,
    "a6": 3,
    "a7": 4,
    "a8": 5,
    "a9": 7,
    "a10": 6,
    "a11": 5,
    "a12": "Araize_screenshot.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "rc.bitsetc@gmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662654610,
    "first": "Tim",
    "last": "Burdashaw",
    "org": "Operation Compassion",
    "email": "tim@operationcompassion.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "FastFund by Araize",
    "rating": 9,
    "a1": "Most everything. Its easy to use, fast and flexible. It is easy to adjust to our specific needs. The screens are easy to use and all the information is at your finger tips.",
    "a2": "I have to use tech support to make changes to the letters and forms because I do not know HTML. That is the biggest drawback to the program.",
    "a3": "See question #10.",
    "a4": "FastFund by Araize",
    "a5": 9,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 9,
    "a12": "IMG_7810.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "tburd58@gmail.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662450928,
    "first": "Cathy",
    "last": "Mathews",
    "org": "Nortex Regional Planning Commission",
    "email": "cmathews@nortexrpc.org",
    "sector": "Human Services",
    "users": "10 - 49",
    "income": "$0 - $100,000",
    "software": "FastFund by Araize",
    "rating": 10,
    "a1": "Ease of use, easy to access reports, tracking, multi use.  Data entry is easy, and the reports we need to print are easily accessed.  I would recommend this software to anyone who needs to track donations.",
    "a2": "It's hard to get rid of duplicate names, needs to be formatted so that it requires Last name first, then first name to make it easier to sort alphabetically by last name.",
    "a3": "This system is great.",
    "a4": "FastFund by Araize",
    "a5": 9,
    "a6": 10,
    "a7": 9,
    "a8": 9,
    "a9": 10,
    "a10": 9,
    "a11": 9,
    "a12": "loginscreen.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "cmathews@nortexrpc.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662180072,
    "first": "Beth",
    "last": "Filla",
    "org": "East Texas Communities Foundation",
    "email": "bfilla@etcf.org",
    "sector": "Foundations",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "FIMS",
    "rating": 1,
    "a1": "FIMS was designed to be a fund management system and as such, has a wonderful ability to manage pooled funds",
    "a2": "FIMS was not created to be a CRM, but it seems to have added that component as an after thought to the financial side. It is outdated, disjointed, and not useful as a CRM",
    "a3": "Better customer service, better integration across modules, better/easier ability to pull  out reports and information.",
    "a4": "FIMS",
    "a5": 0,
    "a6": 0,
    "a7": 2,
    "a8": 0,
    "a9": 1,
    "a10": 0,
    "a11": 0,
    "a12": "FIMS database screenshot.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "bfilla@etcf.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628390261,
    "first": "Connie",
    "last": "Alexander",
    "org": "Gateway Educational Services",
    "email": "info@gatewayeducationalservices.org",
    "sector": "Education",
    "users": "1 - Just Me",
    "income": "$0 - $100,000",
    "software": "Flipcause",
    "rating": 8,
    "a1": "The personal attention and help from the company. When we do need help we can get help and they do an amazing job creating the web page campaign.",
    "a2": "The price it's expensive for our size nonprofit. It's $1,200 a year and that's a lot for us. If it was a flat yearly fee around $600 it would help us.",
    "a3": "For our size nonprofit, it's expensive",
    "a4": "Flipcause",
    "a5": 8,
    "a6": 4,
    "a7": 8,
    "a8": 8,
    "a9": 8,
    "a10": 7,
    "a11": 7,
    "a12": "Screen Shot 2020-05-22 at 11.23.52 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "info@gatewayeducationalservices.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662878203,
    "first": "Danny",
    "last": "Spitzberg",
    "org": "Start.coop",
    "email": "stationaery@gmail.com",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "Google Sheets",
    "rating": 7,
    "a1": "easily viewable and editable and all of what you expect from a digital spreadsheet no I won't write more this is obnoxious I'm sorry",
    "a2": "needs automation like so many other tech tools out there that make hard work easy no I won't write more this is obnoxious I'm sorry",
    "a3": "I wouldn't try it's silly to expect Google to do anything about Google Sheets",
    "a4": "Google Sheets",
    "a5": 6,
    "a6": 6,
    "a7": 8,
    "a8": 6,
    "a9": 7,
    "a10": 3,
    "a11": 7,
    "a12": "Screen Shot 2020-06-03 at 9.53.28 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "stationaery@gmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663013266,
    "first": "Craig",
    "last": "Engen",
    "org": "I'm in heaven",
    "email": "cengine01@msn.com",
    "sector": "Faith Based",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "Google Sheets",
    "rating": 5,
    "a1": "Easy , shareable, part of a bigger company easy to learn Google docs are used by a lot of people who are already possiblu integrAted into a worldwide system",
    "a2": "Not intuitive ....................................no sup..............port ...not really a lot of dislike s..",
    "a3": "..  .......More features ......................................",
    "a4": "Google Sheets",
    "a5": 5,
    "a6": 5,
    "a7": 5,
    "a8": 5,
    "a9": 5,
    "a10": 5,
    "a11": 5,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689588335,
    "first": "Michael",
    "last": "Darnaud",
    "org": "LookIt, Inc.",
    "email": "michael@darnaud.org",
    "sector": "Other (please specify)",
    "users": "1 - Just Me",
    "income": "$100,000 - $250,000",
    "software": "HubSpot",
    "rating": 8,
    "a1": "HubSpot is a Customer Relationship Management solution that is easy to implement and offers a number of key features for free.",
    "a2": "Dashboard is a bit light. It would be great if it could allow the CRO to have more visibility into each person's performance.",
    "a3": "Reinforce all the reporting tools",
    "a4": "HubSpot",
    "a5": 8,
    "a6": 8,
    "a7": 6,
    "a8": 8,
    "a9": 8,
    "a10": 7,
    "a11": 8,
    "a12": "Screen Shot 2020-06-11 at 2.51.32 PM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "michael@darnaud.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661744627,
    "first": "Shimmy",
    "last": "Mehta",
    "org": "Angelwish",
    "email": "shimmy@angelwish.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "Hubspot",
    "rating": 9,
    "a1": "The software has been easy to set up and use. It integrates with gmail and has a lot of great customization options.",
    "a2": "We’ve only been using the software for a short period of time but based on previous software, this seems much easier to implement.",
    "a3": "Offer a specific nonprofit solution.",
    "a4": "Hubspot",
    "a5": 9,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 7,
    "a10": 5,
    "a11": 9,
    "a12": "69245B10-FE92-4750-8413-165B8B471E7D.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "shimmy@angelwish.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628528116,
    "first": "Lynn",
    "last": "Andesron",
    "org": "IJCAHPO",
    "email": "landerson@jcahpo.org",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "IMIS",
    "rating": 6,
    "a1": "The system is integrated with other office systems and software. All datebase records are in one location for staff access.",
    "a2": "Many of the reports we need to track relationships, donations, gifts are not pre-built so we have to build them ourselves in a special reporting system. This takes addtional staff time to develop which is cumbersome.",
    "a3": "Inter-relationships between reports",
    "a4": "IMIS",
    "a5": 3,
    "a6": 8,
    "a7": 3,
    "a8": 6,
    "a9": 8,
    "a10": 6,
    "a11": 7,
    "a12": "Computer - IMIS software 2020.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "Landerson@jcahpo.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628603456,
    "first": "Kenneth",
    "last": "Henderson",
    "org": "Richmond/Ermet Aid Foundation",
    "email": "ken@richmondermet.org",
    "sector": "Arts & Culture",
    "users": "1 - Just Me",
    "income": "$0 - $100,000",
    "software": "Keela",
    "rating": 9,
    "a1": "It's a good donor management and donation platform that also includes email blasts. Being able to message directly from our database is important. It also uses tags to help segment the list.",
    "a2": "It does not have a good peer to peer option for fundraising. We have to use another platform for peer-to-peer fundraising and integrate it into Keela later.  We do the same for online ticket sales.",
    "a3": "Include better peer-to-peer platforms and possibly an option for online auctions.",
    "a4": "Keela",
    "a5": 9,
    "a6": 8,
    "a7": 7,
    "a8": 9,
    "a9": 9,
    "a10": 8,
    "a11": 9,
    "a12": "KeelaDashboard.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "ken@richmondermet.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661781084,
    "first": "Sandy",
    "last": "Mitchell",
    "org": "Project Help",
    "email": "sandy@mitchellclan.com",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "Keela",
    "rating": 7,
    "a1": "Keeps data all in one place for easy access.  The ability to send targeted campaigns and newsletters to all, not only donors.",
    "a2": "It can't project the future value of the donor for a more effective ask when running a campaign for fundraising.",
    "a3": "Better cell availability and projections of donor worth.",
    "a4": "Keela",
    "a5": 7,
    "a6": 6,
    "a7": 3,
    "a8": 7,
    "a9": 5,
    "a10": 7,
    "a11": 9,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661759204,
    "first": "Kathleen",
    "last": "Thomas",
    "org": "Al Copeland Foundation",
    "email": "KGThomas@alcopeland.com",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "Kindful",
    "rating": 7,
    "a1": "Integration of all platforms we use including Quickbooks, Constant Contact, Square and Paypal. This makes is so much easier for data entry.",
    "a2": "Missing ability to add some tracking features  Would like to be able to add more personalized tracking and sorting for donors.",
    "a3": "Coding for donor information with more options",
    "a4": "Kindful",
    "a5": 8,
    "a6": 7,
    "a7": 7,
    "a8": 8,
    "a9": 5,
    "a10": 7,
    "a11": 7,
    "a12": "08A6CCED-947E-465E-BC67-D93D2B71C9AE.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "KGThomas@alcopeland.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11624513526,
    "first": "Kim",
    "last": "Theva",
    "org": "AIM",
    "email": "kim.theva@aimcharity.org",
    "sector": "Human Services",
    "users": "10 - 49",
    "income": "$750,000 - $1,500,000",
    "software": "Kindful",
    "rating": 7,
    "a1": "It is actually new to us. We recently switched from Donor Perfect. It does seem to be easier to navigate thatn Donor Perfect.",
    "a2": "There has been some difficulty reconciling donations in quickbooks after they remove fees. The actual deposit doesn't match the donation and it happens on every online donation.",
    "a3": "Track fees and charge them monthly in an invoice instead of taking it out of every donation which makes reconciling the donation with the deposit difficult.",
    "a4": "Kindful",
    "a5": 7,
    "a6": 8,
    "a7": 7,
    "a8": 5,
    "a9": 7,
    "a10": 8,
    "a11": 5,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11670999165,
    "first": "Ben",
    "last": "Williamson",
    "org": "Northern Rockies Conservation Cooperative",
    "email": "ben@nrccooperative.org",
    "sector": "Environmental",
    "users": "1 - Just Me",
    "income": "$0 - $100,000",
    "software": "Little Green Light",
    "rating": 10,
    "a1": "Easy to navigate, quick, simple, clear, keeps plenty of information, accessible, good search function, low cost",
    "a2": "Old format, clunky. I wish there were less tabs on the site, which would make it a more streamlined process when looking for specific individuals",
    "a3": "Improved design flow of site",
    "a4": "Little Green Light",
    "a5": 9,
    "a6": 10,
    "a7": 10,
    "a8": 9,
    "a9": 10,
    "a10": 8,
    "a11": 9,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689348042,
    "first": "Tara",
    "last": "Short",
    "org": "Special Olympics Wyoming",
    "email": "development@specialolympicswy.org",
    "sector": "Other (please specify)",
    "users": "5 - 10",
    "income": "$100,000 - $250,000",
    "software": "Little Green Light",
    "rating": 9,
    "a1": "We like the price, it's user friendly with tutorials when you get stuck, and has the tools we can use to track and thank donors.",
    "a2": "We cannot transfer the account to a new employee without customer service steeping in, and it doesn't have the peer to peer platform, so we have to have additional platform to fundraise.",
    "a3": "We would like to see users transfer easily, up to date tools; texting, video, etc for thanking donors.",
    "a4": "Little Green Light",
    "a5": 8,
    "a6": 9,
    "a7": 8,
    "a8": 8,
    "a9": 8,
    "a10": 9,
    "a11": 9,
    "a12": "LGL screenshot.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "development@specialolympipcswy.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661919776,
    "first": "Catherine",
    "last": "Chapman",
    "org": "Hesed",
    "email": "Catherinechapman@bellsouth.net",
    "sector": "Faith Based",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "Little Green Light",
    "rating": 7,
    "a1": "The cost is reasonable, and the customer service is fantastic.  I also like that I have the ability to create custom fields to store data that is only relevant to my organization.  They have a robust Facebook group.  One of the best features is the fact that notes are searchable.",
    "a2": "On boarding can be challenging especially if you have information stored in multiple places.  In trying to address so many issues, membership, school graduation, etc., it can become unwieldy.  The knowledge base is also sometimes more problematic than helpful.",
    "a3": "I would offer an on boarding service at a cost.  I would also enable the user a more defined way to streamline LGL for their use- an easier way to eliminate class years, membership, etc.  if it is not needed.  I would also enable them to more easily create user defined fields.  I would also improve the knowledge base and videos.",
    "a4": "Little Green Light",
    "a5": 3,
    "a6": 9,
    "a7": 4,
    "a8": 7,
    "a9": 5,
    "a10": 10,
    "a11": 7,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628429314,
    "first": "Karon",
    "last": "Wright",
    "org": "The Greater Contribution",
    "email": "karon@greatercontribution.org",
    "sector": "International",
    "users": "10 - 49",
    "income": "$100,000 - $250,000",
    "software": "Little Green Light",
    "rating": 7,
    "a1": "the ease of use; the large scope of data that can be collected;  ability to enhance the work of all staff and board members.",
    "a2": "the steep learning curve; the unfamiliar terminology; the silly name; the lack of technical support.",
    "a3": "simplify access to various fields;  allow user to customize which fields appear on the opening screen of a consitutents page of info.",
    "a4": "Little Green Light",
    "a5": 10,
    "a6": 10,
    "a7": 7,
    "a8": 7,
    "a9": 7,
    "a10": 0,
    "a11": 7,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11639903931,
    "first": "Stephanie",
    "last": "Henke",
    "org": "Nantucket Safe Harbor for Animals",
    "email": "shenke@nishanimals.org",
    "sector": "Animal & Wildlife",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "Little Green Light",
    "rating": 10,
    "a1": "Simple, user-friendly, excellent customer service, price, integrations with MailChimp and Fundraising Report Card, easy form-builder",
    "a2": "There is nothing I dislike about LGL. It does everything I need it to do. It is also always adding functionality to make it easier for users or to offer more integrations.",
    "a3": "Nothing",
    "a4": "Little Green Light",
    "a5": 10,
    "a6": 10,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "Screen Shot 2020-05-27 at 7.09.31 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "shenke@nishanimals.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11640540644,
    "first": "Diana",
    "last": "Russell",
    "org": "Maria Stein Shrine of the Holy Relics",
    "email": "d.russell@mariasteinshrine.org",
    "sector": "Faith Based",
    "users": "5 - 10",
    "income": "$250,000 - $750,000",
    "software": "Little Green Light",
    "rating": 8,
    "a1": "LGL is very affordable especially compared to other CRMs. All staff can access contact information in one place - if there is an update or change it is made one place and one time and all can access. It is cloudbase - we can access form our computers or phones.",
    "a2": "Limited on making emails look nice - but nice that emails are personalized. Lack of reporting and chart/graph tools to plan for the future.",
    "a3": "I really appreciate the personalized email and that the contact is recorded into the CRM, but it would be nice if the email capability were more sophisticated to enable a more attractive email message.    I can export excel reports for analyzing data - but being able to export charts and graphs from CRM to report to committees and to make an action plan for the future would be helpful.",
    "a4": "Little Green Light",
    "a5": 9,
    "a6": 10,
    "a7": 7,
    "a8": 10,
    "a9": 5,
    "a10": 6,
    "a11": 9,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11629134136,
    "first": "Dawn",
    "last": "Matheny",
    "org": "Veriditas",
    "email": "dawn@veriditas.org",
    "sector": "Other (please specify)",
    "users": "1 - Just Me",
    "income": "$250,000 - $750,000",
    "software": "Little Green Light",
    "rating": 9,
    "a1": "It is customizable to our needs, comprehensive in what it covers and it's easy to use. My development coordinator likes it.",
    "a2": "It is not coordinated with our event data base so we end up doing double entry and having to import data from our event data base.",
    "a3": "Develop interactivity with other data bases",
    "a4": "Little Green Light",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 8,
    "a9": 10,
    "a10": 3,
    "a11": 10,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628629780,
    "first": "Cyronica",
    "last": "Upshaw",
    "org": "National MS Society",
    "email": "cyronica.upshaw@nmss.org",
    "sector": "Medical & Wellness",
    "users": "50+ Users",
    "income": "$10,000,000 - $50,000,000",
    "software": "Luminate",
    "rating": 7,
    "a1": "that it tracks donations and registrations for events, mainly online; its also user friendly; it has email templates and reporting",
    "a2": "it does not track sponsorship checks or sponsor information for fundraising events; It isn't the most user-friendly for creating an actual email template. Many times the user of the back-end is an event manager that needs to communicate or make consistent back-end updates. It can be a bit time consuming! It can sometimes be tricky to use for event registrants as its not easy to edit the donation amounts",
    "a3": "I'd have reports set up that automatically populate",
    "a4": "Luminate",
    "a5": 8,
    "a6": 5,
    "a7": 5,
    "a8": 7,
    "a9": 7,
    "a10": 8,
    "a11": 8,
    "a12": "Surveyresponse.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "cyronicau@hotmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11636302235,
    "first": "Cathy",
    "last": "Whitlock",
    "org": "Parkinson's Foundation",
    "email": "cwhitlock@parkinson.org",
    "sector": "Medical & Wellness",
    "users": "10 - 49",
    "income": "$10,000,000 - $50,000,000",
    "software": "Luminate Online",
    "rating": 4,
    "a1": "The email functionality is nice. The theory behind the CRM, of integrating with other Blackbaud products, is nice but isn't actually carried out in practice.",
    "a2": "How difficult it is to sync with other Blackbaud products, how there is no useful social media integration, reporting is terrible, advertising attribution is impossible, their customer service is generally deplorable",
    "a3": "I'm not sure I'd tell them to improve anything; I think the vendor's product suite is a lost cause. I'd rather start over again with Salesforce.",
    "a4": "Luminate Online",
    "a5": 5,
    "a6": 2,
    "a7": 3,
    "a8": 2,
    "a9": 7,
    "a10": 1,
    "a11": 2,
    "a12": "Luminate-screenshot.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "cwhitlock@parkinson.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628350344,
    "first": "Jeanne",
    "last": "Leonard",
    "org": "Association Leadership Partners",
    "email": "jeanne@association-leaders.com",
    "sector": "Associations & Memberships",
    "users": "5 - 10",
    "income": "$250,000 - $750,000",
    "software": "MemberClicks",
    "rating": 10,
    "a1": "It has  an ease of use, fits our needs for keeping member data and being able to access the different data points",
    "a2": "The customization is needed for most types of reporting and record keeping. There is no way to capture historical data",
    "a3": "Reporting features",
    "a4": "MemberClicks",
    "a5": 9,
    "a6": 10,
    "a7": 9,
    "a8": 8,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662482346,
    "first": "Kim",
    "last": "Perrella",
    "org": "ENYCAR",
    "email": "kim@enycar.org",
    "sector": "Associations & Memberships",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "Microsoft Access",
    "rating": 7,
    "a1": "Easy input for storing databases.  Can handle multiple databases at once and can manipulate as needed for new ones.",
    "a2": "Somehwat limited in what you can do with the lists.  Don't have that much need for large lists, so it works right now for our needs.",
    "a3": "Additional options for use",
    "a4": "Microsoft Access",
    "a5": 7,
    "a6": 7,
    "a7": 6,
    "a8": 7,
    "a9": 5,
    "a10": 5,
    "a11": 7,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689758401,
    "first": "Bambie",
    "last": "Hayes-Brown",
    "org": "Georgia Advancing Communities Together, Inc.",
    "email": "bhayesbrown@georgiaact.org",
    "sector": "Associations & Memberships",
    "users": "1 - Just Me",
    "income": "$100,000 - $250,000",
    "software": "Microsoft Excel",
    "rating": 5,
    "a1": "Since it is an older software, it can work for small organizations. Sometimes we use an intern or an older worker and they are pretty much familiar with using basic Excel functions.",
    "a2": "It is not as automated and is labor intensive. Spreadsheets can get very long and if one is not familiar with formulas, then it could be difficult to sort the data.",
    "a3": "Reduce the cost for small nonprofits to be able to afford it.",
    "a4": "Microsoft Excel",
    "a5": 5,
    "a6": 3,
    "a7": 5,
    "a8": 5,
    "a9": 5,
    "a10": 5,
    "a11": 5,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661826568,
    "first": "Karen",
    "last": "Suero",
    "org": "LB Reach",
    "email": "karen@karenssuero.com",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "Microsoft Excel",
    "rating": 6,
    "a1": "Nothing is easy with excel, but we do like that we have so much history stored. We have years of data.",
    "a2": "Hard to navigate and we need to update. We need to move into the 21st century but cost is a huge factor and skill set of the person using the database.",
    "a3": "Create more of a database system.",
    "a4": "Microsoft Excel",
    "a5": 6,
    "a6": 5,
    "a7": 6,
    "a8": 6,
    "a9": 5,
    "a10": 6,
    "a11": 6,
    "a12": "DATA CONFIDENTIAL.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "karen@karenssuero.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11624485592,
    "first": "Jeremiah",
    "last": "Smith",
    "org": "Everyone, Inc",
    "email": "jeremiah.smith@everyonesagrantwriter.org",
    "sector": "Other (please specify)",
    "users": "1 - Just Me",
    "income": "$0 - $100,000",
    "software": "Microsoft Excel",
    "rating": 5,
    "a1": "Simplicity: I have very little tracking required and so it is appropriate for me. I have no need for a CRM/Donor Management Software",
    "a2": "Simplicity - no functionality. If I had any measurable need to document donors or donations, it would not work.",
    "a3": "N/A",
    "a4": "Microsoft Excel",
    "a5": 9,
    "a6": 7,
    "a7": 3,
    "a8": 5,
    "a9": 5,
    "a10": 3,
    "a11": 0,
    "a12": "Excel screenshot.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jeremiah.abrahm.smith@gmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11624455477,
    "first": "Brent",
    "last": "DuBeshter",
    "org": "University of Rochester Medical Center",
    "email": "brent_dubeshter@urmc.rochester.edu",
    "sector": "Medical & Wellness",
    "users": "50+ Users",
    "income": "$250,000,000+",
    "software": "Microsoft Excel",
    "rating": 5,
    "a1": "I don't use it - and I'm not familiar with what software you're considering - best CRM I know of is saleforce",
    "a2": "Not easily customizable - too generic - and I'm not familiar with what software this survey was considering",
    "a3": "not applicable",
    "a4": "Microsoft Excel",
    "a5": 5,
    "a6": 5,
    "a7": 5,
    "a8": 5,
    "a9": 5,
    "a10": 5,
    "a11": 3,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628519520,
    "first": "Dianne",
    "last": "Valentin",
    "org": "The Black Heritage Museum & Cultural Center, Inc.",
    "email": "diannevalentin@bhmacc.org",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "MightyCause",
    "rating": 10,
    "a1": "They keep me up to date, the cost is fair and provides a number of services that are easy to incorporate.",
    "a2": "I don't have a dislike at this point. But we are small. I use Network for Good for a larger organization that I run and I wish they were as modern and seamless and MightyCause.",
    "a3": "More availability for smaller organizations. They are already doing a great job so far.",
    "a4": "MightyCause",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 5,
    "a10": 10,
    "a11": 10,
    "a12": "MightyCause page 20200522_151929.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "diannevalentin@bhmacc.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11638740507,
    "first": "Courtney",
    "last": "Stuecheli",
    "org": "Telluride Adaptive Sports Program",
    "email": "director@tellurideadaptivesports.org",
    "sector": "Other (please specify)",
    "users": "1 - Just Me",
    "income": "$250,000 - $750,000",
    "software": "NeonOne",
    "rating": 4,
    "a1": "It's much easier to send thank you acknowledgments and to keep track of donations over time.  It's fairly simple to learn and to update.",
    "a2": "It doesn't synch from quickbooks to Neon only Neon to quickbooks.  This is very problematic for my organization.  There is quite a bit of confusion stemming from who entered what and my book keeper doesn't always know what's been entered and then there seems to be double entries.  We need to find a better flow of input.",
    "a3": "To synch from both QB to Neon and vice versa",
    "a4": "NeonOne",
    "a5": 4,
    "a6": 5,
    "a7": 2,
    "a8": 4,
    "a9": 5,
    "a10": 5,
    "a11": 3,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628680112,
    "first": "Audre",
    "last": "Adamson",
    "org": "Craig Field Airport and Industrial Authority",
    "email": "audreycraigairport@bellsouth.net",
    "sector": "Other (please specify)",
    "users": "1 - Just Me",
    "income": "$1,500,00 - $3,000,000",
    "software": "NeonOne",
    "rating": 10,
    "a1": "It's user friendly and i can figure out how to do things easily based on materials already included into the service itself.",
    "a2": "i can't connect to all the softwares I want to intergrade.  We don't use constant contact; we use other cheaper solutions.",
    "a3": "Mailouts to donros is overly simplistic in it's astetic",
    "a4": "NeonOne",
    "a5": 9,
    "a6": 9,
    "a7": 10,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 10,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628341467,
    "first": "Jill",
    "last": "Senecal",
    "org": "The Foodbank of Southern California",
    "email": "Donate@foodbankofsocal.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$250,000,000+",
    "software": "NeonOne",
    "rating": 9,
    "a1": "It is very intuitive and easy to use. Highly functional. Easy to train others. Extensive accessible knowledge base.",
    "a2": "Only have email tech support (by choice), so issues take a day or two to resolve. With that said solutions they provide work.",
    "a3": "Enhanced ability to change layout of system features.",
    "a4": "NeonOne",
    "a5": 9,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 5,
    "a11": 9,
    "a12": "NeonOne Dashboard screen shot.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "Jill.Senecal@gmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662577494,
    "first": "Melissa",
    "last": "Fowler",
    "org": "NAF",
    "email": "mfowler@prochoice.org",
    "sector": "Associations & Memberships",
    "users": "50+ Users",
    "income": "$250,000 - $750,000",
    "software": "Netforum",
    "rating": 4,
    "a1": "the potential of using a CRM is something I appreciate and I like some parts of the marketing module",
    "a2": "Difficult to customize; functions like recurring donations don't work; limited functionality for the public-facing eweb site that the system supports",
    "a3": "Better training and guides--not just a wiki",
    "a4": "Netforum",
    "a5": 3,
    "a6": 3,
    "a7": 2,
    "a8": 4,
    "a9": 4,
    "a10": 4,
    "a11": 4,
    "a12": "screenshot.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "mfowler@prochoice.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11658917362,
    "first": "Charles",
    "last": "Newell",
    "org": "Rural Strategies",
    "email": "marty@ruralstrategies.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "Network for Good",
    "rating": 6,
    "a1": "familiar, been using for more than 10 years. we have stuck with this vendor through several versions/names",
    "a2": "cost and functionality. we have stuck with this application through inertia but a change is gotta come",
    "a3": "cost per transaction plus subscription fee means it is is expensive compared to competition",
    "a4": "Network for Good",
    "a5": 6,
    "a6": 6,
    "a7": 7,
    "a8": 5,
    "a9": 5,
    "a10": 6,
    "a11": 3,
    "a12": "NFG Dashboard.Newell.Rural Strategies.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "marty@ruralstrategies.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11696094248,
    "first": "Tom",
    "last": "Bove",
    "org": "Loudoun Community Cat Coalition",
    "email": "info@loudouncommunitycats.org",
    "sector": "Animal & Wildlife",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "Network for Good",
    "rating": 4,
    "a1": "Low merchant processing costs. Ease of setting up donation integration. Donor reports are in a good format",
    "a2": "Too complicated to use donor management. New event management module is so different and complex we stopped using it all together",
    "a3": "Training videos on how to use their software. It feels like a bunch of disjointed applications bundled into 1 product",
    "a4": "Network for Good",
    "a5": 0,
    "a6": 4,
    "a7": 4,
    "a8": 5,
    "a9": 7,
    "a10": 0,
    "a11": 4,
    "a12": "Screenshot_20200614-104947_Chrome.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "Info@loudouncommunitycats.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662457398,
    "first": "Jessica",
    "last": "A",
    "org": "GUIDE, Inc.",
    "email": "jessica@guideinc.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "Network for Good",
    "rating": 9,
    "a1": "It's easy to track everything in one dashboard. We can send emails, segment lists, check donations, set up fundraising pages or monthly donors, etc. It's easy, safe and user-friendly.",
    "a2": "It doesn't fundraise for me! Seriously, I can't think of anything that I don't like aside from the work I need to put in. The cost is a doozy for us, but it’s also very reasonable in the grand scheme of things.",
    "a3": "Nothing that I can think of.",
    "a4": "Network for Good",
    "a5": 10,
    "a6": 8,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 9,
    "a12": "NFG.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jessica@guideinc.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662304883,
    "first": "Karen",
    "last": "Mineo",
    "org": "Advocates for Homeless & Those in Need (AHTN)",
    "email": "KMineo@ahtn.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "Network for Good",
    "rating": 10,
    "a1": "N4G is a really good tool for AHTN.  It's easy to use.  It has the following features:  Donorbase; online campaigns; email blasts; event purchasing; reports; thank you letters to donors; donation reporting, etc.  We have been using it for a few years now and love it.",
    "a2": "There really isn't anything that AHTN dislikes about N4G.  We have used 2 other CRMS but had to also buy Constant Contact and an online campaign platform.",
    "a3": "I don't have any specific suggestions for improving N4G.",
    "a4": "Network for Good",
    "a5": 9,
    "a6": 8,
    "a7": 10,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 10,
    "a12": "N4G Payment.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "KMineo@ahtn.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661747560,
    "first": "Karen",
    "last": "Mineo",
    "org": "Advocates for Homeless & Those in Need (AHTN)",
    "email": "Kmineo@ahtn.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "Network for Good",
    "rating": 9,
    "a1": "Easy to use.  We can send blast emails; track donors; thank them; use for RSVP to events.  Network for Good satisfies all of our needs. I would recommend this donor base for small nonprofits who are growing. We have grown from 200 donors to over 1200 and it has grown with us.",
    "a2": "I haven’t found anything to dislike.  We started our with 200 donors and have grown to over 1200.  We have had to pay for the next level so we had to have the additional money in the budget but it has been worth it.",
    "a3": "Maybe our Admin has improvement ideas but I don’t have any issues.",
    "a4": "Network for Good",
    "a5": 9,
    "a6": 8,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 10,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661802406,
    "first": "Stephan",
    "last": "Guy",
    "org": "Engineers in Action",
    "email": "srguy55@gmail.com",
    "sector": "International",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "Network for Good",
    "rating": 9,
    "a1": "easy to use , donations made online added automatically, easy to download donor info, communication system.",
    "a2": "was hard to learn how to use and how to build communication templates getting easier but still needs improvement",
    "a3": "continue to improve communication systems and integration with constant contact so can move off cc",
    "a4": "Network for Good",
    "a5": 8,
    "a6": 7,
    "a7": 9,
    "a8": 10,
    "a9": 10,
    "a10": 9,
    "a11": 10,
    "a12": "06  zINV NFG  06012020.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "srguy55@gmail.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11629047321,
    "first": "Beverly",
    "last": "Katz",
    "org": "Analytic Consultants, inc.",
    "email": "blkatz@analytic-consultants.com",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$10,000,000 - $50,000,000",
    "software": "Network for Good",
    "rating": 8,
    "a1": "Network for Good software is comprehensive, covers many diverse needs of non-profits and fundraisers. The software is robust, has solutions for most fundraising needs, responds quickly, provides opportunity to print and save input.",
    "a2": "Network for Good fundraising software requires that fundraisers work within the templates and thought process offered.  It lacks the flexibility to customize to the user organization’s needs on the fly, and without cost.  It often is behind best practices.",
    "a3": "stay ahead of best practices, incorporate research to improve functionality, templates, formats",
    "a4": "Network for Good",
    "a5": 8,
    "a6": 8,
    "a7": 9,
    "a8": 8,
    "a9": 7,
    "a10": 6,
    "a11": 9,
    "a12": "Network for Good Software.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "blkatz@analytic-consultants.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662296601,
    "first": "Christine",
    "last": "Lucas",
    "org": "Leadership Florida",
    "email": "clucas@leadershipflorida.org",
    "sector": "Associations & Memberships",
    "users": "10 - 49",
    "income": "$1,500,00 - $3,000,000",
    "software": "Novi",
    "rating": 8,
    "a1": "Integrates our membership database, website and events. They are continually improving the product, and we can use the data from our system to do bulk e-mailing and accounting.",
    "a2": "Novi is not fully integrated into Quickbooks and Constant Contact/Mailchimp yet, so we run reports to use those softwares, which can be tedious.",
    "a3": "Would like fuller integration with Quickbooks",
    "a4": "Novi",
    "a5": 7,
    "a6": 8,
    "a7": 7,
    "a8": 9,
    "a9": 5,
    "a10": 10,
    "a11": 8,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689204168,
    "first": "Dave",
    "last": "Archuletta",
    "org": "New York Live Arts",
    "email": "darchuletta@newyorklivearts.org",
    "sector": "Arts & Culture",
    "users": "10 - 49",
    "income": "$3,000,000 - $10,000,000",
    "software": "Patron Manager",
    "rating": 10,
    "a1": "Built on Salesforce, it is easy to train, use, and customize. The app marketplace is robust with many customizations, and the user community is responsive and full of expertise.",
    "a2": "There's nothing I dislike about this software, and I've used a lot of others before. The only thing I would point to here is the annual contract cost, which - while substantial - is still comparable with other enterprise CRM platforms.",
    "a3": "Custom donation forms still need some work, however they have improved significantly in the last few months.",
    "a4": "Patron Manager",
    "a5": 10,
    "a6": 8,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Screen Shot 2020-06-11 at 3.50.57 PM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "darchuletta@newyorklivearts.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662266642,
    "first": "Caryn",
    "last": "Fraim",
    "org": "The Choral Arts Society of Washington",
    "email": "cfraim@choralarts.org",
    "sector": "Arts & Culture",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "PatronManager",
    "rating": 9,
    "a1": "Integrated accounts - ticket purchase history, donations, e-marketing and snail mail and event campaigns, all in one place.  they handle all of the credit card processing and merchant accounts and ensure we are compliant",
    "a2": "like most database systems, when data is stored in different places, you can't always run a report that gives you *exactly* what you want, especially if what you are looking for is stored in different data tables.",
    "a3": "see above with the DISLIKE section.",
    "a4": "PatronManager",
    "a5": 10,
    "a6": 5,
    "a7": 9,
    "a8": 9,
    "a9": 8,
    "a10": 7,
    "a11": 10,
    "a12": "Screen Shot 2020-06-03 at 10.27.39 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "cfraim@choralarts.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661874811,
    "first": "Andy",
    "last": "Abramson",
    "org": "Comunicano",
    "email": "aabramson@comunicano.com",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "Pipedrive CRM",
    "rating": 5,
    "a1": "We know we need to learn more about Fundraising CRM as a board member, who has oversight of our annual giving and sustained giving program, plus works with our corporate sponsors.",
    "a2": "It is not designed for non-profits and is really made for businesses that operate as for profit, not a NPO. As a result it's not as friendly to our staff's usage.",
    "a3": "Create a non-profit module, develop charity level pricing for 501-C-3 organizations.",
    "a4": "Pipedrive CRM",
    "a5": 6,
    "a6": 2,
    "a7": 0,
    "a8": 4,
    "a9": 10,
    "a10": 10,
    "a11": 0,
    "a12": "Screenshot.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "aabramson@comunicano.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11676460369,
    "first": "William",
    "last": "Gokee",
    "org": "Frontline Ministries International",
    "email": "whgokee@frontline-ministries.org",
    "sector": "Faith Based",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "Quickbooks",
    "rating": 8,
    "a1": "This software is well-organized and easy to use for our treasurer who is not trained in finances. It also has easy to print financial reports for leadership meetings",
    "a2": "It doesn't seem to offer a feature for fundraising solutions or such things like a integrated giving app that doesn't cost extra as part of the service package that could be offered.",
    "a3": "Fundraising solutions added; giving app service offered",
    "a4": "Quickbooks",
    "a5": 8,
    "a6": 8,
    "a7": 7,
    "a8": 8,
    "a9": 8,
    "a10": 4,
    "a11": 7,
    "a12": "Quickbooks Account Verification.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "mpgokee@gmail.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663913103,
    "first": "Shariq",
    "last": "Siddiqui",
    "org": "Center on Muslim Philanthropy",
    "email": "siddiquisa@gmail.com",
    "sector": "Faith Based",
    "users": "5 - 10",
    "income": "$100,000 - $250,000",
    "software": "Quickbooks",
    "rating": 3,
    "a1": "I like the fact that the system is connected to our financial system.  Makes end of year reporting easier.",
    "a2": "The challenge is that it is not a fundraising system.  It is made for accountants and finance professionals.",
    "a3": "Consider adding fundraising or nonprofit friendly options.",
    "a4": "Quickbooks",
    "a5": 1,
    "a6": 9,
    "a7": 2,
    "a8": 2,
    "a9": 2,
    "a10": 2,
    "a11": 0,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11698594139,
    "first": "Jennifer",
    "last": "Stapleton",
    "org": "WomenSafe, Inc.",
    "email": "finance@womensafe.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "Quickbooks",
    "rating": 10,
    "a1": "Easy to use.  Our software makes it very easy to track donors, their current donations and historical information.",
    "a2": "Nothing.  I have used many different software systems in my Nonprofit career and can safely say I'd change nothing.",
    "a3": "Nothing",
    "a4": "Quickbooks",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Intuit.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "finance@womensafe.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628141191,
    "first": "Rhonda",
    "last": "Wotowis",
    "org": "Kendrick Fincher Hydration for Life",
    "email": "rhonda@kendrickfincher.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "QuickBooks for nonprofits",
    "rating": 7,
    "a1": "I don't. We need something better, but QB is an affordable way to track donors and get some reports.",
    "a2": "Not good for data tracking and notes. We can retrieve donor reports, but we can't make notations for donor stewardship.",
    "a3": "Donor data tracking within QuickBooks could help for small nonprofits.",
    "a4": "QuickBooks for nonprofits",
    "a5": 9,
    "a6": 8,
    "a7": 2,
    "a8": 5,
    "a9": 8,
    "a10": 5,
    "a11": 9,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662446345,
    "first": "Charlisa",
    "last": "Garg",
    "org": "Teachers College Columbia University",
    "email": "charlisa.garg@tc.edu",
    "sector": "Education",
    "users": "10 - 49",
    "income": "$10,000,000 - $50,000,000",
    "software": "Raiser’s Edge",
    "rating": 6,
    "a1": "Integrated use for fundraising and donor management, events management, alumni relations and management",
    "a2": "Some functions are not as intuitive, and the system often needs extensive tweaking and customization to increase effectiveness",
    "a3": "That said, the customization is part of what makes RE such a great tool - I just wish it was easier to do at the user level    As a corporate and foundation relation professional I do find that the grants management a d reporting aspect of RE is not as useful as it could be - generally resulting in having to create customized reports for everything",
    "a4": "Raiser’s Edge",
    "a5": 3,
    "a6": 7,
    "a7": 6,
    "a8": 3,
    "a9": 7,
    "a10": 5,
    "a11": 7,
    "a12": "4C176924-3C05-46ED-BD4F-4A9A2C2A75EA.jpeg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "charligarg@hotmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11667587296,
    "first": "John",
    "last": "Masino",
    "org": "Multiple Sclerosis Association of America (MSAA)",
    "email": "jmasino@mymsaa.org",
    "sector": "Human Services",
    "users": "10 - 49",
    "income": "$3,000,000 - $10,000,000",
    "software": "Raiser’s Edge",
    "rating": 6,
    "a1": "Luminate Online's email marketing and donation forms; Luminate CRM's basic design built on Salesforce's platform",
    "a2": "The integration can potentially cause a large number of duplicate records upon go-live if your database is not accurately clean",
    "a3": "The ability to merge in Luminate Online gets turned off when integrated with the CRM database for data integrity purposes; it would be great to keep that but only to be used by select super admins",
    "a4": "Raiser’s Edge",
    "a5": 6,
    "a6": 7,
    "a7": 4,
    "a8": 6,
    "a9": 4,
    "a10": 8,
    "a11": 7,
    "a12": "LO and LCRM screenshots.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jmasino@mymsaa.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11640594621,
    "first": "Robin",
    "last": "Doeden",
    "org": "Momentous Institute",
    "email": "rdoeden@momentousinstitute.org",
    "sector": "Medical & Wellness",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "Raisers Edge",
    "rating": 9,
    "a1": "Flexible, complete, easy to use, easy to access whether at home or in the office .............................",
    "a2": "reports and queries are not intuitive; training is expensive; ....................................................",
    "a3": "access to easy to follow instructions for pulling reports and running inquiries",
    "a4": "Raisers Edge",
    "a5": 7,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 10,
    "a10": 7,
    "a11": 10,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689309497,
    "first": "Michael",
    "last": "Bittel",
    "org": "ABTA",
    "email": "mbittel@abta.org",
    "sector": "Medical & Wellness",
    "users": "10 - 49",
    "income": "$3,000,000 - $10,000,000",
    "software": "Raiser's Edge",
    "rating": 6,
    "a1": "It does anything you need and has a long history of being a part of fundraising. It has the benefit of that history!",
    "a2": "I wish it would eaiser to navigate. NXT is okay but the reporting possibilities are not robust enough. I think it needs to move in the next century.",
    "a3": "Go back and improve the tables, and make the NXT platform more robust for reporting",
    "a4": "Raiser's Edge",
    "a5": 6,
    "a6": 4,
    "a7": 7,
    "a8": 6,
    "a9": 7,
    "a10": 5,
    "a11": 7,
    "a12": "RE Login.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "michaelbittel@hotmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11692849415,
    "first": "Pam",
    "last": "Mueller",
    "org": "School Sisters of St. Francis",
    "email": "pmueller@sssf.org",
    "sector": "Faith Based",
    "users": "10 - 49",
    "income": "$1,500,00 - $3,000,000",
    "software": "Raiser's Edge",
    "rating": 5,
    "a1": "Robust features, blends with financial edge that we use for our international community across 11 countries",
    "a2": "slow in response to upgrades and constant staff changes as a resource.  Expensive modules and maintenance fees",
    "a3": "Reaching out to users  before making changes or addressing user needs more timely",
    "a4": "Raiser's Edge",
    "a5": 5,
    "a6": 4,
    "a7": 7,
    "a8": 8,
    "a9": 2,
    "a10": 0,
    "a11": 5,
    "a12": "Raiser's edge print screen.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "pmueller@sssf.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689371338,
    "first": "Mark",
    "last": "Simonson",
    "org": "Make-A-Wish America",
    "email": "msimonson@wish.org",
    "sector": "Human Services",
    "users": "50+ Users",
    "income": "$50,000,000 - $250,000,000",
    "software": "Raiser's Edge",
    "rating": 5,
    "a1": "I really don't like it. But I suppose it is reliable and most people in the non-profit industry are familiar with how to use it.",
    "a2": "Clunky, old, too many clicks to do something, queries are difficult to build, not web-based so I have to be connected to my company's server",
    "a3": "Make it easy on the eyes, make it intuitive, make it faster",
    "a4": "Raiser's Edge",
    "a5": 3,
    "a6": 5,
    "a7": 7,
    "a8": 4,
    "a9": 4,
    "a10": 4,
    "a11": 3,
    "a12": "RE screensot.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "msimonson@wish.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11667623484,
    "first": "Ellen",
    "last": "Kallman",
    "org": "Melmark New England",
    "email": "ekallman@melmarkne.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "Raiser's Edge",
    "rating": 8,
    "a1": "It is comprehensive package that provides various levels of engagement for users and also offers many support offerings. Blackbaud is a tried and true leader in the industry.",
    "a2": "It doesn't interface with many popular online giving tools. This results in donor and gift information having to be entered multiple times.",
    "a3": "Support your clients by creating workable interfaces for other products, especially online gift processing and event management resources.",
    "a4": "Raiser's Edge",
    "a5": 7,
    "a6": 7,
    "a7": 8,
    "a8": 8,
    "a9": 8,
    "a10": 7,
    "a11": 8,
    "a12": "Screenshot (6).png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "ekallman@melmarkne.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11666125928,
    "first": "Sel",
    "last": "Vel",
    "org": "Sterling",
    "email": "ivela@sterlingnonprofits.com",
    "sector": "Other (please specify)",
    "users": "10 - 49",
    "income": "$0 - $100,000",
    "software": "Raiser's Edge",
    "rating": 9,
    "a1": "There are so many capabilities within the database if implemented and set up properly. Blackbaud has improved many functions to the data clean up and has made the duplicate wizard removals much easier to work with, which both functions help maintain the entire database clean. They have added data enrichment modules for e-mail and phone clean up and wealth analytics and prospect research. Analyzing donor activity within the query capabilities allows for reports to run on many metrics within the database. Queries can be pulled based on wealth capacity, sector, wealth range, relationships, interest – there are so many variables. We have found the preset custom report feature very helpful.",
    "a2": "Raiser’s Edge is a complex database that organizations can find difficult to manage and work.  It can be an intimidating database if the proper training is not accessible or available and difficult to work with or have it be successful if not properly set up. The software is so comprehensive and highly customizable platform, the implementation process is crucial part of having a successful working database.  Seeking a professional dedicated to Blackbaud Raiser’s Edge is recommended with the implantation, customizing, and training. Blackbaud support can help with many questions but are not always able to answer all questions.",
    "a3": "Blackbaud has made training and online video's accessible. Extremely helpful. Keep it up.",
    "a4": "Raiser's Edge",
    "a5": 7,
    "a6": 10,
    "a7": 8,
    "a8": 10,
    "a9": 10,
    "a10": 9,
    "a11": 8,
    "a12": "RE snapshot.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "ivela@sterlingnonpofits.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663630853,
    "first": "Stacey",
    "last": "Slevcove",
    "org": "Linc Housing",
    "email": "sslevcove@linchousing.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "Raiser's Edge",
    "rating": 5,
    "a1": "My team has enjoyed improvements with NXT that make it easier to see and enter data and information on donors, as well as mobile friendly capability for looking up information on the go. privacy and security were another reason this platform was initially selected.",
    "a2": "The cost is high and there is a steep learning curve for new users to be introduced to the system, become proficient, and utilize the full set of features that Blackbaud offers.",
    "a3": "ease of login and account management, more features to NXT",
    "a4": "Raiser's Edge",
    "a5": 5,
    "a6": 4,
    "a7": 6,
    "a8": 5,
    "a9": 6,
    "a10": 5,
    "a11": 4,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663616587,
    "first": "Malcolm",
    "last": "Radke",
    "org": "LRHF",
    "email": "malcolm.radke@lrhf.ca",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "Raiser's Edge",
    "rating": 6,
    "a1": "We like the reporting for back-end staff, and the integration with Financial Edge and our bookkeeping software.",
    "a2": "Cost, a more basic version for cheaper would be nice. Fundraisers find it difficult to navigate as well, which means they view it less as a helpful tool and more like an obligation of their job.",
    "a3": "Cheaper more basic version",
    "a4": "Raiser's Edge",
    "a5": 3,
    "a6": 3,
    "a7": 9,
    "a8": 6,
    "a9": 3,
    "a10": 9,
    "a11": 4,
    "a12": "RE NXT screenshot.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "malcolmradke@hotmail.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662718539,
    "first": "doug",
    "last": "cohen",
    "org": "heckscher museum",
    "email": "cohen@heckscher.org",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "Raiser's Edge",
    "rating": 6,
    "a1": "It's used by other staff. I do not use it as the company CFO. There are four users in the development department that use the software on a daily basis, and I believe they like the reporting features.",
    "a2": "Expensive. The annual costs of the software are high. This expense includes maintenance and external hosting.",
    "a3": "Lower the cost.",
    "a4": "Raiser's Edge",
    "a5": 7,
    "a6": 4,
    "a7": 7,
    "a8": 7,
    "a9": 5,
    "a10": 5,
    "a11": 5,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662413359,
    "first": "Vanessa",
    "last": "Underwood",
    "org": "JA Canada",
    "email": "vunderwood@jacanada.org",
    "sector": "Education",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "Raiser's Edge",
    "rating": 6,
    "a1": "One place for tracking everything fundraising related, including event registrations and integration with web donations",
    "a2": "Doesn't integrate with finance, so entries have to be duplicated; need the ability to put probabilities into the pipeline",
    "a3": "Introduce a low- or no-cost API for finance integration",
    "a4": "Raiser's Edge",
    "a5": 3,
    "a6": 5,
    "a7": 5,
    "a8": 7,
    "a9": 3,
    "a10": 7,
    "a11": 6,
    "a12": "Blackbaud Invoice# 91890343 SiteID 16269 BU1050-J.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "vunderwood@jacanada.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661995079,
    "first": "Maureen",
    "last": "Gerard",
    "org": "Foundry Educational Foundation",
    "email": "maureen@fefinc.org",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "Raiser's Edge",
    "rating": 9,
    "a1": "Complete system that includes many fundraising technique options for donor stewardship, engagement, etc.",
    "a2": "Not easy to learn the system, record management is archaic and not easy to connect husband and wife donors, donors with company, reports and queries are not easy to learn",
    "a3": "Make it more intuitive and record management easier",
    "a4": "Raiser's Edge",
    "a5": 6,
    "a6": 8,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 8,
    "a11": 9,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661910138,
    "first": "Steven",
    "last": "Cobb",
    "org": "California Community Foundation",
    "email": "scobb@calfund.org",
    "sector": "Foundations",
    "users": "10 - 49",
    "income": "$250,000,000+",
    "software": "Raiser's Edge",
    "rating": 9,
    "a1": "comprehensive solution with complementary products from same vendor; large capacity; large user base; regular updates",
    "a2": "cost is reasonable. however, lower cost always appreciated; while interface is mostly intuitive, training is important to improve productivity. formal training can be expensive.",
    "a3": "additional templates  suggested procedures / policies",
    "a4": "Raiser's Edge",
    "a5": 8,
    "a6": 7,
    "a7": 9,
    "a8": 9,
    "a9": 8,
    "a10": 9,
    "a11": 10,
    "a12": "re login scobb 6-3-2020.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "scobb@calfund.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11658857493,
    "first": "Ryan",
    "last": "Garnett",
    "org": "Harvey McKinnon Associates",
    "email": "rgarnett@harveymckinnon.com",
    "sector": "Other (please specify)",
    "users": "10 - 49",
    "income": "$1,500,00 - $3,000,000",
    "software": "Raiser's Edge",
    "rating": 6,
    "a1": "It's a pretty decent database for the most part. NXT is definitely a nice upgrade over the original.",
    "a2": "It's very expensive, especially for smaller charities that have a limited budget. Can also be tough to get service sometimes.",
    "a3": "Find better options for smaller charities",
    "a4": "Raiser's Edge",
    "a5": 6,
    "a6": 4,
    "a7": 7,
    "a8": 7,
    "a9": 6,
    "a10": 5,
    "a11": 6,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689341481,
    "first": "Steve",
    "last": "Hagerdorn",
    "org": "Catholic Foundation",
    "email": "SHagerdorn@Catholic-Foundation.org",
    "sector": "Foundations",
    "users": "1 - Just Me",
    "income": "$10,000,000 - $50,000,000",
    "software": "Raiser's Edge",
    "rating": 8,
    "a1": "Raisers Edge provides a great deal of information about donors wealth and provides our development staff with a customized carousel for donor contact.",
    "a2": "The Transition process from a prior software (FIMS), which is a product also owned by Blackbaud, has been OK but we thought it would be better.  Third party partners recommended by Blackbaud have been hit or miss in terms of their quality.",
    "a3": "The product is not inexpensive and greater transparency in pricing as well as timeliness of pricing should be better.  It is a top notch system and training is great.",
    "a4": "Raiser's Edge",
    "a5": 7,
    "a6": 7,
    "a7": 10,
    "a8": 8,
    "a9": 7,
    "a10": 7,
    "a11": 8,
    "a12": "Screenshot 2020-06-11 16.33.18.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "shagerdorn@catholic-foundation.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661795167,
    "first": "Ann",
    "last": "Puckett",
    "org": "Grand Rapids Community Foundation",
    "email": "apuckett@grfoundation.org",
    "sector": "Foundations",
    "users": "10 - 49",
    "income": "$3,000,000 - $10,000,000",
    "software": "Raiser's Edge",
    "rating": 7,
    "a1": "The ability to track and dissect constituent data in many ways.  Giving us the flexibility we need in order to provide the services to our constituents that they need and being able to target appropriately.",
    "a2": "Currently we are on the classic version and it is not a very intuitive user interface.   This requires us to conduct quite a bit of training especially when onboarding and it can be overwhelming to a new staff person.",
    "a3": "In this specific product I do not have recommendations.  Overall it would be preferred if they would connect to a PC when working with a support issue vs. going back & forth between email exchanges.  It can take forever to get a simple issue resolved.",
    "a4": "Raiser's Edge",
    "a5": 4,
    "a6": 4,
    "a7": 10,
    "a8": 6,
    "a9": 9,
    "a10": 6,
    "a11": 7,
    "a12": "Capture.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "apuckett@grfoundation.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663102952,
    "first": "Katy",
    "last": "Kearney",
    "org": "The Mini Time Machine Inc.",
    "email": "katyk@theminitimemachine.org",
    "sector": "Arts & Culture",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "Raiser's Edge",
    "rating": 5,
    "a1": "Revenue reports are easy to read and dive down into. I only use Altru to complete the financial exchange between the CRM and our accounting software",
    "a2": "It's not accounting friendly, and software not compatible with new chip credit cards so must use swipers",
    "a3": "1. Need a way to track gift cards and balances",
    "a4": "Raiser's Edge",
    "a5": 6,
    "a6": 2,
    "a7": 4,
    "a8": 6,
    "a9": 5,
    "a10": 6,
    "a11": 4,
    "a12": "Altru screenshot.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "katyk@theminitimemachine.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661768241,
    "first": "Claire",
    "last": "Cooney",
    "org": "The Conservation Fund",
    "email": "ccooney@conservationfund.org",
    "sector": "Environmental",
    "users": "10 - 49",
    "income": "$50,000,000 - $250,000,000",
    "software": "Raiser's Edge",
    "rating": 5,
    "a1": "the system is integrated with our finance team, so it is easy to track and stay coordinated with our internal teams; it is also a relatively easy system to learn how to use.",
    "a2": "the overall system and add-ons are expensive and the customer support can be lacking and slow to respond.",
    "a3": "customer service",
    "a4": "Raiser's Edge",
    "a5": 6,
    "a6": 2,
    "a7": 7,
    "a8": 6,
    "a9": 6,
    "a10": 2,
    "a11": 6,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11660385401,
    "first": "Marie",
    "last": "Johnson",
    "org": "Hilfe Group",
    "email": "Gingerluvs2bake@gmail.com",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$10,000,000 - $50,000,000",
    "software": "Raiser's Edge",
    "rating": 8,
    "a1": "I like the GUI interface and the customer service. It is great at tracking information of donors and gifts. It is also easy to train need employees on. We also love the customer service aspect of the company.",
    "a2": "Clunkiness. It is sometimes hard to pull reports and information from the system. Also, finding and merging duplicates records is difficult. Tier or membership levels are also difficult to navigate.",
    "a3": "Reports and merging records need to be less time consuming.",
    "a4": "Raiser's Edge",
    "a5": 8,
    "a6": 8,
    "a7": 7,
    "a8": 8,
    "a9": 8,
    "a10": 8,
    "a11": 8,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689295236,
    "first": "Hillary",
    "last": "Murphy",
    "org": "Phoebe Foundation, Inc.",
    "email": "hmurphy@phoebehealth.com",
    "sector": "Medical & Wellness",
    "users": "1 - Just Me",
    "income": "$3,000,000 - $10,000,000",
    "software": "Raiser's Edge",
    "rating": 9,
    "a1": "It's a one stop shop for all we do and is able to grow as we need more functionality.  It also integrates with other solutions to keep us connected.",
    "a2": "It's expensive.  I wish there was a way to reduce the cost without significanly losing key functions.",
    "a3": "I'd like to be a little more user friendly so it doesn't intimidate others in the office.  I know RE NXT is supposed to be that but the reputation is it's hard to navigate by our novice users.",
    "a4": "Raiser's Edge",
    "a5": 7,
    "a6": 7,
    "a7": 10,
    "a8": 10,
    "a9": 8,
    "a10": 7,
    "a11": 10,
    "a12": "IMG_3253.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "hmurphy@phoebehealth.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11637737055,
    "first": "Paige",
    "last": "Stryker",
    "org": "Loaves & Fsihes",
    "email": "paige@loavesandfishes.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "Raiser's Edge",
    "rating": 7,
    "a1": "The CRM software has robust depth to the information that it can hold. Batch entry is super time efficient.",
    "a2": "I wish that the web version had been built out more before they released it. We are now using a program that is half built, and they are changing functionality with each update, so processes have to be created again.",
    "a3": "Build out the web version more rapidly, and release updates to a wider set of users.",
    "a4": "Raiser's Edge",
    "a5": 6,
    "a6": 5,
    "a7": 9,
    "a8": 7,
    "a9": 5,
    "a10": 8,
    "a11": 5,
    "a12": "RE screenshot.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "paige@loavesandfishes.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11641274954,
    "first": "Chris",
    "last": "Turkmany",
    "org": "Notre Dame High School",
    "email": "turkmany@ndhs.org",
    "sector": "Animal & Wildlife",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "Raiser's Edge",
    "rating": 8,
    "a1": "Tracks donors well - Allows dated material to be recorded - Upgrades such as wealth screening can be very useful",
    "a2": "Too many variables - Misses on being friendly with my PC - Needs to be more flexible with customization",
    "a3": "Pare down what you do best",
    "a4": "Raiser's Edge",
    "a5": 8,
    "a6": 3,
    "a7": 8,
    "a8": 9,
    "a9": 8,
    "a10": 8,
    "a11": 8,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628365012,
    "first": "Lisa",
    "last": "Alexander",
    "org": "CoxHealth Foundation",
    "email": "lisa.alexander@coxhealth.com",
    "sector": "Medical & Wellness",
    "users": "10 - 49",
    "income": "$10,000,000 - $50,000,000",
    "software": "Raiser's Edge",
    "rating": 5,
    "a1": "Raiser's Edge has a reputation of being the gold standard for software. We have tried several others in year's past, one Campagne was bought out and we had no service. RE has great reporting capabilities, alot of add on modules, the ability to code beyond Fund and Campaign. It is highly functional.",
    "a2": "What I dislike is that it is not very intuitive. It does not have some fields that we feel are necessary on the main biography page, it requires alot of training and pulling names if you don't hit right on the name is not very easy. You may have to search several times a name you know is in there or use the % of function.",
    "a3": "I would expand the biography page.   Work to make it more intuitive.  Make report pulling easier for someone who does not use it often  The Query function is highly challenging at times.   I would appreciate better service and fewer calls trying to up sell me on modules or RE XT.",
    "a4": "Raiser's Edge",
    "a5": 3,
    "a6": 4,
    "a7": 6,
    "a8": 5,
    "a9": 7,
    "a10": 4,
    "a11": 6,
    "a12": "RE Screenshot.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "lisa.alexander@coxhealth.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11648529741,
    "first": "Karen",
    "last": "DiMaria",
    "org": "Summit Area YMCA",
    "email": "karen.dimaria@thesay.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "Raiser's Edge",
    "rating": 9,
    "a1": "I don't particularly LOVE anything about it - I am just used to it after so many years. It is functional and efficient.",
    "a2": "Raiser's Edge makes it difficult to automatically interface with other programs. If you contract with them it is expensive to develop a program to make two systems connect = Like a University ERM to obtain alumni data.",
    "a3": "While any information in Excel can be uploaded, like Apple products, Raiser's Edge makes it hard to integrate with other programs automatically, OR if they can create an interface, it's expensive.",
    "a4": "Raiser's Edge",
    "a5": 8,
    "a6": 7,
    "a7": 10,
    "a8": 8,
    "a9": 10,
    "a10": 5,
    "a11": 10,
    "a12": "Signed in to Raisers Edge 5-29-2020.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "karen.dimaria@thesay.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11644734994,
    "first": "Jen",
    "last": "French",
    "org": "Neurotech Network",
    "email": "jfrench@neurotechnetwork.org",
    "sector": "Medical & Wellness",
    "users": "1 - Just Me",
    "income": "$0 - $100,000",
    "software": "Raiser's Edge",
    "rating": 6,
    "a1": "The options for use. There are several modules or add-ons that are customizable which makes it easy to adapt for specific situations",
    "a2": "The clunky interface needs to be a one-all interface rather than logging into several different modules",
    "a3": "Improve the integration of the old system with the new icloud. Many features are missing and it makes it difficult to use.",
    "a4": "Raiser's Edge",
    "a5": 4,
    "a6": 3,
    "a7": 9,
    "a8": 6,
    "a9": 5,
    "a10": 7,
    "a11": 3,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628720561,
    "first": "Susan",
    "last": "Pottberg",
    "org": "Miracle Hill Ministries",
    "email": "spottberg@miraclehill.org",
    "sector": "Human Services",
    "users": "10 - 49",
    "income": "$10,000,000 - $50,000,000",
    "software": "Raiser's Edge",
    "rating": 2,
    "a1": "NXT is the only modern online part of the program that is easy to use. Our team likes the versatility of this part of Blackbaud.",
    "a2": "Everything is done through different modules that do not work together seamlessly. The support team isn't knowledgeable. Everything is too expensive and cumbersome to use.",
    "a3": "All the different programs need to be combined into one more modern up to date, easy to use software program.",
    "a4": "Raiser's Edge",
    "a5": 2,
    "a6": 1,
    "a7": 5,
    "a8": 0,
    "a9": 3,
    "a10": 0,
    "a11": 1,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11637570810,
    "first": "Donald",
    "last": "Raack",
    "org": "AltruNext",
    "email": "don@altrunext.com",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "Raiser's Edge",
    "rating": 7,
    "a1": "The usability and interface are state of the art. Reports are fairly easy to generate. Service is available mostly when needed.",
    "a2": "As with most enterprise applications, the cost is high. A variable cost model should be introduced to allow users to pick functionality they require in order to scale the solution to their budget.",
    "a3": "Enhance reporting capabilities",
    "a4": "Raiser's Edge",
    "a5": 7,
    "a6": 3,
    "a7": 9,
    "a8": 7,
    "a9": 4,
    "a10": 8,
    "a11": 7,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11624118148,
    "first": "JonTest",
    "last": "BiedermannTest",
    "org": "TBG",
    "email": "jon@biedermanngroup.com",
    "sector": "Animal & Wildlife",
    "users": "1 - Just Me",
    "income": "$750,000 - $1,500,000",
    "software": "Raiser's Edge",
    "rating": 9,
    "a1": "It's really flexible.  I can set up a campaign and raise money on line and have everything coded and reported correctly with a minimum of effort.",
    "a2": "There is a lot of timeouts- I will be working and the software quits on me.  It sometimes isn't smart enough to check for known-duplicates.  My colleagues think it is hard to use.",
    "a3": "Nothing",
    "a4": "Raiser's Edge",
    "a5": 9,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 9,
    "a12": "As Logo.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jon@biedermanngroup.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11652033554,
    "first": "Michael",
    "last": "Schwerin",
    "org": "SPCA of Texas",
    "email": "mschwerin@spca.org",
    "sector": "Animal & Wildlife",
    "users": "10 - 49",
    "income": "$3,000,000 - $10,000,000",
    "software": "Raiser's Edge",
    "rating": 5,
    "a1": "It integrates with our financial software (which is a Blackbaud solution) and it has the ability to be accessed remotely (it is not housed at our office).",
    "a2": "The upgrades happen frequently, and often without warning. It will change the look and reset some of the settings. The web view also runs very slowly.",
    "a3": "It is very frustrating that they are very eager to charge for help with their product. If they truly care about their customers, they would provide more support before presenting a pay wall.",
    "a4": "Raiser's Edge",
    "a5": 2,
    "a6": 2,
    "a7": 9,
    "a8": 4,
    "a9": 7,
    "a10": 1,
    "a11": 8,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689443706,
    "first": "Elise",
    "last": "Roberts",
    "org": "NatureBridge",
    "email": "eroberts@naturebridge.org",
    "sector": "Environmental",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "Raiser's Edge NXT",
    "rating": 9,
    "a1": "I love the online portal instead of software, the user interface (my homepage is really useful), automatic prompts, integration with gmail and ease of use",
    "a2": "This might not be the fault of the CRM, but we have an earned  and contributed revenue model and right now we have two separate systems to track each group. I'd also like an integration with my google calendar so I can get prompts for action there as well. .",
    "a3": "I'd like suggested workflows for different types of donors (new donors, monthly donors, etc) that are built into the program and are editable.",
    "a4": "Raiser's Edge NXT",
    "a5": 9,
    "a6": 5,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 10,
    "a12": "Screen Shot 2020-06-11 at 2.04.49 PM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "eroberts@naturebridge.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661955345,
    "first": "Wendy",
    "last": "Welch",
    "org": "Humane Society of Huron Valley",
    "email": "wendyw@hshv.org",
    "sector": "Animal & Wildlife",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "Raiser's Edge NXT",
    "rating": 5,
    "a1": "robust, made for nonprofits, support is responsive. Mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm",
    "a2": "It’s not easy or cheap to customize, and there are archaic bits to it. Also costs a lot to buy and keep.",
    "a3": "Focus more on improving and updating the software, and less on sales! It needs to integrate better with their other software (e.g., duplicate imports should be easy).",
    "a4": "Raiser's Edge NXT",
    "a5": 5,
    "a6": 2,
    "a7": 6,
    "a8": 5,
    "a9": 2,
    "a10": 8,
    "a11": 3,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662380689,
    "first": "Diane",
    "last": "Riehs",
    "org": "Manos de Cristo",
    "email": "driehs@manosdecristo.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$250,000 - $750,000",
    "software": "Raiser's Edge NXT",
    "rating": 4,
    "a1": "Raiser's Edge NXT is a powerful software. It requires a lot of studying and going to online classes to fully understand how to use it. The ease of analysis - giving trends, donor capacity and updates to addresses/emails.",
    "a2": "Getting help with solutions. When you call customer service, you are on hold for an extremely long period of time. Some of their customer service people would rather send you an article than help you with a personal solution. There seems to be a real disconnect between helping customers through a solution.",
    "a3": "If you sell a nonprofit an updated software, you need to help the organization with the integration. Our integration of NXT software has not been a seamless process. We have received poor customer service.",
    "a4": "Raiser's Edge NXT",
    "a5": 2,
    "a6": 2,
    "a7": 6,
    "a8": 8,
    "a9": 2,
    "a10": 2,
    "a11": 5,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11691203844,
    "first": "Kristie",
    "last": "Nix Moorer",
    "org": "Greater Birmingham Humane Society",
    "email": "Knix@gbhs.org",
    "sector": "Animal & Wildlife",
    "users": "2 - 4",
    "income": "$3,000,000 - $10,000,000",
    "software": "Raiser's Edge NXT",
    "rating": 7,
    "a1": "Ability to keep hundreds of thousands of records and pull data out as needed if it was put in correctly.  Recently we have had two excellent customer service experiences after a long dry period of not much help",
    "a2": "Database view is antiquated; as the DOD I prefer web view but you can’t do everything in NXT (like add records),  also the search feature is slow and not accurate",
    "a3": "Make searching for a constituent easier and more precise; update database view to look more modern and be more user friendly;  improve export feature from database view so you can export directly to excel and not have to save to your computer first",
    "a4": "Raiser's Edge NXT",
    "a5": 7,
    "a6": 5,
    "a7": 8,
    "a8": 8,
    "a9": 8,
    "a10": 6,
    "a11": 7,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11636629031,
    "first": "Stacy",
    "last": "Roth",
    "org": "Share Our Strength",
    "email": "sroth@strength.org",
    "sector": "Human Services",
    "users": "50+ Users",
    "income": "$50,000,000 - $250,000,000",
    "software": "Raiser's Edge NXT",
    "rating": 7,
    "a1": "Simple for users to use, can do a lot of things, has a lot of deep functions, the detailed integrations it does have are good",
    "a2": "Hard to integrate third party data, it takes a lot of manual manipulation. Harder for users to pull reports and understand what they're looking at.",
    "a3": "Continue to focus on better and easier integrations",
    "a4": "Raiser's Edge NXT",
    "a5": 8,
    "a6": 4,
    "a7": 8,
    "a8": 6,
    "a9": 7,
    "a10": 8,
    "a11": 8,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11630187185,
    "first": "Mary",
    "last": "Callan",
    "org": "Shrine of Our Lady of Guadalupe",
    "email": "Mcallan@guadalupeshrine.org",
    "sector": "Faith Based",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "Raiser's Edge NXT",
    "rating": 4,
    "a1": "Very powerful capabilities ; if you buy all the add ones, it does integrate with many other systems. Once you learn it, it will help you cultivate and steward donors well.",
    "a2": "Very complex, requires a lot of training, it’s extremely expensive, not intuitive, and customer support is terrible",
    "a3": "Customer support and training during on boarding! Needs vast improvement.",
    "a4": "Raiser's Edge NXT",
    "a5": 0,
    "a6": 3,
    "a7": 8,
    "a8": 7,
    "a9": 0,
    "a10": 1,
    "a11": 3,
    "a12": "233A42D0-7C02-4FE3-9BB3-90C72415A1EA.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "Mcallan@guadalupeshrine.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11640785188,
    "first": "Andrew",
    "last": "Fussner",
    "org": "American Heart Association",
    "email": "andrew.fussner@heart.org",
    "sector": "Medical & Wellness",
    "users": "50+ Users",
    "income": "$250,000,000+",
    "software": "Raiser's Edge NXT",
    "rating": 8,
    "a1": "Ability to query data.  We previously used Siebel and it was nearly impossible to query on more than one field at a time.",
    "a2": "From what I understand we have not adopted this software organization wide because the individual license costs would be too much to be affordable.",
    "a3": "Not much - it works pretty well for my purposes.",
    "a4": "Raiser's Edge NXT",
    "a5": 7,
    "a6": 5,
    "a7": 8,
    "a8": 9,
    "a9": 8,
    "a10": 7,
    "a11": 8,
    "a12": "BBCRM - Screen Shot - Fussner.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "andrew.fussner@heart.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11668207592,
    "first": "Seth",
    "last": "Palmer",
    "org": "NC REALTORS",
    "email": "spalmer@ncrealtors.org",
    "sector": "Associations & Memberships",
    "users": "1 - Just Me",
    "income": "$1,500,00 - $3,000,000",
    "software": "RAMCO",
    "rating": 7,
    "a1": "Integration with other systems that we use across the organization. Able to compare input across other inputs without having to export data",
    "a2": "Difficulty to use. Requires a significant amount of training to effectively utilize all functions and integrations.",
    "a3": "Make it easier to operate",
    "a4": "RAMCO",
    "a5": 6,
    "a6": 6,
    "a7": 6,
    "a8": 6,
    "a9": 7,
    "a10": 7,
    "a11": 6,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661764359,
    "first": "ROBERT",
    "last": "ADDLEMAN",
    "org": "Northmont United Presbyterian Church",
    "email": "bob@northmontchurch.org",
    "sector": "Faith Based",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "REALM by ACS",
    "rating": 9,
    "a1": "The software solution is Web/Cloud-based, which makes remote work much easier. Especially with the COVID-19 pandemic, this has been a godsend.",
    "a2": "This software solution is considerably less customizable than its non cloud-based predecessor. It is very frustrating to \"upgrade\" to a newer product, only to lose functionality in so doing.",
    "a3": "Restore all functionality of ACS Financial Suite",
    "a4": "REALM by ACS",
    "a5": 10,
    "a6": 8,
    "a7": 6,
    "a8": 8,
    "a9": 10,
    "a10": 10,
    "a11": 6,
    "a12": "1591188432736151701985.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "bobaddlemanjr@gmail.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11625195430,
    "first": "Julie",
    "last": "Aber",
    "org": "Central Missouri Humane Society",
    "email": "Julie@cmhspets.org",
    "sector": "Animal & Wildlife",
    "users": "10 - 49",
    "income": "$750,000 - $1,500,000",
    "software": "Saelsforce",
    "rating": 9,
    "a1": "The software is easy to use and we can tailor it to our needs. It is cloud based so we can access it anywhere. They have non profit pricing",
    "a2": "It took a while for our Development person to learn build our databases. It would probably would have been easier if we had an IT person.",
    "a3": "Nothing really. We are pretty happy with it",
    "a4": "Saelsforce",
    "a5": 8,
    "a6": 10,
    "a7": 8,
    "a8": 10,
    "a9": 9,
    "a10": 7,
    "a11": 9,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663021611,
    "first": "Michell",
    "last": "Saucedo",
    "org": "Coalition for Humane Immigrant Rights",
    "email": "masanch88@gmail.com",
    "sector": "Other (please specify)",
    "users": "10 - 49",
    "income": "$10,000,000 - $50,000,000",
    "software": "SalesForce",
    "rating": 8,
    "a1": "Options to customize and add apps to support work. It can help serve the purpose of time management, email communications, engagement points.",
    "a2": "It serves too many purpose and can hinder fundraising tracking. Sometimes things too customize that cause problems to the whole system.",
    "a3": "Continue to improve the Nonprofit Success Pack.",
    "a4": "SalesForce",
    "a5": 6,
    "a6": 5,
    "a7": 10,
    "a8": 7,
    "a9": 8,
    "a10": 10,
    "a11": 9,
    "a12": "SF Log In Picture.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "masanch88@gmail.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663849773,
    "first": "Ellen",
    "last": "Janowski",
    "org": "Chicago Council on Global Affairs",
    "email": "ejanowski@thechicagocouncil.org",
    "sector": "Other (please specify)",
    "users": "50+ Users",
    "income": "$10,000,000 - $50,000,000",
    "software": "SalesForce",
    "rating": 10,
    "a1": "I enjoy using products in the Salesforce ecosystem because of the overall ease of using it, the robust and open-source training model, and the large ecosystem of other Salesforce users in the nonprofit and for profit space--you can learn from both.",
    "a2": "Like any software, Salesforce has some fall backs and can some parts of its product are cumbersome to use. The Einstein Analytics platform can be a bit confusing and I think its because they built it piecemeal, especially with its integration with the Pardot email marketing solution.",
    "a3": "To better integrate Salesforce with its Pardot email marketing system and expose more data for customer to use on a mass scale (as opposed to individual-by-individual).",
    "a4": "SalesForce",
    "a5": 10,
    "a6": 9,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Screen Shot 2020-06-03 at 4.34.17 PM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "ejanowski@thechicagocouncil.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11658898022,
    "first": "Jessica",
    "last": "Seitz",
    "org": "Alpha Kappa Psi",
    "email": "jessica@akpsi.org",
    "sector": "Other (please specify)",
    "users": "10 - 49",
    "income": "$50,000,000 - $250,000,000",
    "software": "SalesForce",
    "rating": 7,
    "a1": "I appreciate that we are able to have one database of record and do not need to run a separate database.",
    "a2": "There is no single sign on for a user to make a donation in Classy as it is a separate system from our Salesforce-based community.",
    "a3": "Improvements are needed in Salesforce NPSP so that it is more than just a shifting of functions of sales-based products to donation/foundation-based work.",
    "a4": "SalesForce",
    "a5": 7,
    "a6": 5,
    "a7": 8,
    "a8": 6,
    "a9": 7,
    "a10": 6,
    "a11": 7,
    "a12": "Software.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jessica@akpsi.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11692250537,
    "first": "Ros",
    "last": "Miller",
    "org": "Prelude to a Cure",
    "email": "ros.miller@jilliansdream.org",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "SalesForce",
    "rating": 10,
    "a1": "It seems pretty easy to sue and it tracks all donors. Other users can update and input information and as a team we can talk with each other",
    "a2": "Getting started takes a little time  and time to learn the terminology. The Training pods can be a little confusing.",
    "a3": "Provide a training session when new licenses are given to set up the platform in easy steps",
    "a4": "SalesForce",
    "a5": 8,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 8,
    "a10": 10,
    "a11": 9,
    "a12": "screenshot NPSP.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "ros.miller@jilliansdream.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689384229,
    "first": "Catherine",
    "last": "Brandli",
    "org": "Girl Rising",
    "email": "catherine@girlrising.org",
    "sector": "International",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "SalesForce",
    "rating": 8,
    "a1": "Reporting is much easier than with other platforms; budget-friendly; consistent improvements and lots of plugin options",
    "a2": "Often too much customization is needed as too many options almost to set up--at this makes it hard sometimes to navigate;  Not a user-friendly layout to quickly look at a record and get the key info you need.",
    "a3": "While the Nonprofit Success pack has made improvements, it still could be better geared, even in terminology used, for nonprofit use. Every nonprofit I know has to spend significant $ upfront to have someone help customize (often with standard features) to get it operational. Most donor databases have donor info organized better that you don't have to scroll and scroll or drill down into layers.",
    "a4": "SalesForce",
    "a5": 6,
    "a6": 9,
    "a7": 8,
    "a8": 6,
    "a9": 9,
    "a10": 6,
    "a11": 7,
    "a12": "Screen Shot 2020-06-11 at 4.47.52 PM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "luccat@hotmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689348444,
    "first": "Lauren",
    "last": "Humphrey",
    "org": "826LA",
    "email": "lauren@826la.org",
    "sector": "Education",
    "users": "10 - 49",
    "income": "$1,500,00 - $3,000,000",
    "software": "SalesForce",
    "rating": 6,
    "a1": "Lots of information and data; I like the dashboard options though admittedly I don't use them. It's easy-ish on the eyes, generally.",
    "a2": "Reports can be overly complicated, though I can see how having those options is important. There are often many steps to get to a single piece of information.",
    "a3": "Not sure--we have a network-wide Salesforce admin who manages the backend stuff, so I don't know what is limited to her and what is limited to Salesforce.",
    "a4": "SalesForce",
    "a5": 4,
    "a6": 5,
    "a7": 8,
    "a8": 7,
    "a9": 4,
    "a10": 5,
    "a11": 6,
    "a12": "Screen Shot 2020-06-11 at 1.33.15 PM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "laurenhumphrey@gmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11676949082,
    "first": "Casey",
    "last": "Darnley",
    "org": "Schuylkill Center for Environmental Education",
    "email": "casey@schuylkillcenter.org",
    "sector": "Animal & Wildlife",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "SalesForce",
    "rating": 7,
    "a1": "The automation and integration capabilities are nearly unlimited and constant updates keep adding more.",
    "a2": "There is so much customization involved that it can get overwhelming.  It's not setup exactly for our needs so we are constantly adjusting to try and make it work for us.",
    "a3": "More out of box templates for nonprofits",
    "a4": "SalesForce",
    "a5": 3,
    "a6": 10,
    "a7": 9,
    "a8": 6,
    "a9": 7,
    "a10": 4,
    "a11": 7,
    "a12": "Salesforce Screenshot.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "casey@schuylkillcenter.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11664566271,
    "first": "Jeff",
    "last": "Robertson",
    "org": "Agape Asia Foundation",
    "email": "jeff@agapeasia.org",
    "sector": "International",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "SalesForce",
    "rating": 7,
    "a1": "Integrates with some other apps, easily adapted to our needs, new Lightning experience is easy to use and has sped up our processing time",
    "a2": "Complicated to set up some applications that we regularly use, some of the integrations take skilled software professional which are hard to find",
    "a3": "More integration with other applications",
    "a4": "SalesForce",
    "a5": 4,
    "a6": 9,
    "a7": 5,
    "a8": 9,
    "a9": 5,
    "a10": 7,
    "a11": 8,
    "a12": "349FB5F3-36C4-45A6-9C51-593582644893.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jeff@agapeasia.org",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11664062377,
    "first": "Sean",
    "last": "Hale",
    "org": "Sean Hale Consulting",
    "email": "sean@seanhale.org",
    "sector": "Other (please specify)",
    "users": "1 - Just Me",
    "income": "$750,000 - $1,500,000",
    "software": "SalesForce",
    "rating": 8,
    "a1": "Manages complexity, great dashboards, and great for multiple revenue sources.  When its running well, its an awesome tool!",
    "a2": "Configuration and administration need strong support (paid 3rd party).  That can be difficult for small NPOs.  Many get Salesforce because its free and only learn later that its too much firepower for them to handle.",
    "a3": "Continue creating support for nonprofits, especially lower end of the market.",
    "a4": "SalesForce",
    "a5": 6,
    "a6": 6,
    "a7": 10,
    "a8": 9,
    "a9": 7,
    "a10": 7,
    "a11": 8,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663807396,
    "first": "William",
    "last": "Mustard",
    "org": "Messianic Jewish Alliance of America",
    "email": "bmustard@mjaa.org",
    "sector": "Faith Based",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "SalesForce",
    "rating": 6,
    "a1": "Lots and lots of donor info available on the app.  Great reporting capabilities.  Pretty easy to use and intuitive.",
    "a2": "Little training and really geared for Business.  So much of SalesForce is really set up for business.  Especially the parts about closing sales etc.  This really isn't the way it is done in fundraising.",
    "a3": "Quit treating fundraising like sales.",
    "a4": "SalesForce",
    "a5": 7,
    "a6": 4,
    "a7": 5,
    "a8": 8,
    "a9": 5,
    "a10": 5,
    "a11": 5,
    "a12": "2018-03-15 (4).png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "bmustard@mjaa.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662665881,
    "first": "Caitlin",
    "last": "Gompf",
    "org": "Choral Arts Society of Washington",
    "email": "cgompf@choralarts.org",
    "sector": "Arts & Culture",
    "users": "5 - 10",
    "income": "$250,000,000+",
    "software": "SalesForce",
    "rating": 9,
    "a1": "User friendly, easy to learn about the relationship over time with a person within their contact record.",
    "a2": "Reporting is difficult to navigate for a newcomer, but I confess there are lots of tutorials to help use them!",
    "a3": "I don't have specific notes for improvement.",
    "a4": "SalesForce",
    "a5": 7,
    "a6": 7,
    "a7": 9,
    "a8": 7,
    "a9": 9,
    "a10": 9,
    "a11": 9,
    "a12": "Capture.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "cgompf92@gmail.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662356026,
    "first": "Libia",
    "last": "Bianibi",
    "org": "Arts Alliance Illinois",
    "email": "bianibi@artsalliance.org",
    "sector": "Arts & Culture",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "SalesForce",
    "rating": 6,
    "a1": "It's very flexible, it has a variety of roles for all kinds of users, it brings together finance with the donor database and allows various kinds of reporting",
    "a2": "It's difficult to configure, has steep learning curve, too many resources and it also not easy to find out how to fix things that change with every single updates. Our point of contact doesn't loop us in in how something can affect us until we find out too late.",
    "a3": "They have to make specific trainings for nonprofits that cover basic needs, and a different one for more advanced uses.",
    "a4": "SalesForce",
    "a5": 6,
    "a6": 4,
    "a7": 10,
    "a8": 8,
    "a9": 8,
    "a10": 0,
    "a11": 6,
    "a12": "Screen Shot 2020-06-03 at 9.52.35 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "bianibi@gmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662305806,
    "first": "Megan",
    "last": "Owens",
    "org": "Transportation Riders United",
    "email": "mowens@detroittransit.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "SalesForce",
    "rating": 6,
    "a1": "It's free for small nonprofits (although getting it set up and data transferred over took consultant help). Plus its a broadly known platform.",
    "a2": "It's got way more components and features than we need and some of the terminology is confusing, especially when training new people on it",
    "a3": "Create a simplified version for smaller nonprofits",
    "a4": "SalesForce",
    "a5": 3,
    "a6": 7,
    "a7": 9,
    "a8": 6,
    "a9": 6,
    "a10": 3,
    "a11": 5,
    "a12": "CRM proof.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "mowens00@gmail.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662145568,
    "first": "John",
    "last": "Cotman",
    "org": "Ohio Aerospace Institute",
    "email": "johncotman@oai.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "SalesForce",
    "rating": 8,
    "a1": "History kept and tracking how donor contacted.  Can be tied into our accounting system to capture cost of acquisition and ongoing efforts.",
    "a2": "No canned views/reports.  Easier combining of donors for stratification or regional analysis.  Ability to streamline data input and comments.",
    "a3": "Easier ad-hoc reporting",
    "a4": "SalesForce",
    "a5": 5,
    "a6": 7,
    "a7": 7,
    "a8": 8,
    "a9": 10,
    "a10": 8,
    "a11": 7,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662110079,
    "first": "Paula",
    "last": "Paris",
    "org": "JFYNetWorks",
    "email": "pparis@jfynet.org",
    "sector": "Education",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "SalesForce",
    "rating": 5,
    "a1": "I don't necessarily love it, but it is what we have and it is low cost. It has a NonProfit Service Pack that is available free, and there are are several local organizations that offer training at minimal cost.",
    "a2": "It is difficult to maintain in a small shop without a dedicated person and without an IT person. Customer service is terrible, and seems to rely on a  level of technical expertise that a small nonprofit may not have.",
    "a3": "I don't really find fault with the vendor, per se. But it would be nice if there were a version of the  NonProfit pack that was more user friendly for non-techies.",
    "a4": "SalesForce",
    "a5": 5,
    "a6": 7,
    "a7": 7,
    "a8": 6,
    "a9": 5,
    "a10": 0,
    "a11": 5,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662058440,
    "first": "David",
    "last": "McCurry",
    "org": "AMG International",
    "email": "davidm@amginternational.org",
    "sector": "Faith Based",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "SalesForce",
    "rating": 5,
    "a1": "Donor records are easy to navigate. Gift history is assessable. Easy to add PDFs and contact reports.",
    "a2": "Preparing reports can be challenging. There are too many similar filters. Numbers don’t always correlate.",
    "a3": "Make report writing and the creation of mailing lists more user friendly",
    "a4": "SalesForce",
    "a5": 4,
    "a6": 6,
    "a7": 4,
    "a8": 3,
    "a9": 6,
    "a10": 5,
    "a11": 5,
    "a12": "BDC5DE9B-EAA5-41A1-A810-679A75F8438E.jpeg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "tubbymac@yahoo.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662043986,
    "first": "Terri",
    "last": "Bruce",
    "org": "NAMA Foundation",
    "email": "tbruce@namanow.org",
    "sector": "Associations & Memberships",
    "users": "10 - 49",
    "income": "$250,000 - $750,000",
    "software": "SalesForce",
    "rating": 5,
    "a1": "The softward is functional.  There are many companies avaialble to hire for assistance and training.  Appreciate that it is web/cloud based for usage anywhere.",
    "a2": "It is difficult to navagate.  I have challanges regularly.  In order for it to do what we needed it to do, we had to also get iATS and Apsona which is costly and even more difficult to navagate.",
    "a3": "Make it easier.  More user friendly  Built for sales but used in fundraising  Appreciate the \"free\" version for non-profits but the extras (iATS and Apsona) to make it functional are costly.",
    "a4": "SalesForce",
    "a5": 4,
    "a6": 8,
    "a7": 3,
    "a8": 4,
    "a9": 6,
    "a10": 3,
    "a11": 4,
    "a12": "Doc1.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "tbruce@namanow.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662029926,
    "first": "Rob",
    "last": "Ruzanic",
    "org": "UrbanPromise",
    "email": "rob.ruzanic@Urbanpromise.org",
    "sector": "Education",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "SalesForce",
    "rating": 8,
    "a1": "Easy view existing reports and to add data, visually it is very easy to see and understand Dashboards as well as access pre-made reports. Easy to find donor information and look at donor history across time.",
    "a2": "HARD to customize and create new reports. Hard to build new reports hard to edit and update existing reports so thaty they simply capture the same data year over year and in current fiscal years without having to build new reports from scratch.  Also problems integrating with our online giving platform, data consistences are a problems. Imported data is often hard/impossible to edit even if it is incorrect.",
    "a3": "They need much better staff training tools and customized solutions that help non=profits manage data of donors across many variables as well as link those donors to volunteerism, etc.",
    "a4": "SalesForce",
    "a5": 3,
    "a6": 9,
    "a7": 8,
    "a8": 9,
    "a9": 9,
    "a10": 8,
    "a11": 9,
    "a12": "Screen Shot 2020-06-03 at 9.30.41 AM.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "rob.ruzanic@urbanpromise.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661979495,
    "first": "Martyns",
    "last": "Kanu",
    "org": "San Mateo County",
    "email": "Kanu@smccd.edu",
    "sector": "Education",
    "users": "50+ Users",
    "income": "$250,000,000+",
    "software": "SalesForce",
    "rating": 9,
    "a1": "It's easy of use and implementations, we can have a new member now and he or she will be up and runing with the software. Team members are imdiately empowered with tools that improve productivity and free up time. A new way to CRM that helps teams unlock productivity and grow sales. Fast to Setup.",
    "a2": "NONE for now!we can have a new member now and he or she will be up and runing with the software. Team members are imdiately empowered with tools that improve productivity and free up time. A new way to CRM that helps teams unlock productivity and grow sales. Fast to Setup.",
    "a3": "price",
    "a4": "SalesForce",
    "a5": 10,
    "a6": 10,
    "a7": 9,
    "a8": 9,
    "a9": 7,
    "a10": 9,
    "a11": 8,
    "a12": "Salesforc screen short.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "kanu@smccd.edu",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661966577,
    "first": "Carole",
    "last": "Krechman",
    "org": "The Peacemaker Corps association",
    "email": "Carole@peacemakercorps.org",
    "sector": "International",
    "users": "5 - 10",
    "income": "$0 - $100,000",
    "software": "SalesForce",
    "rating": 7,
    "a1": "keeps track of submissions for our film festival and we received the free licenses as a donation. We are able to trace submission to travel and accommodations for each winner until they are at the ceremony and then home",
    "a2": "training personnel to use the system so that we can get the full benefit of the software.  It is hard to get help when we need it.  When new volunteers join us the training take quite a bit of time.",
    "a3": "More training for my staff and help when we need it",
    "a4": "SalesForce",
    "a5": 6,
    "a6": 8,
    "a7": 7,
    "a8": 8,
    "a9": 6,
    "a10": 7,
    "a11": 6,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661898956,
    "first": "Laura",
    "last": "Powers",
    "org": "Philadelphia Bar Foundation",
    "email": "Lpowers@philabarfoundation.org",
    "sector": "Other (please specify)",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "SalesForce",
    "rating": 7,
    "a1": "Clean interface and very flexible platform - ability to do most of what we need if we set it up correctly",
    "a2": "Built to serve for-profits so it is overly complex - needs to have a very experienced internal administrator",
    "a3": "Software is overly complicated, needs simplification",
    "a4": "SalesForce",
    "a5": 5,
    "a6": 5,
    "a7": 8,
    "a8": 10,
    "a9": 5,
    "a10": 2,
    "a11": 8,
    "a12": "A6E7C6EB-E66A-4357-A91A-98D80070E230.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "Lpowers@philabarfoundation.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661862090,
    "first": "Wendy",
    "last": "Weinstein",
    "org": "Clay Art Center",
    "email": "wendy@clayartcenter.org",
    "sector": "Arts & Culture",
    "users": "5 - 10",
    "income": "$250,000 - $750,000",
    "software": "SalesForce",
    "rating": 4,
    "a1": "centralization of information and the fact that there are so many third party apps which can be embedded into the various screens",
    "a2": "challenges of generating reports without having to hire a third party; everything is an upcharge; reporting and resolving issues are not straight forward; basic non-profit pack is almost too simplistic",
    "a3": "customer service - call center incredibly difficult to work with and frequently do not actually listen to (1) my problem, and (2) steps I have already taken to address it",
    "a4": "SalesForce",
    "a5": 1,
    "a6": 5,
    "a7": 5,
    "a8": 6,
    "a9": 3,
    "a10": 1,
    "a11": 5,
    "a12": "Salesforce proof of usage.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "wendy@clayartcenter.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661833867,
    "first": "Jonathan",
    "last": "Green",
    "org": "Yale-China Association",
    "email": "jonathan.green@yale.edu",
    "sector": "Other (please specify)",
    "users": "10 - 49",
    "income": "$250,000 - $750,000",
    "software": "SalesForce",
    "rating": 6,
    "a1": "We use the online platform.  We like the fact we can access it online from where ever we are to quickly look up information or to add to the database.",
    "a2": "Pulling reports from the platform is a challenge.  It takes the team a lot of time to pull reports.  Given this challenge, we've dedicated one person to be the report expert.",
    "a3": "improve the functionality without increasing the cost to much to do so",
    "a4": "SalesForce",
    "a5": 4,
    "a6": 3,
    "a7": 6,
    "a8": 3,
    "a9": 8,
    "a10": 8,
    "a11": 6,
    "a12": "Salesforce invoice proof.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jonathan.green@yale.edu",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661773271,
    "first": "Sharad",
    "last": "Aggarwal",
    "org": "BRAC USA",
    "email": "sharad@bracusa.org",
    "sector": "International",
    "users": "10 - 49",
    "income": "$10,000,000 - $50,000,000",
    "software": "SalesForce",
    "rating": 7,
    "a1": "Many other organizations use Salesforce and it's a common platform so support is readily available. There are also 3rd party consultants available to support customization. Working with a large company, they offer many other services, but they may not be as useful to us.",
    "a2": "Salesforce demand a lot of customization and hiring a 3rd party to do it.   Salesforce demand  customization and hiring a 3rd party to do it, which can be expensive. They offer Non-profit discounts but are still very expensive. The previous GUI was very dated and hard to manipulate.",
    "a3": "Lower costs for non-profits.",
    "a4": "SalesForce",
    "a5": 6,
    "a6": 4,
    "a7": 8,
    "a8": 7,
    "a9": 9,
    "a10": 6,
    "a11": 7,
    "a12": "SF SA.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "sharad@bracusa.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11661745325,
    "first": "Jeanette",
    "last": "Hamel",
    "org": "Crossing National Inc",
    "email": "jhamel@crossingcec.com",
    "sector": "Education",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "SalesForce",
    "rating": 7,
    "a1": "Organization of donor information...everything in one spot and ease of access. Creates a seamless process",
    "a2": "Lack of overview in the setup - could have used some more oversight to make sure that data entry was standardized",
    "a3": "Support and guidance on how to manage the system",
    "a4": "SalesForce",
    "a5": 8,
    "a6": 6,
    "a7": 7,
    "a8": 7,
    "a9": 8,
    "a10": 3,
    "a11": 7,
    "a12": "Screen Shot 2020-06-03 at 8.12.56 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jhamel@crossingcec.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11658927767,
    "first": "Colleen",
    "last": "Shaddox",
    "org": "Quicksilver Communication",
    "email": "colleen@qsilver.com",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "SalesForce",
    "rating": 1,
    "a1": "It is not a solution and I do not love it. It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.It is not a solution and I do not love it.",
    "a2": "Salesforce contracts with the Dept. of Homeland Security. So it's evil in addition to being the least user friendly system imaginable.",
    "a3": "The training modules are ridiculous. Badges! Talk to me like a grown up.",
    "a4": "SalesForce",
    "a5": 0,
    "a6": 5,
    "a7": 0,
    "a8": 0,
    "a9": 0,
    "a10": 0,
    "a11": 0,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628855105,
    "first": "Theresaq",
    "last": "Nelson",
    "org": "Theresa Nelson & Associates",
    "email": "theresa@theresanelson.com",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$0 - $100,000",
    "software": "Salesforce",
    "rating": 7,
    "a1": "Salesforce is intuitive and fairly easy to use, like a Mac vs. a PC, layout flows well, simple reports are easy to do",
    "a2": "Finding a consultant to customize it who understands fundraising, unerstanding how notes should best be placed as there are three different locations, some of which are reportable and some of which are not",
    "a3": "Build in more pre-set reports - talk to us users about what are the most common reports we need and have those ready to go",
    "a4": "Salesforce",
    "a5": 7,
    "a6": 7,
    "a7": 7,
    "a8": 7,
    "a9": 8,
    "a10": 6,
    "a11": 7,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11624535517,
    "first": "Mai",
    "last": "Moua",
    "org": "Hmong American Partnership",
    "email": "maim@hmong.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$10,000,000 - $50,000,000",
    "software": "Salesforce",
    "rating": 7,
    "a1": "Open source, integrates with many other software. There are modules online that you can do self-paced learning. A great learning community.",
    "a2": "Not as intuitive to understand and use. You need to have a salesforce administrator. You will need training to use the software.",
    "a3": "We're still learning about the software and its usage.",
    "a4": "Salesforce",
    "a5": 5,
    "a6": 5,
    "a7": 8,
    "a8": 6,
    "a9": 9,
    "a10": 7,
    "a11": 7,
    "a12": "Salesforce Software.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "maim@hmong.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628369722,
    "first": "Jenny",
    "last": "Williamson",
    "org": "C Three Foundation",
    "email": "jenny.williamson@cthreefoundation.org",
    "sector": "Medical & Wellness",
    "users": "1 - Just Me",
    "income": "$0 - $100,000",
    "software": "SalesForce",
    "rating": 7,
    "a1": "We opted to go with SalesForce because of the free nonprofit solutions that they offered. This saved us about $225 a month, which is significant for our limited budget.",
    "a2": "The functionality is so complex that it's a lot for one person to handle. Menus don't always correspond to what I think they will and integrations are a bit difficult to learn. However, much of that is on me due to time constraints and being unable to spend a significant amount of time in the resouce learning area.",
    "a3": "We've only just begun to use SalesForce, and the training available is incredible, so we haven't really used it long enough to figure out what improvements would help. The paid assistance was far beyond our budget.",
    "a4": "SalesForce",
    "a5": 5,
    "a6": 10,
    "a7": 10,
    "a8": 5,
    "a9": 5,
    "a10": 5,
    "a11": 9,
    "a12": "Screenshot_2020-05-22 Home Salesforce.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jenny.williamson@cthreefoundation.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628706115,
    "first": "Terri",
    "last": "Forman",
    "org": "First Graduate",
    "email": "tforman@firstgraduate.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "Salesforce",
    "rating": 3,
    "a1": "Not much; the best Salesforce feature is that we can upload related documents to a donor. Other than that, it is complicated and we have so many workarounds that it is frustrating.",
    "a2": "Salesforce is not intuitive; not built for Development. The language it uses does not correlate, the training videos are too long and not helpful, no one is available for help unless you pay for an upgrade.",
    "a3": "bring Development pros together and ask them what they need and what things should be called to reflect our work.",
    "a4": "Salesforce",
    "a5": 3,
    "a6": 5,
    "a7": 2,
    "a8": 4,
    "a9": 4,
    "a10": 0,
    "a11": 1,
    "a12": "Screen Shot 2020-05-22 at 1.13.22 PM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "tforman@firstgraduate.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11624579808,
    "first": "Laurie",
    "last": "Kennedy",
    "org": "KIPP",
    "email": "lkennedy@kippma.org",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "SalesForce",
    "rating": 7,
    "a1": "It is easy to access and move through donor information. You can access anywhere you have internet access, ever on your phone. Mobile app is grear.",
    "a2": "Reporting is tricky and I find setting up the dashboards very difficult. It should be simple for all levels of user competencies.",
    "a3": "Creating reports and dashboards should be more user friendly",
    "a4": "SalesForce",
    "a5": 6,
    "a6": 8,
    "a7": 8,
    "a8": 6,
    "a9": 8,
    "a10": 3,
    "a11": 7,
    "a12": "IMG_2002.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "lkennedy@kippma.org",
    "a16": "No, make my organization's name anonymous."
  },

  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11625559486,
    "first": "Julie",
    "last": "Kerr",
    "org": "Southern Tier AIDS Program, Inc.",
    "email": "jkerr@stapinc.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$3,000,000 - $10,000,000",
    "software": "Salsa CRM",
    "rating": 7,
    "a1": "The software has an easy to use interface.  It works well for multiple fundraising events. I like that it is cloud based as well",
    "a2": "The software is fairly expensive and the way the fees are charged is fairly complicated. Also they are a little hard to reconcile to actual charges from the bank statement.",
    "a3": "I would like to see a lower cost option for smaller organizations",
    "a4": "Salsa CRM",
    "a5": 7,
    "a6": 6,
    "a7": 7,
    "a8": 7,
    "a9": 7,
    "a10": 5,
    "a11": 7,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628377537,
    "first": "Karen",
    "last": "Shannon",
    "org": "Population Research Institute",
    "email": "karen@pop.org",
    "sector": "Other (please specify)",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "Salsa CRM",
    "rating": 10,
    "a1": "User friendly, Greatly simplifies development work, increases our efficiency, great customer service",
    "a2": "There is not one single thing that I can think of to dislike about this CRM. Really! We're really happy with it!",
    "a3": "Salsa continues to upgrade, and would like to see increased improvements with Performance dashboard and its reports.",
    "a4": "Salsa CRM",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "screen shot Salsa.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "karen@pop.org",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11662260764,
    "first": "Dave",
    "last": "Tinker",
    "org": "Achieva",
    "email": "Dtinker@achieva.info",
    "sector": "Human Services",
    "users": "50+ Users",
    "income": "$1,500,00 - $3,000,000",
    "software": "Salsa CRM",
    "rating": 9,
    "a1": "Tech support is quick and does not talk above your head.  Also the software is easy to use and does not have a steep learning curve",
    "a2": "Doesn’t talk with my fund accounting software - we have spent a bit of time trying to coordinate the data between the two software products",
    "a3": "See #10",
    "a4": "Salsa CRM",
    "a5": 9,
    "a6": 9,
    "a7": 8,
    "a8": 8,
    "a9": 8,
    "a10": 10,
    "a11": 9,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11689224885,
    "first": "Jordan",
    "last": "Bles",
    "org": "Iowa Environmental Council",
    "email": "bles@iaenvironment.org",
    "sector": "Environmental",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "Salsa CRM",
    "rating": 7,
    "a1": "It interfaces easily with its Engage email platform, and has a useful membership module. Once you learn how to talk to it, it can do all the basics of a database well.",
    "a2": "It is not very intuitive, doesn't have a robust search option, and isn't set up for businesses and organizations.",
    "a3": "If you could improve the features of the CRM to be more like the Engage email and fundraising platform, that would be amazing.",
    "a4": "Salsa CRM",
    "a5": 4,
    "a6": 7,
    "a7": 7,
    "a8": 7,
    "a9": 6,
    "a10": 7,
    "a11": 5,
    "a12": "Salsa screenshot.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "jblev17@gmail.com",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11667608022,
    "first": "Shana",
    "last": "Santow",
    "org": "Southside Community Land Trust",
    "email": "Shana@southsideclt.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "Salsa CRM",
    "rating": 0,
    "a1": "We like the ability to store donor data and create groups that enable us to sift through and categorize accordingly.",
    "a2": "Producing reports to serve our needs, particularly for mailings, can be challenging and produces a considerable amount of extra time and energy parsing through too much of the data we do not need.",
    "a3": "Create robust reporting tools that make it easier and less time consuming to generate spreadsheets for mailings and campaigns.",
    "a4": "Salsa CRM",
    "a5": "",
    "a6": "",
    "a7": "",
    "a8": "",
    "a9": "",
    "a10": "",
    "a11": "",
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11629332079,
    "first": "August",
    "last": "Carlino",
    "org": "Rivers of Steel Heritage Corporation",
    "email": "arcarlino@riversofsteel.com",
    "sector": "Arts & Culture",
    "users": "5 - 10",
    "income": "$100,000 - $250,000",
    "software": "Salsa CRM",
    "rating": 9,
    "a1": "It’s cross-platform integration tracks ticket sales, donors, and other patrons. Now I’m just typing to meet the character requirements.",
    "a2": "It is a pricey software compared with others. I can’t say any more so I’m just typing to meet the character requirements",
    "a3": "Nothing yet. We just started using it so we’re still in the discovery phase.",
    "a4": "Salsa CRM",
    "a5": 5,
    "a6": 8,
    "a7": 10,
    "a8": 10,
    "a9": 5,
    "a10": 10,
    "a11": 9,
    "a12": "A527FC15-F8EE-4E3E-9675-F7DF479421DB.jpeg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "arcarlino@riversofsteel.com",
    "a16": "No, make my organization's name anonymous."
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11628365465,
    "first": "Laurie",
    "last": "Taylor-Hamm",
    "org": "California State University, Fresno",
    "email": "ltaylorhamm@mail.fresnostate.edu",
    "sector": "Education",
    "users": "50+ Users",
    "income": "$0 - $100,000",
    "software": "Salsa CRM",
    "rating": 8,
    "a1": "This product is comparable to Sales Force - with lots of good tools - it can get complicated for some.",
    "a2": "Product is costly especially for a grass roots organization -It would be nice if the cost was less  - otherwise, it fits most needs fairly well.",
    "a3": "Get more power to drill down like with Sales Force - try to keep costs down to improve access to small grass roots organizations.",
    "a4": "Salsa CRM",
    "a5": 7,
    "a6": 8,
    "a7": 7,
    "a8": 8,
    "a9": 7,
    "a10": 7,
    "a11": 8,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11624634728,
    "first": "Alex",
    "last": "Nerad",
    "org": "Egyptian Theatre",
    "email": "alex@egyptiantheatre.org",
    "sector": "Arts & Culture",
    "users": "1 - Just Me",
    "income": "$0 - $100,000",
    "software": "Showare Ticketing Platform",
    "rating": 9,
    "a1": "All of our information is in one location, makes it easy to track all the activity of our patrons/donors. The company has wonderful customer service.",
    "a2": "It isn't as robust as a typical CRM, we can't email directly from the platform, we can't track as many details and interactions we have with patrons/donors",
    "a3": "Add more functionality for patron/donor details",
    "a4": "Showare Ticketing Platform",
    "a5": 8,
    "a6": 9,
    "a7": 7,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 9,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11667136592,
    "first": "Michael",
    "last": "Engleson",
    "org": "Wisconsin Association of Lakes",
    "email": "mengleson@wisconsinlakes.org",
    "sector": "Environmental",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "The Databank",
    "rating": 10,
    "a1": "The Databank is tailored to non-profits (not a for-profit CRM modified to NPOs), is affordable, is very responsive to customer service issues",
    "a2": "Not much! The meetings registration system is a bit clunky, especially if you have multiple questions of meeting respondents.",
    "a3": "Improve the ability of the meetings module to handle large meetings with multiple questions of attendees, the ability of the person paying to register multiple individuals and have their names show up on rosters. Or, build connectivity with third party sources like EventBrite. Overall, though, I have very few complaints.",
    "a4": "The Databank",
    "a5": 10,
    "a6": 9,
    "a7": 8,
    "a8": 10,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "10",
    "id": 11663500246,
    "first": "Susan",
    "last": "Albert",
    "org": "House of Neighborly Service",
    "email": "Susan.Albert@hns-tx.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$250,000 - $750,000",
    "software": "Wild Apricot",
    "rating": 3,
    "a1": "Nothing. It is not well designed for fundraising data management. It cannot handle development department needs.",
    "a2": "Everything Cannot do soft credits. Reports are hard to run. does not interface with online giving systems.",
    "a3": "It is mainly a client management software and they added donor management. They should have not bothered. It is not designed to meet fundraising needs.",
    "a4": "Wild Apricot",
    "a5": 4,
    "a6": 2,
    "a7": 0,
    "a8": 4,
    "a9": 1,
    "a10": 8,
    "a11": 0,
    "a12": "",
    "a13": "",
    "a14": "",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12689924248,
    "first": "Michelle",
    "last": "Robinson",
    "org": "Organization wishes to be anonymous.",
    "email": "mrobinson@freedomkentucky.com",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 8,
    "a1": "Customizable classification of data makes it easy to report and search. I have tagged my data in unique ways so that I can select specific details to narrow or broaden a search.",
    "a2": "Screens need to be updated and flow better. There needs to be an improvement in how the interface looks. DonorPerfect has a dated interface and it needs to look more like other packages. Time for an overhaul.",
    "a3": "The functionality is great but the interface needs to be updated to use more drag/drop and list features. For example, you should be able to see a list of all gifts and their detail without leaving the list screen.",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 7,
    "a7": 8,
    "a8": 8,
    "a9": 8,
    "a10": 10,
    "a11": 8,
    "a12": "Screen Shot 2021-05-26 at 9.54.19 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12674192215,
    "first": "Colleen",
    "last": "Leader",
    "org": "Organization wishes to be anonymous.",
    "email": "cleader@gorlinsyndrome.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$250,000,000+",
    "software": "DonorPerfect",
    "rating": 5,
    "a1": "not all that much.  The reports we run are already preset so that's good but other than that - it's not that great of a system",
    "a2": "We are unable to properly refund customers in both donorperfect and the payment gateway, creating custom forms is labor intensive and difficult and it takes forever to get through to customer service - although once we do they are great.",
    "a3": "Payment issues, the ability to contact a real person when you have an issue and better understand the non-profit area to create the ability to add criteria easily.",
    "a4": "DonorPerfect",
    "a5": "5 - Neutral, Neither Agree nor Disagree",
    "a6": "5 - Neutral, Neither Agree nor Disagree",
    "a7": 2,
    "a8": "5 - Neutral, Neither Agree nor Disagree",
    "a9": "5 - Neutral, Neither Agree nor Disagree",
    "a10": 4,
    "a11": 5,
    "a12": "Donorperfect.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12671418977,
    "first": "Lindsey",
    "last": "Martchenko",
    "org": "Organization wishes to be anonymous.",
    "email": "lmartchenko@onecaresupport.ca",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$10,000,000 - $50,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "It is an extremely user-friendly software to use, and support is top notch when I do have questions! The reports that are available are all-encompassing, and allow us to effectively report on a variety of statistics to our board.",
    "a2": "Nothing! There hasn't been anything that I've come across that the software is lacking. Any time I think there's a gap, I contact support and it turns out I just wasn't aware of the feature.",
    "a3": "I would like to see an easier way to indicate that someone doesn't want to receive emails vs paper mail. I would also like to see an easier and cleaner way to track planned gifts (ie bequests).",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 7,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 10,
    "a12": "dp screenshot.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12670678040,
    "first": "Val",
    "last": "Schinkel",
    "org": "Organization wishes to be anonymous.",
    "email": "val.schinkel@cybercsb.Ca",
    "sector": "Faith Based",
    "users": "10 - 49",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 7,
    "a1": "Multiple users can be in DP at the same time, it is a good place for all the donors you deal with, It can handle fundraising events.",
    "a2": "The reports are clunky and can not always get it to work the way I would like it too. Anonymous donors show up with their full name in our donor reports. This is frustrating for our supporters/donors",
    "a3": "That when clicking on anonymous that the name of the donor would not show up in donor reports.",
    "a4": "DonorPerfect",
    "a5": 6,
    "a6": 6,
    "a7": 4,
    "a8": 6,
    "a9": "5 - Neutral, Neither Agree nor Disagree",
    "a10": 9,
    "a11": 7,
    "a12": "Capture.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12670595409,
    "first": "Shira",
    "last": "Spielman",
    "org": "Organization wishes to be anonymous.",
    "email": "sspielman@sulam.org",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "The customer service is absolutely outstanding! Incredibly friendly, helpful and supportive. Also like the search features, reports, and how it is organized.",
    "a2": "I don't like the fact that duplicates get created so easily.  I also wish there was a way to do a mail merge more efficiently.",
    "a3": "Please create a formula to decrease duplicates! It wastes so much time to remove duplicates.  I would also love a way to include pledges in the reports.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 10,
    "a7": 9,
    "a8": 9,
    "a9": 8,
    "a10": 10,
    "a11": 10,
    "a12": "20210519_132526.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12670387513,
    "first": "Elaine",
    "last": "Riba",
    "org": "Organization wishes to be anonymous.",
    "email": "elainer@bocafed.org",
    "sector": "Associations & Memberships",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "It is very user friendly. Easy to access Reports once you know how to use the system. Always updating and improving their system based on user feedback.",
    "a2": "Sometimes it can get a little confusing when trying to create certain reports, but you can call and have someone walk you through it very easily.",
    "a3": "Nothing. It is really the perfect system to keep track of all our donors and the donations that come in.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 10,
    "a7": 9,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 9,
    "a12": "DP screenshot.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12670166228,
    "first": "Katie",
    "last": "Baker",
    "org": "Organization wishes to be anonymous.",
    "email": "kbaker@hollandmuseum.org",
    "sector": "Arts & Culture",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 7,
    "a1": "I find Donor Perfect makes it easy to add donors to the database, add information, and manage interactions.",
    "a2": "I find it is hard to run reports in Donor Perfect and pull the information I need to manage mailings.",
    "a3": "Make it cheaper instead of having a bunch of ad on costs.",
    "a4": "DonorPerfect",
    "a5": 7,
    "a6": "5 - Neutral, Neither Agree nor Disagree",
    "a7": 4,
    "a8": 7,
    "a9": 6,
    "a10": 9,
    "a11": 7,
    "a12": "dp.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12668085630,
    "first": "Sarah",
    "last": "Rood",
    "org": "Organization wishes to be anonymous.",
    "email": "srood@metrodetroitanimals.org",
    "sector": "Animal & Wildlife",
    "users": "5 - 10",
    "income": "$50,000,000 - $250,000,000",
    "software": "DonorPerfect",
    "rating": 7,
    "a1": "It's easy to manage donors, add new donors, gifts and send automated emails. It's also easy to upload multiple contacts with their template.",
    "a2": "It can be hard to figure out what the correct way to tell the software to pull the information you want is.",
    "a3": "More intuitive reporting --> easier to pull reports with certain info showing.",
    "a4": "DonorPerfect",
    "a5": 6,
    "a6": 7,
    "a7": 7,
    "a8": 8,
    "a9": 7,
    "a10": 7,
    "a11": 7,
    "a12": "DP.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12667975495,
    "first": "Nikki",
    "last": "Hilchey",
    "org": "Organization wishes to be anonymous.",
    "email": "nhilchey@girlscoutsneny.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "DonorPerfect is easy to learn, customizable for our organization, and can include access to tools like crowdfunding and online auctions. DonorPerfect staff are also very receptive to feedback from users and implement suggested enhancements to improve the software.",
    "a2": "Creating online donation pages and setting up compound filters have a steeper learning curve than the rest of the system.",
    "a3": "It would be nice to be able to copy and edit some of the standard reports instead of trying to rebuild them from scratch when we want to make an adjustment for our needs.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 10,
    "a7": 9,
    "a8": 9,
    "a9": 10,
    "a10": 10,
    "a11": 9,
    "a12": "Girl Scouts of Northeastern New York -  Monthly Invoice - 12-May-21.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12667380505,
    "first": "Regina",
    "last": "Valentini",
    "org": "Organization wishes to be anonymous.",
    "email": "Regina.Valentini@healthynewalbany.org",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 8,
    "a1": "The solution is very Comprehensive and modular. I love that you buy what you need when you need it and can add/cancel at any time.",
    "a2": "While very comprehensive, not totally customizable. Some processes are inherent and cannot be changed.",
    "a3": "More flexible processes.",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 7,
    "a7": 8,
    "a8": 8,
    "a9": "5 - Neutral, Neither Agree nor Disagree",
    "a10": 10,
    "a11": 8,
    "a12": "45713700-645F-42D8-B99B-F0291B42CABA.jpeg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12666617090,
    "first": "Jeanette",
    "last": "Conaway",
    "org": "Organization wishes to be anonymous.",
    "email": "jec98hd@gmail.com",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "Customer Service is unlike any other in the industry and its the best I've ever experienced!  Whether I use the online chat or call them, they always are helpful and patient.",
    "a2": "There is only one thing that is challenging.  Our old software allowed entering a couple on one record, DonorPerfect does not requiring the team to reenter information.",
    "a3": "DonorPerfect is very responsive and seems to listen to customers.  They continually implement changes customers recommend.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 10,
    "a7": 9,
    "a8": 9,
    "a9": 8,
    "a10": 10,
    "a11": 9,
    "a12": "Southfield School LA -  Monthly Invoice - 13-May-21.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664115665,
    "first": "Nicole",
    "last": "Fader",
    "org": "Organization wishes to be anonymous.",
    "email": "nicole.fader@cristoreysj.org",
    "sector": "Education",
    "users": "5 - 10",
    "income": "$100,000 - $250,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "I absolutely love that we have the option to customize acknowledgement letter and emails so easily. I can produce mass emails, too! Probably my favorite feature!",
    "a2": "Pulling reports can be a bit cumbersome especially when we are gathering information that is very specific to our donors.",
    "a3": "Please improve the report builder section so that it's easier for users to customize and filter through all donor records.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 10,
    "a8": 10,
    "a9": 8,
    "a10": 10,
    "a11": 10,
    "a12": "Screen Shot 2021-05-17 at 10.03.47 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664065715,
    "first": "Joe",
    "last": "Gerber",
    "org": "Organization wishes to be anonymous.",
    "email": "joe@loving-shepherd.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "The help line is extremely good.  The speed and helpfulness of their product specialists is the best I've seen in any industry.",
    "a2": "It's a little clunky with email integration and the online forms don't fully integrate with Google Analytics Ecommerce.",
    "a3": "Improve email integration and make sure online forms fully integrate with Google Analytics Ecommerce.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 10,
    "a11": 9,
    "a12": "DP Logged In.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664062158,
    "first": "Chris",
    "last": "Kendall",
    "org": "Organization wishes to be anonymous.",
    "email": "kendall@nas.org",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "It's highly customizeable in terms of fields and structure. Their chat customer service is extremely fast and provides excellent customer service. The reports are pretty intuitive and provide a lot of flexibility.",
    "a2": "It does not integrate well with other programs. If it has on official partnership with them (like DonorSearch or Constant Contact) the integration is great. But if you want to use any other integrations it's a nightmare. The next one is minor, but you can't change the default titles on exports - each field shows up as its backend code, which just means for every report I have to spend 2-3 minutes changing all the titles on each column of data. The online forms you have to use with its payment processor are awful - they require an immense amount of website reformatting to get to work well, and they have almost no customization options.",
    "a3": "If you want to change the look of your individual pages on a record, there's not an easy way to do it. It'd be better if there were a visualization editor where you could drag and drop things where you want them.",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 10,
    "a7": 7,
    "a8": 9,
    "a9": 9,
    "a10": 10,
    "a11": 9,
    "a12": "NAS DonorPerfect Login Screenshot.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663986165,
    "first": "Daryl",
    "last": "Ball",
    "org": "Organization wishes to be anonymous.",
    "email": "dball@njcts.org",
    "sector": "Medical & Wellness",
    "users": "5 - 10",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "I like that it can be updated in real time. My colleagues can see changes immediately. I like how easily it can be customized and links to a variety of forms.",
    "a2": "I don't like not have the ability to archive deceased donors so they don't show up in lists or reports but you don't loose the accounting behind the record so year over year and lifetime amounts don't get skewed. I don't like not being able to customize what is included in the print out of a transaction from the forms list.  Some of the thankyou letter coding gets me a little confused. Not every letter works well with all the merge fields.",
    "a3": "It would be nice if you could archive deceased donors some way and an increase  of articles on the more advanced coding processes.",
    "a4": "DonorPerfect",
    "a5": 4,
    "a6": "5 - Neutral, Neither Agree nor Disagree",
    "a7": 9,
    "a8": 9,
    "a9": "5 - Neutral, Neither Agree nor Disagree",
    "a10": 10,
    "a11": 10,
    "a12": "20210517_122043.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663967427,
    "first": "Linda",
    "last": "Rosso",
    "org": "Organization wishes to be anonymous.",
    "email": "Lindarosso@gmail.com",
    "sector": "Foundations",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "Donor Perfect is massively capable - it has more horsepower than we can ever use. Yet, it isn’t too complex and the support staff is always available to help figure out the easiest solution to any issue.",
    "a2": "The integration with Constant Contact is not intuitove. Constant Contact advertises Loya of features that are not available with the Donor Perfect version. So, this could be better.",
    "a3": "Perhaps have a dedicated support person to assist with Constant Contact?",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 10,
    "a8": 9,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "B8B598FA-5C84-4D90-A014-AAFD0DC38457.jpeg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663932343,
    "first": "Maria",
    "last": "Martinez",
    "org": "Organization wishes to be anonymous.",
    "email": "mmartinez@archregina.sk.ca",
    "sector": "Faith Based",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "It provides different features that helps with processing gifts, metrics, dashboard, KPIs, AFP statistics. You can control different type of campaigns, amongst all.",
    "a2": "Sometimes is not easy to find answers to doubts/questions if you don't know the proper words, or the word it uses for a specific feature.",
    "a3": "Use \"common\" words to refer to a characteristic, or template, or feature.",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 8,
    "a7": 8,
    "a8": 8,
    "a9": 8,
    "a10": 8,
    "a11": 9,
    "a12": "DP proof May 17 2021.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663923986,
    "first": "Jeff",
    "last": "Hunter",
    "org": "Organization wishes to be anonymous.",
    "email": "jeffh@langs.org",
    "sector": "Medical & Wellness",
    "users": "1 - Just Me",
    "income": "$10,000,000 - $50,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "The service is and the trainers are wonderful. There is a large knowledge base with lots of resources which can be accessed at a moment's notice. The online chat help is very quick to respond",
    "a2": "DonorPerfect can be a bit overwhelming with its large scope. There are many features which we do not use, but I am sure that as time goes on, we will tap into these additional items.",
    "a3": "The simplicity of generating reports.",
    "a4": "DonorPerfect",
    "a5": 7,
    "a6": 8,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "Screenshot DonorPefect login Jeff.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663903171,
    "first": "Amy",
    "last": "Slone",
    "org": "Organization wishes to be anonymous.",
    "email": "aslone@warmhearth.org",
    "sector": "Foundations",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "DonorPerfect has the BEST customer service. In a world where good customer service is increasingly lacking, it is even more appreciated, especially given that our time is our most valuable resource, and we can't afford to waste it. DonorPerfect is extremely user-friendly, but when I do need support/customer service, I get a clear, concise, friendly, easy-to-understand response and solution immediately, and I can't express how much I appreciate that.",
    "a2": "Honestly, there is nothing I dislike about DonorPerfect. In every instance I have wanted something to be different, I have been able to change or customize it myself or with the help of DonorPerfect support. Likewise, if there is a set of data or a report I need to run, I have never been unable to get that data or create a customized report to help me obtain it with the help of support.",
    "a3": "I am super happy with DonorPerfect, and cannot think of any area in which improvement is needed!",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "AS DP Screenshot 5.17.21.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663892310,
    "first": "Debra",
    "last": "Hull",
    "org": "Organization wishes to be anonymous.",
    "email": "dhull@okcnp.org",
    "sector": "Associations & Memberships",
    "users": "10 - 49",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "Ease of use, customer service, access to on-demand trainings. Their in-person conference has been a favorite of mine, as I always learn something new.  Donor Perfect is very user-intuitive. It meshes well with our other systems. We recently launched our online giving platform using this system. They have been extremely accessible during the set up and launch of the program and have checked in with us frequently to make sure all was working.   Their customer service is second to none; quick responses and they don’t quit until the issue has been solved to OUR satisfaction. They’ve been patient as we’ve added quite  few staff over the past few years and questions always arise.",
    "a2": "Really? Nothing! The problems I have stem from the way we implemented the solution 8 years ago. Our data was not clean when it was imported into the system.  If I had any complaint, it would be that it can be complicated to set the correct filters for some of the reports. And sometimes records that should be included in a filtered report won’t show up when the report is generated.  This causes extra work for our team, as we then need to go back and check to make sure that all other records we want are included in the report.",
    "a3": "Possibly making the Reports that come with the system customizable.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 10,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "DP screen shot 05.17.2021.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663878355,
    "first": "Nancy",
    "last": "Pabreza",
    "org": "Organization wishes to be anonymous.",
    "email": "npabreza@holycrossusa.org",
    "sector": "Faith Based",
    "users": "10 - 49",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 7,
    "a1": "Easy report building. Easy on the eyes - large font - easy to navigate. Greater capabilities than previous program in some reporting areas.",
    "a2": "Definition of lapsed/reactivied donors is different than ours. Makes utilizing some of the great packaged reports obsolete. Counts soft credit donors as inactive or lapsed. Knowledge base isn't helpful. Glossary is almost empty.",
    "a3": "More flexibility in the above. Also - a way to count soft credits so a donor isn't lapsed or inactive if they gift through a donor advised fund or other third party entity. Maybe the ability to make more complex filters.",
    "a4": "DonorPerfect",
    "a5": 7,
    "a6": 8,
    "a7": 7,
    "a8": 8,
    "a9": "5 - Neutral, Neither Agree nor Disagree",
    "a10": "5 - Neutral, Neither Agree nor Disagree",
    "a11": 7,
    "a12": "DP Screen shot.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663872152,
    "first": "Leesha",
    "last": "Baumann",
    "org": "Organization wishes to be anonymous.",
    "email": "leeshakay@gmail.com",
    "sector": "Animal & Wildlife",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "We love the cloud based solution allowing us to access the database from anywhere in and out of the office. The chat help feature has also been extraordinarily helpful.",
    "a2": "The coding can be a bit complex for the average user. The organization of the user profiles at times makes no sense and the inability to combine households easily is non-existent due to a lack of multiple email addresses for one profile. Email templates only are sent to the primary email rather than allowing you to choose who you'd like it to send to.",
    "a3": "Simplify the more complex coding like letter templates. Allow a householding feature to make donor contact easier and less repetitive.",
    "a4": "DonorPerfect",
    "a5": 6,
    "a6": 6,
    "a7": 9,
    "a8": 7,
    "a9": 9,
    "a10": 10,
    "a11": 9,
    "a12": "Untitled.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663860949,
    "first": "Serena",
    "last": "Dallas",
    "org": "Organization wishes to be anonymous.",
    "email": "serenadallas@gmail.com",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "It's simplicity to learn and and keep learning (free ongoing virtual training via webinars etc), easy access to chat (which never takes more than a few minutes to connect!",
    "a2": "Lacks additional features that you would like as you grow.  For example we started adding additional features at a much higher rate each month  (smart automation ect) which seems to me would be best suited at the basic level.",
    "a3": "Allow feedback within the program (since they take reviews seriously.  Consider email support as a basic feature and finally allow upgraded features with Constant Contact - we are taught about testing (A/B) and segmenting, but this feature is not available with our DP product and unable to upgrade this feature directly with Constant Contact without buying a whole new subscription.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 8,
    "a7": 8,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Capture6.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663856522,
    "first": "Tamara",
    "last": "Stafford",
    "org": "Organization wishes to be anonymous.",
    "email": "tamara@truenorthaid.ca",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "I love that it easily connects with quickbooks. Grabbing a report is very easy and their support is very good and easy to get a hold of if you have any questions.",
    "a2": "The donor forms dont look the best, it is sometimes hard to figure out what reports to pull it would be awesome if they had a more clear description of what is included in the report",
    "a3": "on their online forms",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 8,
    "a7": 8,
    "a8": 8,
    "a9": 8,
    "a10": 10,
    "a11": 9,
    "a12": "5_10_21 $290.41_DP_EFT.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12684029172,
    "first": "Juliana",
    "last": "Johnson Piller",
    "org": "Greater Cleveland Film Commission",
    "email": "jjohnson@clevelandfilm.com",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "I LOVE that DonorPerfect is cloud-based so I can access our database anywhere at any time. They also have a pretty intuitive mobile app that makes it easy  to access info on the go.",
    "a2": "I dislike that their payment processing still doesn't allow for Apple/Google/Samsung Pay, or PayPal. Also, while the online donation forms allow for a lot of customization, they can still look a little dated compared to other donor management software options out there.",
    "a3": "I would tell the vendor that they MUST get on the alternative payments ASAP. It's inconvenient that our donors can't use Apple/Google/Samsung Pay or PayPal to make their donations, especially our younger donors.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 10,
    "a7": 9,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Greater Cleveland Film Commission -  Monthly Invoice - 12-May-21.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12675687947,
    "first": "Foyinsola",
    "last": "Ani",
    "org": "PATH",
    "email": "foyinsolaa@epath.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$50,000,000 - $250,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "I love the fact that the system is overall very user-friendly, the available reports and report section is also easy to use. Their tech support is also easily accessible.",
    "a2": "Nothing to complain about at the moment per say. I really enjoy using the system and don't have much suggestions.",
    "a3": "The grants management tab in order to better track grants and pull specific reports",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 10,
    "a12": "DP SYSTEM.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12673869874,
    "first": "Joe",
    "last": "Cuozzo",
    "org": "Bucks County Opportunity Council",
    "email": "jcuozzo@bcoc.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "Easy to use, has tools for most everything I need, thank you process, contact management, donor info",
    "a2": "Duplicates through online giving, the online donation forms are a little long and can be confusing to donors",
    "a3": "Develop a better way to handle duplicate creation through online giving platforms, potentially make forms a little easier (there are four steps from contact to completion of donation)",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": "5 - Neutral, Neither Agree nor Disagree",
    "a7": 9,
    "a8": 9,
    "a9": 8,
    "a10": 10,
    "a11": 9,
    "a12": "IMG_20210520_093316.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12670656104,
    "first": "Ruth",
    "last": "Schmidt",
    "org": "America's Keswick",
    "email": "rschmidt@americaskeswick.org",
    "sector": "Faith Based",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "user-friendly, great reporting features, transfer of data from our old system was all done for us and retained all old data",
    "a2": "some of the reporting options could be a little easier, sometimes hard to find answers to specific questions in knowledge base",
    "a3": "it would be nice if some of the reports were more easily customizable",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 8,
    "a8": 9,
    "a9": "5 - Neutral, Neither Agree nor Disagree",
    "a10": 9,
    "a11": 10,
    "a12": "DPO screenshot.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12670107230,
    "first": "Sydney",
    "last": "Miller",
    "org": "The Belle W. Baruch Foundation",
    "email": "sydney@hobcawbarony.org",
    "sector": "Environmental",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "The clean layout, and the best practices they inspire and accommodate. The knowledge base has never failed me!",
    "a2": "On the whole I love the built in templates, but would like to more easily add fields. There are more features on which I need to educate myself.",
    "a3": "Not a damn thing.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "SCM DP.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12667716819,
    "first": "Patty",
    "last": "Porretta",
    "org": "Girls Inc. of York Region",
    "email": "p1porretta@rogers.com",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "I love that I can access it from anywhere and that it provides an excellent infrastructure for our annual fundraising activities. The reporting feature is amazing which is a great help in enhancing our fundraising activities to increase revenues and provides and excellent planning tool. Customer support is amazing. They provide great free fundraising webinars on many topics and free resources to download on their website. I could go on and on.",
    "a2": "That Donor Perfect is not compatible with CanadaHelps.org online donation system that many charities use. Please see question 11.",
    "a3": "Making it compatible with CanadaHelps.org i.e. donations are automatically added to Donor Perfect.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 10,
    "a7": 9,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Donor Perfect Picture.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12667688578,
    "first": "Carrie",
    "last": "Packard",
    "org": "Stout Street Foundation",
    "email": "Carriep@stoutstreet.org",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "DonorPerfect",
    "rating": 8,
    "a1": "Reporting and customization of entry screens. I love that I can set up data entry to match our response forms, etc. I also like the reporting options and how they work with the AFP standards.",
    "a2": "Pay extra for EVERYTHING! It is extra to run an NCOA. The video thank you options are incredibly expensive when BombBomb is only $29 a month,.",
    "a3": "Other database companies are offering NCOA and wealth screening as part of their basic package. I love the customization of data entry screens and calculated fields in Donor Perfect, but there are times, when I am charged for everything, I wish I had gone with another solution - and I was able to choose my solution for this position.",
    "a4": "DonorPerfect",
    "a5": 6,
    "a6": 8,
    "a7": 9,
    "a8": 9,
    "a9": "5 - Neutral, Neither Agree nor Disagree",
    "a10": 9,
    "a11": 8,
    "a12": "Donor Perfect Screen Shot.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12667041974,
    "first": "Holly",
    "last": "Dougall",
    "org": "Alzheimer Society Windsor Essex County",
    "email": "hdougall@aswecare.com",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 5,
    "a1": "Houses a lot of different types of information in one place, cloud based, readily accessible from home with work from home in place (COVID).",
    "a2": "The wording - misleading - could be much more simple. The trainer assigned to me speaks too quickly and assumes I know things about the system that I do not. It has become quite frustrating seeking support/",
    "a3": "Wording, too complicated and common words used mean something entirely within the program.",
    "a4": "DonorPerfect",
    "a5": 2,
    "a6": "5 - Neutral, Neither Agree nor Disagree",
    "a7": 7,
    "a8": "5 - Neutral, Neither Agree nor Disagree",
    "a9": 1,
    "a10": 9,
    "a11": 5,
    "a12": "IMG_4813.jpeg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12666772571,
    "first": "Hope",
    "last": "Marstin",
    "org": "Patrick Henry Memorial Foundation",
    "email": "om@redhill.org",
    "sector": "Arts & Culture",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "Easy to use to make changes, add information and it produces great reports! It also is easy to set up follow up contacts.",
    "a2": "I do not know of anything I dislike about this solution, except maybe when the system goes down and is not available.",
    "a3": "Have the donation on the search page",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 8,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 9,
    "a12": "Screen shot.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664963939,
    "first": "Robin",
    "last": "Novotny",
    "org": "Friends of Wisconsin Singers",
    "email": "robin@wisconsinsingers.com",
    "sector": "Arts & Culture",
    "users": "5 - 10",
    "income": "$100,000 - $250,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "It provides multiple resources for effective fundraising, excellent tech support, ability to integrate Constant Contact and a wealth of ways to utilize the data to grow our donor base.",
    "a2": "The learning curve has been rather steep, particularly for someone who does not have experience with database work.",
    "a3": "I think the initial training of users could be better organized. There were certain things that were left out of our initial one on one training that we've discovered after the fact that could have helped us at the get go. Also, one on one training on how to do reports would be extremely helpful as this is where analyzing the data is best served.",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 9,
    "a7": 10,
    "a8": 9,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Screen Shot 2021-05-17 at 4.44.32 PM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664948349,
    "first": "Kristin",
    "last": "Ward",
    "org": "Judeo-Christian Outreach Center",
    "email": "kward@jcoc.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "DonorPerfect's customer service and training is wonderful and helpful. If a representative doesn't know the answer to your question, they will always find out for you in a timely fashion. More than satisfied with the solution's capability, we added its Ready.Set.Auction., DP Text, and Processing Gateway last year before our annual gala (turned out to be a wonderful virtual experience).",
    "a2": "There is so much to learn in the report center. I wish there was an easier way to consolidate reoccurring reports.",
    "a3": "Processing Gateway - there needs to be an easy way to match direct deposits into our bank account with the online giving batches. They've working on this for months.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "IMG_7534.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664690746,
    "first": "Jeannette",
    "last": "Carrington",
    "org": "New Life Evangelistic Center",
    "email": "jcarrington@nlecstl.org",
    "sector": "Human Services",
    "users": "10 - 49",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "DonorPerfect Online grows with us.  It not only allows us to pay according to the number of records that we have, but it allows us to learn more about our donors and track what we have learned.  We are able to analyze our data in many different ways to understand our donors and to see what areas we need to work on.",
    "a2": "I do wish that their forms were more user friendly and that they used a drag and drop, what you see is what you get (WYSIWYG) approach. However, the forms are time saving because the form submissions can be automatically downloaded into the database saving time and data entry errors.",
    "a3": "I do wish that their forms were more user friendly and that they used a drag and drop, what you see is what you get (WYSIWYG) approach.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Screenshot 2021-05-17 150039.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664648834,
    "first": "Angela",
    "last": "Benson",
    "org": "Shiloh Christian School",
    "email": "angela.benson@shilohchristian.org",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "It is comprehensive and complete in providing what we need for our organization. The training and support are great.",
    "a2": "It can be overwhelming due to all the capacities is has available.  As a small non profit with many job responsibilities it feels like I am not utilizing the software as well I we could.  It's hard to know where to start.",
    "a3": "It would be helpful to provide summaries of the capabilities within in each section to determine what can be executed by small, medium and large companies with differing levels of resources.",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 9,
    "a7": 10,
    "a8": 9,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "20210517_144446.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664574683,
    "first": "Julian",
    "last": "Galimo",
    "org": "ICAN",
    "email": "jgalimo@ican.family",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 7,
    "a1": "This software is user-friendly.  It provides the necessary tools for tracking, moves management and data imports for donors and organizations.  I also like that is has the educational tools, community support forums, webinars and a chat with us now features for troubleshooting.  It is a great CRM software for our capacity and fundraising efforts.",
    "a2": "I wish some of the fields were more customizable in the way they are situated within the platform. In addition the creation of soft/hard credit button for individual/organization donors would be easier for tracking purposes.",
    "a3": "I would suggest the vendor improve the soft/hard credit feature. I would also suggest that for donors who are couples -- Mr. & Mrs.  -- their records should reflect that or have a better option to connect the donors.      Also, for contacts  --- that section needs improvement for the recording of conversations - I feel as though that section and adding in those reports under contacts can be difficult. There should also be an option to run monthly contact reports.      Lastly, I would improve upon the prospect assignment feature.  I think there needs to be an option to assign on the main screen - not just in the contact sections.  I think its beneficial when adding in a new donor - that it can be assigned to a specific person if needed.  That way creating prospect lists becomes easier if pulling assigned prospects for each person.  Also, when pulling monthly contact reports it will demonstrate the staff assigned to the prospect.      I would also provide a space to put necessary information regarding the donor such as job, title, children - identifiable items that can be beneficial to the organization and fundraising efforts.",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 8,
    "a7": 7,
    "a8": 8,
    "a9": "5 - Neutral, Neither Agree nor Disagree",
    "a10": 9,
    "a11": 7,
    "a12": "DP photo.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664458747,
    "first": "Jennifer",
    "last": "Roberts",
    "org": "City Rescue Mission",
    "email": "jenniferr@cityrescuemission.org",
    "sector": "Faith Based",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "The main attribute of this software that I love is it's overall ease of use. We have several users who have varying levels of computer experience and all are able to utilize the software. The program enables us to capture all donor information in one spot and allows each employee to view and utilize the information. I also love the learning community that is readily available for all questions.",
    "a2": "Sometimes it is difficult to determine which report to use when I am trying to get donor information out of the sytem",
    "a3": "Maybe some way to offer a more interactive way to learn the program.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 9,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 9,
    "a11": 10,
    "a12": "Screenshot (1).png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664443973,
    "first": "Elaine",
    "last": "Carpenter",
    "org": "Youth Guidance Mentoring Academy",
    "email": "ecarpenter@youthguidanceprogram.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "It performs well, clients can suggest improvements, and the customer service team is the best I've ever experienced.",
    "a2": "That we haven't been able to afford to add more users yet.  DonorPerfect does have a warning when signing in will kick another user off, though.  That helps us.",
    "a3": "I don't know enough about technology to tell the vendor anything beyond the information required for me to get tech support.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": "5 - Neutral, Neither Agree nor Disagree",
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Donor Perfect Annual renewal.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664409414,
    "first": "Richard",
    "last": "Konieczny",
    "org": "Interserve Canada",
    "email": "richkon@juno.com",
    "sector": "Faith Based",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "Easy to use and navigate.  Quick help when a problem is encountered.  Very intuative for the user.  There are also checks and balances and with the Reports it is easy to do diagnostics to unceover mistakes.",
    "a2": "Nothing at this point except that there are some repetitive tasks that could be streamlined.  See a suggestion in mumber 11 below.",
    "a3": "1. As there are some repetitive monthly donations (same donor number, same amount, same type of donation, same allocation, etc) to have some kind of macro to duplicate the previous entry.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 8,
    "a7": 9,
    "a8": 9,
    "a9": "5 - Neutral, Neither Agree nor Disagree",
    "a10": 9,
    "a11": 9,
    "a12": "Logged-In to DonorPerfect.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664407113,
    "first": "Nicole",
    "last": "Powers",
    "org": "Street Business School",
    "email": "nicole@streetbusinessschool.org",
    "sector": "International",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "Incredibly easy to learn, intuitive layout and functionality, powerful reporting without being confusing, ability to customize fields, prompt and helpful support, thorough resources and knowledgebase",
    "a2": "Honestly I can't think of anything - there is nothing that I actively dislike about the system and I have been the main admin for two years.",
    "a3": "Maybe my one wish would be to easily pull expected pledges by year. This is a little complicated right now, using a cash flow report, and it seems like something most orgs would want to know.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 9,
    "a7": 9,
    "a8": 10,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "DP screenshot.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664390076,
    "first": "Lori",
    "last": "Baker",
    "org": "Alexander Leigh Center for Autism",
    "email": "bylaws@sbcglobal.net",
    "sector": "Education",
    "users": "50+ Users",
    "income": "$100,000 - $250,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "DonorPerfect is user friendly and provides all of the information that we are looking for. We can pull any report using any field in the database. The information is valuable for donor data anylsis.",
    "a2": "If a record, gift, or donor information is deleted in error, it is completely gone unless a back-up was just created and it can be recovered.",
    "a3": "Try to create a new backup option.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 9,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "DonorPerfect Screen Shot.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664385422,
    "first": "Pat",
    "last": "Hackman",
    "org": "Guardian Angels Senior Services",
    "email": "phackman@ga-er.org",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "The support team at Donor Perfect is the best. Always helpful, patient, understanding and professional.",
    "a2": "Donor and giving reports can be cumbersome and even difficult to pull if you are not doing it on a regular basis.",
    "a3": "Consider adding a virtual event platform.",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 8,
    "a10": 10,
    "a11": 10,
    "a12": "Donor Perfect Screen Shot.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664357432,
    "first": "Brandi",
    "last": "Garcia",
    "org": "Boys & Girls Clubs of Mid Central Coast",
    "email": "brandi.garcia@bgccentralcoast.org",
    "sector": "Other (please specify)",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "This program keeps everything organized and accessible in one place.  It is easy to use and if we need assistance it is very easy to get someone on the phone for support.",
    "a2": "With so much information and options it can be a bit confusing, but support is always willing to help.",
    "a3": "I think it could be a little more user friendly.  I would love a way to send out invoices through the program.",
    "a4": "DonorPerfect",
    "a5": 7,
    "a6": 9,
    "a7": 8,
    "a8": 9,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "Donor Perfect.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664272605,
    "first": "Lydia",
    "last": "Kokolskyj-West",
    "org": "Cristo Rey San Jose Jesuit High School",
    "email": "Lydia.Kokolskyj-West@cristoreysj.org",
    "sector": "Education",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "It is very easy to use and you do not need an in-house specialist to manage data.  You can purchase and remove segments as you need them making the system very cost effective for smaller organizations. The standard reports provided truly help provide insights to drive strategy. Training is available as well as a very efficient and patient help desk.",
    "a2": "The onboarding and system migration could have been handled a little differently.  We found issues with our data that did not show up in the planning of the data conversion and migration.  Some could not be changed globally and had to be updated manually.",
    "a3": "Perhaps provide more deliberate training when folks are onboarded and assign a specific trainer to working with the organization.  It has been cumbersome at times to have different trainers delivering different training to staff.  Having the same trainer allows the person to become familiar with the organization and help identify issues as well as provide organization specific solutions.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 10,
    "a7": 9,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Screen Shot 2021-05-17 at 10.44.30 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664251757,
    "first": "Kimberly",
    "last": "Horn",
    "org": "Desert Christian Schools",
    "email": "khorn@desertchristian.com",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "It is very intuitive; very easy to work with. I love the ability to track every interaction with donors and set reminders.",
    "a2": "Merging donors can be very tedious. I think the software could be a little better in recognizing same donors.",
    "a3": "There is always room for improvement, ut I cant think of any specifics.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 6,
    "a10": 9,
    "a11": 9,
    "a12": "screen cap.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664233601,
    "first": "Jason",
    "last": "Hynson",
    "org": "Victory Mission",
    "email": "Jason@VictoryMission.com",
    "sector": "Faith Based",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "It is easy to use. I appreciate the customer service to any issue. They have a great crowdfunding plan and all the bells and whistles.",
    "a2": "The online giving platform is not updated to current trends. The online giving needs to be an integrated solution and not a require a stand alone site.",
    "a3": "They are working on updates currently.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 10,
    "a7": "5 - Neutral, Neither Agree nor Disagree",
    "a8": 8,
    "a9": 8,
    "a10": 10,
    "a11": 9,
    "a12": "Bill_2021-Springfield_Victory_Mission_DPO.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664225681,
    "first": "Judy",
    "last": "Callahan",
    "org": "Putnam Community Action Program/WestCOP",
    "email": "jcallahan@westcop.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "Excellent customer service and ease of use.   The customer service department is very responsive and guides you through whatever issue you are encountering.  The product itself is relatively easy to use, even for novices who have never used a donor management system before.",
    "a2": "High additional fees for add-ons and little flexibility with those modules, making them less than an optimal solution.",
    "a3": "Pricing structure more affordable for smaller customers and more flexibility with the low-cost (limited) add-ons such as the Event Management module.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 7,
    "a7": 10,
    "a8": 10,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "DP Screen Shot.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664177849,
    "first": "Andrea",
    "last": "Pasco",
    "org": "KVCAP",
    "email": "andreap@kvcap.org",
    "sector": "Human Services",
    "users": "1 - Just Me",
    "income": "$100,000 - $250,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "Easy to use, cloud based - can log on from my phone, at home, the office, etc.; lots of report options, amazing phone support",
    "a2": "Sometimes duplicates donors who give online via their weblink app if they enter their info slightly differently each time (e.g. they write \"Ave\" instead of \"Avenue\".",
    "a3": "Initially I found it very challenging to import my data from all of the different spreadsheets. More assistance when personalizing the database at the beginning.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 8,
    "a7": 10,
    "a8": 10,
    "a9": "5 - Neutral, Neither Agree nor Disagree",
    "a10": 10,
    "a11": 10,
    "a12": "Capture.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664157064,
    "first": "Linda",
    "last": "Henderson",
    "org": "Rolling Hills Zoo",
    "email": "linda@rollinghillszoo.org",
    "sector": "Animal & Wildlife",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 8,
    "a1": "Donor Perfect is very user friendly donor management platform that allows us to track donor giving and stewardship information.  When needed, we have received great customer service.",
    "a2": "The only dislike that we have is that we have trouble with tracking on educational activities/registrations with our constituents.  When they register a child for summer camps, etc., it ends up creating duplicate constituents that then ups our numbers for constituents and puts us into a higher bracket.",
    "a3": "Would improve the integrating of registrations for our educational programing.  We don't need to add additional constituent records for each child when it should all fall under one household.",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 8,
    "a7": 7,
    "a8": 8,
    "a9": 8,
    "a10": 8,
    "a11": 8,
    "a12": "Screen Shot of Donor Perfect.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664114132,
    "first": "Teresa",
    "last": "Wang",
    "org": "Pasadena Educational Foundation",
    "email": "twang@pasedfoundation.org",
    "sector": "Education",
    "users": "2 - 4",
    "income": "$10,000,000 - $50,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": ": Donor Perfect is easy to use and has a simple and intuitive interface. I like that it has comprehensive options for gift processing, event modules, receipts, and default reports. I never thought that I would need. I also like the Constant Contact integration to make sending out email blasts easier, along with other 3rd party platform integration. It’s also relatively affordable compared to some of the more robust CRMs out there, which is great for small and medium sized nonprofits. One convenient feature is Instant Merge, which allows you to quickly generate a merged acknowledgment letter from a gift entry. The default gift settings allow you to enter a large batch of donations at once, saving both time and ensuring accuracy.",
    "a2": "The inability to in Global Update to delete or update Flags. Streamlining some tedious processes that require too many clicks and fields to fill out. The ability to merge duplicate codes like solicitation, general ledger, flags, etc. The ability to edit default DP reports, since sometimes the default formatting is often not usable to us.",
    "a3": "I would like to see a main profile field called “status”, with “Active” set to default and a drop-down list that includes Inactive and Deceased. Right now, all our deceased doors show as “Lapsed” and the only field for us to mark it is the “Do Not Mail Reason”.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 10,
    "a7": 9,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "DP Home Screenshot.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664101110,
    "first": "Georgia",
    "last": "Briggs",
    "org": "Springfield Rescue Mission",
    "email": "georgiab7@verizon.ne",
    "sector": "Human Services",
    "users": "10 - 49",
    "income": "$250,000,000+",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "The ease of use, able to build reports as well as use DP templates. The Knowledgebase is designed for the End User with clear concise directions including video training. There is opportunity to Suggest and Vote for changes we would like to see - and they have excellent Tech Support staff.",
    "a2": "Unable to sort certain lists, such as Links in a donor's record. ID numbers are not in those lists either.",
    "a3": "Make available the ability to sort Link lists and add the Donor_ID",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "Springfield Rescue Mission DPO - Subscription Renewal.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664083410,
    "first": "Mary",
    "last": "Overman",
    "org": "Volunteers in Medicine",
    "email": "mary.overman@vim-cascades.org",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "Easy to learn and use, and captures the necessary donor information to assist with growth and cultivation. If you're not familiar with the system, the \"knowledgebase\" has most of the answers you need, but they are also great at responding quickly to user inquiries.",
    "a2": "Occasionally there is donor info I'd like to capture for reporting and there is no specific section in DPO for this information. I tend to put it in the \"other\" section, which is easy to pull, although if you were putting too many disparate pieces of information in there, it would not be easy to sort. However, this is a minor issue and I think DPO captures key information well.",
    "a3": "Add a section for \"how did the donor hear about us\".",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 9,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "DonorPerfect Online Billing.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664068169,
    "first": "Rose",
    "last": "DeFer",
    "org": "St. Vincent de Paul Catholic School",
    "email": "rdefer@svdpschool.org",
    "sector": "Education",
    "users": "5 - 10",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "It's versatility. It's ability to allow us to do our Golf Tournament payment online. The help from the DP people to run reports.",
    "a2": "I don't easily understand the reports segment. However, they are always so helpful to walk me through each step.",
    "a3": "They have trainings and helpful staff...so I wouldn't really change anything. They can adapt the program to suit your organization's needs.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "RDeFerDonorPerfect.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664065245,
    "first": "Nikki",
    "last": "Taylor",
    "org": "Boys & Girls Club of Farmington",
    "email": "nikkit@bgcfarmington.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "Easy to use and they keep up with updates and additions to make my job easier.  They send training notifications to my email so I can learn about more ways the system can support and help me.",
    "a2": "It isn't the system, but I wish I could spend more time learning how to use all the options.  There are so many different ways Donor Perfect can help make the tedious things about my job be quick and let donors know we care.",
    "a3": "Currently, nothing!",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "DP.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664062721,
    "first": "Paige",
    "last": "Hubner",
    "org": "The Watershed Foundation",
    "email": "paige@watershedfoundation.org",
    "sector": "Environmental",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "The chat support feature is amazing - they give clear directions to help me with creating new reports to help analyze giving patterns to determine which donors should be solicited at which level. We utilize the online forms for donations, event tickets and campaigns. This add-on feature saves me so much time inputting data and donations, it is worth every penny. It can be customized with our logos and colors so that we stay on brand. I also love that the reports created in DP have hyper-links to the donor records. This allows me to save time when altering records if entered incorrectly.",
    "a2": "I am quite happy with Donor Perfect. They take feedback and implement solutions from the problems submitted by users.  Recent updates to creating email in addition to printed thank you letters is wonderful, but I am still learning to adjust to no longer using the batch updates for printing receipts.",
    "a3": "I have just one slight issue. When I create an email thank you note, I also want to send a tax receipt that is printed to the donor. The email updates the batch code as already receipted. I have been manually sending the email, then creating the printed receipts while in the same record vs. creating a batch of letters to print.     I am confident that this is a simple fix and I already have a work around. I really enjoy using the Donor Perfect software.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 7,
    "a7": 9,
    "a8": 9,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Screenshot 2021-05-17 124034.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664054782,
    "first": "Robbie",
    "last": "Smith",
    "org": "Safe Harbor Boys Home",
    "email": "Robbiesmith@boyshome.com",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "DonorPerfect is affordable for a small organization, it is user friendly with templates, receipts are easy to send via letter or email",
    "a2": "Some of the settings and the menu aren't all that intuitive and it doesn't integrate with MailChimp, however it does integrate wtih Constant Contact",
    "a3": "Integration with MailChimp is a must for this software to receive an A+ rating from me.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 10,
    "a7": 8,
    "a8": 9,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Screenshot_2021-05-17 DPO Start Page.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664049551,
    "first": "Dee",
    "last": "Cook",
    "org": "Indigenous Ministries",
    "email": "Dee@indigenousministries.org",
    "sector": "Faith Based",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 8,
    "a1": "All our donors’ info is easily accessible and there are numerous reports we can generate to track our monthly and annual figures",
    "a2": "The reports are difficult to learn and produce, at least for me. My job requires me to wear a number of hats so at times I struggle to remember how to create filters for different reports and functions. I am getting better though because their customer service is very good and helpful",
    "a3": "Making some of the reporting more user friendly",
    "a4": "DonorPerfect",
    "a5": 6,
    "a6": 9,
    "a7": 7,
    "a8": 8,
    "a9": 9,
    "a10": 10,
    "a11": 8,
    "a12": "A05A1312-79F0-4285-8111-259BEB7938ED.jpeg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664041073,
    "first": "Roxanne",
    "last": "Jacobs",
    "org": "Grandma's Place",
    "email": "roxanne@grandmasplacepb.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "I LOVE how user-frendly this system this is. I really like the organizational dashboard that give you a quick snapshot of your giving by month, state, solicitaition and historical comparison.",
    "a2": "I wish that some of the reports that our auditor needs on an annual basis were included in the standard reports.",
    "a3": "I would suggest that they include more standard reports and to incorporate a button on the donor screen to print an envelope.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "DonorPerfect.JPG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12664003959,
    "first": "Maureen",
    "last": "Foox",
    "org": "Legal Services of the Hudson Valley",
    "email": "mfox@lshv.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 8,
    "a1": "It is easy to use and pull reports from - very user friendly - and easy to teach.........................................................................",
    "a2": "One size does not always fit eveyrone and some of our donors don't fit the fields that we have - difficult to personalize each record and have easy reporting.",
    "a3": "Allow the end user to add additional fields as necessary",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 8,
    "a7": 7,
    "a8": 8,
    "a9": 8,
    "a10": 8,
    "a11": 8,
    "a12": "Legal Services of the Hudson Valley -  Monthly Invoice - 13-Mar-21.pdf",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663960080,
    "first": "Darlene",
    "last": "Lamberti",
    "org": "Centre Grey Health Services Foundation",
    "email": "dlamberti@cghsfoundationc.om",
    "sector": "Medical & Wellness",
    "users": "2 - 4",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "Ease of use and how it integrates so beautifully with Quickbooks and Constant Contact.  Also love how they are constantly updating and improving!",
    "a2": "On Line form does not show well on iphones or Safari.  Should not have to follow a hyperlink.   There has to be a way to fix this.",
    "a3": "Please allow us to view our online donation form from an iphone for Safari without having to go to a hyperlink.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 8,
    "a8": 10,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "Screenshot (18).png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663943351,
    "first": "Regina",
    "last": "Gore",
    "org": "Facial Pain Association",
    "email": "rgore@tna-support.org",
    "sector": "Medical & Wellness",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 8,
    "a1": "Access to Chat Support. Access to recorded webinar training. Excellent one on one training. Excellent graphics. User defined dashboard.",
    "a2": "Lack of user defined reports and sometimes klunky interface with partner applications. Canned reports do not have flexible fields.",
    "a3": "Improve user defined fields and reports",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 8,
    "a7": 8,
    "a8": 9,
    "a9": 9,
    "a10": 10,
    "a11": 8,
    "a12": "DP Homepage.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663937694,
    "first": "Suse",
    "last": "Gomes",
    "org": "Chez Doris",
    "email": "don-donation@chezdoris.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 8,
    "a1": "The variety of options, the quality of customer support, the vast capacity of personalization are very important and",
    "a2": "Donor perfect is not perfect, but I think the company does its best to offer a great product and they try to improve it regularly. I don't have an exemple of something I dislike at this moment.",
    "a3": "Online forms styles are a little limited or outdated. There is place for improving the online forms visualy in order for them to be visualy interesting to the eye.",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 9,
    "a7": 8,
    "a8": 9,
    "a9": 8,
    "a10": 9,
    "a11": 8,
    "a12": "Capture1.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663937339,
    "first": "Lance",
    "last": "Ragsdale",
    "org": "French Camp Academy",
    "email": "lragsdale@frenchcamp.org",
    "sector": "Education",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "The support services offered by DonorPerfect are off the chart fantastic.  They are helpful, quick to respond, and collect information to make changes for future upgrades.",
    "a2": "DonorPerfect has met many of our database needs. The automatic reports have been effective. Though they migrated away from a server based model years ago, it proved to be the right move.",
    "a3": "Keep providing top quality support services.  It creates loyalty and engagement.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 10,
    "a8": 9,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "DP mobile screenshot.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663914456,
    "first": "Gerald",
    "last": "Buzzell",
    "org": "Youth for Christ of Kern County",
    "email": "jbuzzell@yfckern.org",
    "sector": "Faith Based",
    "users": "5 - 10",
    "income": "$250,000 - $750,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "The ease of use. The constant improvements being made. The efficiency of the data and it's safe keeping. The availability of live chat helps when in the midst of a project being worked on.",
    "a2": "I honestly have nothing I dislike about Donor Perfect. If anything at all it is how amazingly thorough and complete it is and what it can provide. I would add in the directions that 100 characters are needed as a minimum.",
    "a3": "Keep up the listening and the voting by your users. It helps to keep the program viable to those who use it.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "Screenshot (2).png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663908089,
    "first": "Nickolas",
    "last": "Lentz",
    "org": "Greater Lansing Food Bank",
    "email": "nickolas@glfoodbank.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 8,
    "a1": "The features of the reports that we can run for segmenting our donors. The fields allow for customization of reports and allow us to create multiple tags on different donors",
    "a2": "The interface for online giving feels old and is quite limiting. Specifically not allowing multiple if->then scenarios to occur for better communication with our donors.",
    "a3": "Allow more customization in the Online Forms",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 8,
    "a7": 8,
    "a8": 8,
    "a9": 8,
    "a10": 8,
    "a11": 8,
    "a12": "Capture.PNG",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663883953,
    "first": "Brenda",
    "last": "Gisolf",
    "org": "Hope Haven, Inc",
    "email": "bgisolf@hopehaven.org",
    "sector": "Human Services",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 9,
    "a1": "It is so easy to use.  We use it to track our events, eliminating multiple spreadsheets and mistakes. Support is very helpful and willing to answer the easiest questions and the more difficult questions. They are very respectful of our knowledge.",
    "a2": "Some reports can be hard to build.  I don't feel like we use this software to the best of our ability.",
    "a3": "Easier access to seminars and hands on training. Other programs that can be added on can be expensive. It does hinder us from using them",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 9,
    "a7": 9,
    "a8": 9,
    "a9": 9,
    "a10": 9,
    "a11": 9,
    "a12": "Donor Prerfect.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663875245,
    "first": "Jeremy",
    "last": "Kane",
    "org": "Gene Slay's Girls & Boys Club of St. Louis",
    "email": "jkane@gsgbcstl.org",
    "sector": "Human Services",
    "users": "2 - 4",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "Ease of use, customazability, it is super scalable as your organization grows, which has been a huge asset to our org.",
    "a2": "It's pretty much zero frills -- what you see is what you get. I've needed to supplement DonorPerfect with multipe additional systems like Classy and OneCause",
    "a3": "Sometimes it's a little clunky -- one of my favorite reports recently stopped exporting directly to Excel. Now I have to run it on the screen and then export, as opposed to export directly. I had been able to export directly for eight years prior to this occurring.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 8,
    "a7": 10,
    "a8": 10,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "Screen Shot 2021-05-17 at 10.41.51 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663861499,
    "first": "Samantha",
    "last": "Daggett",
    "org": "Just for Girls",
    "email": "sdaggett@myjfg.org",
    "sector": "Education",
    "users": "10 - 49",
    "income": "$1,500,00 - $3,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "It is by far the most user friendly donor database software on the market! I have been using it for over 12 years and have also been a beta tester.",
    "a2": "Nothing - everything is customizable, so anything that doesn't run on templates, I just create my own.",
    "a3": "Nothing",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "DP log on screen.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663860978,
    "first": "Lisa",
    "last": "Hansell",
    "org": "Wissahickon Trails",
    "email": "lisa@wissahickontrails.org",
    "sector": "Environmental",
    "users": "5 - 10",
    "income": "$750,000 - $1,500,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "Their customer service is the best I've ever experienced in any sector. Their staff go above and beyond to teach you how to use the system and customize it for your organization's needs.",
    "a2": "There are some parts of the system that feel outdated, like the lack of tools to track the giving and participation from multiple donors/volunteers from the same household.",
    "a3": "1. Work on the ability to better track individual engagement from multiple people in the same household. 2. Improve the customization functionality of on-line giving forms.",
    "a4": "DonorPerfect",
    "a5": 9,
    "a6": 10,
    "a7": 9,
    "a8": 10,
    "a9": 9,
    "a10": 10,
    "a11": 10,
    "a12": "Screen Shot 2021-05-17 at 11.49.09 AM.png",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663854624,
    "first": "Ann",
    "last": "Myers",
    "org": "Partnership for Better Health",
    "email": "ann@forbetterhealthpa.org",
    "sector": "Other (please specify)",
    "users": "2 - 4",
    "income": "$100,000 - $250,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "It is user friendly. It had easy reporting systems. The tecnical support staff are always efficitent and helpful.",
    "a2": "There is nothing that I dislike.",
    "a3": "There is nothing that I would tell them to improve.",
    "a4": "DonorPerfect",
    "a5": 10,
    "a6": 10,
    "a7": 10,
    "a8": 10,
    "a9": 10,
    "a10": 10,
    "a11": 10,
    "a12": "DonorPerfect.jpg",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  },
  {
    "category": "Fundraising",
    "weight": "1",
    "id": 12663853971,
    "first": "Lori",
    "last": "Pace",
    "org": "Nashville Zoo",
    "email": "membership@nashvillezoo.org",
    "sector": "Animal & Wildlife",
    "users": "5 - 10",
    "income": "$3,000,000 - $10,000,000",
    "software": "DonorPerfect",
    "rating": 10,
    "a1": "Flexibility and ease in creating and running reports, ability to select almost any variation of options. Versatile options when setting up screens.",
    "a2": "Slight difficulty in de-duping records in that one must know the donor ID to do a mass elimination of, for example, defunct emails.",
    "a3": "I would love to be able to de-dup using the member number vs having to determine the donor ID and then process a de-dup import",
    "a4": "DonorPerfect",
    "a5": 8,
    "a6": 8,
    "a7": 9,
    "a8": 10,
    "a9": 8,
    "a10": 10,
    "a11": 10,
    "a12": "DP Customer Proof.docx",
    "a13": "",
    "a14": "I confirm that I am an authorized user of the software I am reviewing and grant consent to The NonProfit Times or its affiliates to publish my review as they deem necessary.  I understand that reviews might or might not be published at the sole discretion of The NonProfit Times, and that The NonProfit Times may choose to limit the number of reviews accepted per organization.I further confirm that I am not an employee, contractor, or agent of the vendor I am reviewing or The NonProfit Times and that my review was solely authored by me, not on my behalf, and I was not compensated by the vendor.  I understand that my review may be published on the Internet, and I release The NonProfit Times, its employees, partners, affiliates, and representatives from any and all liability that might arise from my review.",
    "a15": "",
    "a16": ""
  }
  ];
}

