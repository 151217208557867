import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from'./app.component';
import { LandingComponent } from'./landing.component';
import { CrmSoftwareComponent } from'./crm-software.component';
import { ReviewsCrmSoftwareComponent } from './reviews-crm-software.component';
import { HumanResourcesManagementSoftwareComponent } from './human-resources-management-software.component';
import { ReviewsHumanResourcesManagementSoftwareComponent } from './reviews-human-resources-management-software.component';
import { AccountingSoftwareComponent } from './accounting-software.component';
import { ReviewsAccountingSoftwareComponent } from './reviews-accounting-software.component';
import { AssociationManagementComponent } from './association-management.component';
import { ReviewsAssociationManagementComponent } from './reviews-association-management.component';
import { ReviewsBloomerangComponent } from './reviews-bloomerang.component';
import { ReviewsRaisersEdgeNxtComponent } from './reviews-raisers-edge-nxt.component';
import { ReviewsComponent} from './reviews.component';

const routes: Routes = [
  { path: '', redirectTo: '/crm', pathMatch: 'full' },
  { path: 'detail/:id', component: ReviewsComponent },
  { path: 'home', component: AppComponent },
  { path: 'landing', component: LandingComponent },
  { path: 'crm-reviews', component: ReviewsCrmSoftwareComponent},
  { path: 'hr', component: HumanResourcesManagementSoftwareComponent },
  { path: 'hr-reviews', component: ReviewsHumanResourcesManagementSoftwareComponent },
  { path: 'accounting', component: AccountingSoftwareComponent },
  { path: 'accounting-reviews', component: ReviewsAccountingSoftwareComponent },
  { path: 'association', component: AssociationManagementComponent },
  { path: 'association-reviews', component: ReviewsAssociationManagementComponent },
  { path: 'crm', component: CrmSoftwareComponent },
  { path: 'reviews', component: ReviewsComponent },
  { path: 'reviews-bloomerang', component: ReviewsBloomerangComponent },
  { path: 'reviews-blackbaud-nxt', component: ReviewsRaisersEdgeNxtComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
