<div class="container  text-center">
 
</div>


	<div class="container  text-center">
    <!--h6 tyle="color:grey">Sponsored:</h6-->
		<div class="row">
			<div style="padding:1em" class="col-12">
				<div>
					<div class="card" >
						<div class="card-body" style="padding: 1em;">
             <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Vendor Category</th>
                    <th scope="col">Total Vendors</th>
                    <th scope="col">Total Reviews</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row"><a class="navbar-brand" routerLink="/crm" routerLinkActive="crm">Fundraising CRM Software</a></th>
                    <td>71</td>
                    <td>245</td>
                  </tr>
                  <tr>
                    <th scope="row"><a class="navbar-brand" routerLink="/crm" routerLinkActive="crm">Association Management Software</a></th>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <!--tr>
                    <th scope="row"><a class="navbar-brand" routerLink="/crm-reviews" routerLinkActive="crm">Human Resources Management Software</a></th>
                    <td>11</td>
                    <td>38</td>
                  </tr>
                  <tr>
                    <th scope="row"><a class="navbar-brand" routerLink="/crm" routerLinkActive="crm">Accounting Software</a></th>
                    <td>0</td>
                    <td>0</td>
                  </tr-->
                </tbody>
              </table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
