
<div class="container">
	<div class="row">
   <div class="col-12">
      <div class="search-hero">
        <label>Search:</label>
        <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="&#61442;  Start searching">
      </div>
      </div>
</div>

	<div class="row">

    <!--div class="col-6">
      <label>Sectors:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="sectorText">
        <option *ngFor="let sector of sectors"  [value]="sector.name">{{sector.name}}</option>
      </select>
    </div>

    <div class="col-6">
      <label>Annual Contribution Revenue:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="annualText">
        <option *ngFor="let annual of annuals"  [value]="annual.name">{{annual.name}}</option>
      </select>
    </div>

    <div class="col-6">
      <label># of Users:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="usersText">
        <option *ngFor="let user of users"  [value]="user.name">{{user.name}}</option>
      </select>
    </div>

    <div class="col-6">
      <label>Overall Rating:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="ratingText">
        <option *ngFor="let rating of ratings"  [value]="rating.name">{{rating.name}}</option>
      </select>
    </div-->

     <!--div class="col-6">
      <label>Categories:</label>
      <select name="city" class="form-control" (change)="onChange($event.target.value)" [(ngModel)]="catText">
        <option *ngFor="let category of categories"  [value]="category.name">{{category.name}}</option>
      </select>
    </div-->




		</div>



	</div>

	<div class="container  text-center">
    <h6 tyle="color:grey">Results:</h6>
		<div class="row">
			<div *ngFor="let hero of heroes | filter:searchText" style="padding:1em" class="col-12">
				<div>
					<div class="card" >
						<div class="card-body" style="padding: 1em;">
							<ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" id="pills-home-{{hero.id}}-tab" data-toggle="pill" href="#pills-home-{{hero.id}}" role="tab" aria-controls="pills-home" aria-selected="true">{{hero.name}}</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" id="pills-contact-{{hero.id}}-tab" data-toggle="pill" href="#pills-contact-{{hero.id}}" role="tab" aria-controls="pills-contact" aria-selected="false">Pricing & Demo</a>
								</li>
							</ul>
							<div class="tab-content" id="pills-tab-{{hero.id}}-Content">
								<div class="tab-pane fade show active" id="pills-home-{{hero.id}}" role="tabpanel" aria-labelledby="pills-home-tab" style="padding:1em">
									<div class="row" >
										<div class="col-md-3">
                      <div>
                        <img style="padding:1em" class="card-img-top" src="{{hero.img}}" width="128em" alt="Card image cap">
                      </div>
                      <div>
                        <strong>{{hero.category}}</strong>
                      </div>
										</div>
											<div class="col-md-9">
                        <div class="row">
                          <div>
                            {{hero.bio}}
                          </div>
										  	</div>
                        <div class="row" style="padding: 1em">
                          <div class="col-md-6">
                           <strong>Average Rating: </strong>9
                          </div>
                          <div class="col-md-6">
                            <a class="navbar-brand" routerLink="/hr-reviews"><strong>Number of Reviews: </strong> 6 </a>
                          </div>
										  	</div>
										</div>
									</div>
								</div>
								<div class="tab-pane fade" id="pills-contact-{{hero.id}}" role="tabpanel" aria-labelledby="pills-contact-tab"><form id="contact-form" method="post" action="contact.php" role="form">
									<div class="messages"></div>
									<div class="controls" style="padding:1em">
										<div class="row" >
											<div class="col-md-6">
												<div class="form-group">
													<label for="form_name">Firstname *</label>
													<input id="form_name" type="text" name="name" class="form-control" placeholder="Please enter your firstname *" required="required" data-error="Firstname is required.">
													<div class="help-block with-errors"></div>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label for="form_lastname">Lastname *</label>
													<input id="form_lastname" type="text" name="surname" class="form-control" placeholder="Please enter your lastname *" required="required" data-error="Lastname is required.">
													<div class="help-block with-errors"></div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label for="form_email">Email *</label>
													<input id="form_email" type="email" name="email" class="form-control" placeholder="Please enter your email *" required="required" data-error="Valid email is required.">
													<div class="help-block with-errors"></div>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label for="form_need">Please specify your need *</label>
													<select id="form_need" name="need" class="form-control" required="required" data-error="Please specify your need.">
														<option value=""></option>
														<option value="Request quotation">Request quotation</option>
														<option value="Request order status">Request order status</option>
														<option value="Request copy of an invoice">Request copy of an invoice</option>
														<option value="Other">Other</option>
													</select>
													<div class="help-block with-errors"></div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<div class="form-group">
													<label for="form_message">Message *</label>
													<textarea id="form_message" name="message" class="form-control" placeholder="Please share your needs or concerns so we can best help you *" rows="4" required="required" data-error="Please, leave us a message."></textarea>
													<div class="help-block with-errors"></div>
												</div>
											</div>
											<div class="col-md-12">
												<input type="submit" class="btn btn-success btn-send" value="Send message">
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<p class="text-muted">
													<strong>*</strong> These fields are required</p>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
