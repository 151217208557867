import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-reviews-association-management',
  templateUrl: './reviews-association-management.component.html',
  styleUrls: ['./reviews-association-management.component.css']
})
export class ReviewsAssociationManagementComponent implements OnInit {

  constructor(private _route:ActivatedRoute) {
     this._route.queryParams.subscribe(params => {
        if(params.search){
          this.searchText = params.search;
        }
      });
   }

   sectorText = '';
   annualText = '';
   ratingText = '';
   usersText = '';
   searchSoftware = '';
   heroes = [];

  ngOnInit() {
    this.heroes = this.heroess;
    this.search(this.searchText);
  }
  onChange(event){

  }

  search(text){
    this.searchText = text;
    let newArray = [];
    for (var i=0; i < this.heroess.length; i++) {
     // newArray = [];
      if(this.heroess[i].software.toLowerCase().indexOf(text.toLowerCase()) > -1) {
        newArray.push(this.heroess[i]);
      }
    }
    this.heroes = newArray;
 }

  title = 'Marketplace';
  searchText;
  categories = [
    // {id: 1, name: 'All'},
    {id: 2, name: 'Software'},
    {id: 3, name: 'Insurance'},
    {id: 4, name: 'Financial'},
    {id: 5, name: 'Grants'},
  ];
   ratings = [
    // {id: 1, name: 'All'},
    {id: 2, name: '8-10'},
    {id: 3, name: '5-7'},
    {id: 4, name: 'Below 5'},
  ];
    users = [
    // {id: 1, name: 'All'},
    {id: 2, name: '1 (Just Me)'},
    {id: 3, name: '2-4'},
    {id: 4, name: '5-10'},
    {id: 5, name: '10-49'},
    {id: 6, name: '50+'},
  ];
  annuals = [
    // {id: 1, name: 'All'},
    {id: 2, name: '$0 - $100k'},
    {id: 3, name: '$100k - $250k'},
    {id: 4, name: '$250 - $750k'},
    {id: 5, name: '$750k+'},
  ];
   sectors = [
    // {id: 1, name: 'All'},
    {id: 2, name: 'Tribal'},
    {id: 3, name: 'Advocacy'},
    {id: 4, name: 'Non-Profit'},
    {id: 5, name: 'Food Bank'},
    {id: 6, name: 'Fiscal Sponsorship'},
    {id: 7, name: 'Community Organizing'},
    {id: 8, name: 'Affordable Housing'},
    {id: 9, name: 'Faith Based'},
    {id: 10, name: 'Education'},
    {id: 11, name: 'Foundations'},
    {id: 12, name: 'Associations & Memberships'},
    {id: 13, name: 'Environmental'},
    {id: 14, name: 'Medical & Wellness'},
    {id: 15, name: 'Software'},
    {id: 16, name: 'Insurance'},
    {id: 17, name: 'Financial'},
    {id: 18, name: 'Grants'},

  ];
  heroess = [
    { category:"HR", id: "11723318870", first: "Aspen", last: "Weathers", org: "USET Inc.", email: "aweathers@usetinc.org", sector: "Tribal", users: "5 - 10", income: "$250,000 - $750,000", software: "Paylocity", love: "Everything is in one place: timesheets, time off, HR records, paychecks and pay info, directories, and even some social networking options", dislike: "It can sometimes be overwhelming, and there is more than one way or path to complete every single task or get to any page. THis makes  it difficult to explain to our staff members and to tell if the site is incorrect/not working or if it is just user error.", tell: "I would like HR to be able to switch to an 'employee' view without all the supervisor/admin access to make sure that the notifications and instructions we send are able to translate effectively to our staff.", feel: "7", a1: "9", a2: "8", a3: "7", a4: "8", a5: "4", recommend: "9 "},
{ category:"HR", id: "11724921011", first: "Amiee", last: "Kim", org: "LiNK", email: "amiee@linkglobal.org", sector: "Refugee work, advocacy", users: "2 - 4", income: "$3,000,000 - $10,000,000", software: "Salesforce", love: "I love that it's customizable and can suit the needs of multiple departments, not just HR. Many applications can be built out but it requires the expertise of someone who is Salesforce certified or very experienced.", dislike: "It's not intuitive, because it's not solely for the use of HR. So that's not necessarily a fault of the platform.", tell: "N/A", feel: "2", a1: "5 - Neutral, Neither Agree nor Disagree", a2: "8", a3: "3", a4: "2", a5: "3", recommend: "2 "},
{ category:"HR", id: "11730694025", first: "Andra", last: "Wagner", org: "NPAIHB", email: "awagner@npaihb.org", sector: "Non-Profit", users: "1 - Just Me", income: "$0 - $100,000", software: "none", love: "we are small so we don't need one at this time but maybe in the future if we grow, at this time we only have 65 employees", dislike: "we haven't had any challenges so we don't need one at this time but maybe in the future if we grow, at this time we only have 65 employees", tell: "nothing at this time", feel: "5 - Neutral, Neither Agree nor Disagree", a1: "5 - Neutral, Neither Agree nor Disagree", a2: "5 - Neutral, Neither Agree nor Disagree", a3: "5 - Neutral, Neither Agree nor Disagree", a4: "5 - Neutral, Neither Agree nor Disagree", a5: "5 - Neutral, Neither Agree nor Disagree", recommend: "7 "},
{ category:"HR", id: "11723410951", first: "Taylor", last: "Flores", org: "Montana Food Bank Network", email: "tflores@mfbn.org", sector: "Food Bank", users: "10 - 49", income: "$750,000 - $1,500,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723405413", first: "Tracee", last: "Wallace", org: "Feeding the Gulf Coast", email: "twallace@feedingthegulfcoast.org", sector: "Food Bank", users: "50-99", income: "$1,500,00 - $3,000,000", software: "Paylocity", love: "Paylocity is very user friendly for employees and managers. The employees love the app and the community platform", dislike: "The reporting module does not give enough filters for capturing data, causing me to run multiple reports then piece them together", tell: "Reporting does not give enough filters", feel: "9", a1: "8", a2: "8", a3: "9", a4: "9", a5: "10 - Extremely Agree", recommend: "9 "},
{ category:"HR", id: "11723406524", first: "Andrew", last: "Levey", org: "Community Partners", email: "alevey@communitypartners.org", sector: "Fiscal Sponsorship", users: "100-999", income: "$50,000,000 - $250,000,000", software: "Paylocity", love: "The employee interface is pleasing and fairly easy to use. Though its sometimes harder for employees to make changes than it should be.", dislike: "Clocking time can be clunky, especially form the app. Additionally, back end changes can be a bit more complex than needed.", tell: "Make it easier for changes to flow across the entire platform.", feel: "6", a1: "6", a2: "7", a3: "8", a4: "5 - Neutral, Neither Agree nor Disagree", a5: "6", recommend: "7 "},
{ category:"HR", id: "11723379534", first: "Andrew", last: "Levey", org: "Community Partners", email: "alevey@communitypartners.org", sector: "Fiscal Sponsorship", users: "100-999", income: "$50,000,000 - $250,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11724341231", first: "Jeana", last: "Frazzini", org: "Forward Together", email: "jeana@forwardtogether.org", sector: "Community Organizing/Advocacy/Culture Shift", users: "2 - 4", income: "$3,000,000 - $10,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11734199081", first: "Sarah", last: "Forner", org: "CHN Housing Partners", email: "sforner@chnhousingpartners.org", sector: "Affordable Housing & Housing-stability Services", users: "2 - 4", income: "$250,000,000+", software: "PayCor & Salesforce", love: "Reporting capacity; having the capacity to track my fundraising progress throughout the year and report out the monetary value of the grants I secure helps me understand year-to-date totals against my annual goals.", dislike: "Layout/aesthetics; within individual opportunities/submission processes, I have a difficult time differentiating between sections, as the visual formatting doesn't incorporate bold subheadings, etc. Having the option to format/structure individual pages within the CRM would be helpful.", tell: "Layout/aesthetics", feel: "9", a1: "8", a2: "8", a3: "8", a4: "8", a5: "8", recommend: "8 "},
{ category:"HR", id: "11730459666", first: "Kathryn", last: "Thompson", org: "Center of Hope", email: "kathryn@centerofhopetx.com", sector: "Faith Based", users: "1 - Just Me", income: "$250,000 - $750,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11727388963", first: "Betsy", last: "Mitchell", org: "COA Youth & Family Centers", email: "bmitchell@coa-yfc.org", sector: "Human Services", users: "2 - 4", income: "$3,000,000 - $10,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11726779889", first: "Jeahan", last: "LeMasters", org: "KSGN", email: "jlemasters@ksgn.com", sector: "Faith Based", users: "1 - Just Me", income: "$1,500,00 - $3,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11726489019", first: "Shelley", last: "Campbell", org: "Southport Presbyterian Chaurch", email: "scampbell@southportpc.org", sector: "Faith Based", users: "2 - 4", income: "$1,500,00 - $3,000,000", software: "paylocity", love: "The programers move quickly when new legistration comes out to roll out solutions and the online chat help in fantastic. They actually assign a team of techs that know me when I have a question and remember details about my organization and my ability level.", dislike: "it is still not as fully integrated bewteen modules as one would expect in 2020.  I have found no payroll software is as advanced as one would expect but I see no reason why I should have to add a new emplyee to both time and payroll our set up a pay item in both modules.", tell: "Integration and flow between time and payroll", feel: "6", a1: "10 - Extremely Agree", a2: "8", a3: "8", a4: "8", a5: "10 - Extremely Agree", recommend: "10 "},
{ category:"HR", id: "11726347101", first: "vanessa", last: "frazier", org: "mifa", email: "vfrazier@mifa.org", sector: "Human Services", users: "2 - 4", income: "$3,000,000 - $10,000,000", software: "paylocity", love: "benefit enrollment for staff - open enrollment is convenient and user friendly - applications are also applicable", dislike: "integration capabilities; there are a number of modules that are independent and require duplications", tell: "integration/cross over data", feel: "7", a1: "7", a2: "7", a3: "7", a4: "7", a5: "7", recommend: "6 "},
{ category:"HR", id: "11726224938", first: "April", last: "Henry", org: "Wycliffe Bible Translators, Inc.", email: "april_henry@wycliffe.org", sector: "Faith Based", users: "1000+", income: "$10,000,000 - $50,000,000", software: "Workday", love: "The look and feel, Workday community that allows for people to submit and vote on improvement suggestions, they give adequate time before updates and allow for testing in a sandbox environment, security, having almost everything in one system", dislike: "Some things aren't as robust or are a little more clunky than individual systems, for example, some tasks will be called update and others will be called edit with no rhyme or reason.  I also wish exemption status lived on the person/position rather than on the job profile, and I thing the records management could be more robust, particular if you could select more than one category for a document.", tell: "There are several smaller things, but nothing huge enough to be worth mentioning.  We're overall relatively satisfied considering the complexity of our organization.", feel: "9", a1: "8", a2: "8", a3: "9", a4: "10 - Extremely Agree", a5: "9", recommend: "9 "},
{ category:"HR", id: "11725839330", first: "Kiley", last: "Enas", org: "Greater Pittsburgh Community Food Bank", email: "kenas@pittsburghfoodbank.org", sector: "Human Services", users: "2 - 4", income: "$10,000,000 - $50,000,000", software: "never seen in", love: "na we do not use your software so I do not know.  We actually use our payroll vendor as a full HRIS system", dislike: "na we do not use your software so I do not know.  We actually use our payroll vendor as a full HRIS system", tell: "na we do not use your software so I do not know.  We actually use our payroll vendor as a full HRIS system", feel: "5 - Neutral, Neither Agree nor Disagree", a1: "5 - Neutral, Neither Agree nor Disagree", a2: "5 - Neutral, Neither Agree nor Disagree", a3: "5 - Neutral, Neither Agree nor Disagree", a4: "5 - Neutral, Neither Agree nor Disagree", a5: "5 - Neutral, Neither Agree nor Disagree", recommend: "2 "},
{ category:"HR", id: "11724466842", first: "Kris", last: "Garrison", org: "LivWell CARES", email: "info@qclivwellcares.org", sector: "Human Services", users: "2 - 4", income: "$0 - $100,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11724409519", first: "Cara", last: "Schmidt", org: "The Tech", email: "cschmidt@thetech.org", sector: "Education", users: "50-99", income: "$10,000,000 - $50,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11724275505", first: "Sarah", last: "Dare", org: "Bloomfield Hills Schools", email: "sdare@bloomfield.org", sector: "Education", users: "5 - 10", income: "$50,000,000 - $250,000,000", software: "Sungard Business Plus", love: "System can prorate contracts and interface with Finance for payroll and benefit purposes for budgets", dislike: "System rejects attendance  after 21st pay cycle if Teacher elects 21 pays, yet there is usually 1 week of school left", tell: "Allow transactions for 22-26 pay cycles even if Teacher is 21 pay contract", feel: "9", a1: "9", a2: "9", a3: "9", a4: "10 - Extremely Agree", a5: "9", recommend: "9 "},
{ category:"HR", id: "11724178776", first: "Phil", last: "Dorcas", org: "Esperanto UA", email: "filipo@grupoj.org", sector: "Education", users: "100-999", income: "$0 - $100,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11724115109", first: "Velma", last: "McKen", org: "Miami Rescue Mission", email: "vmcken@caringplace.org", sector: "Human Services", users: "2 - 4", income: "$0 - $100,000", software: "BlackBaud", love: "Not much. I inherited it when I joined the company 10 years ago, and I have no choice but to use it.  It gives me the ability to run reports, and that's the best part.  I also get to create history for employees.  Other than that, there really isn't much I like about it.", dislike: "Almost everything.  It is not user friendly, and it's almost like you need to have a PhD to use it. For example, when running reports it doesn't give you the ability to pick your data from among actives and terminated employees.  It's very difficult to use.", tell: "It is not user friendly, and appears to be antiquated.", feel: "0 - Extremely Disagree", a1: "5 - Neutral, Neither Agree nor Disagree", a2: "0 - Extremely Disagree", a3: "0 - Extremely Disagree", a4: "0 - Extremely Disagree", a5: "0 - Extremely Disagree", recommend: "0 "},
{ category:"HR", id: "11724013345", first: "Danielle", last: "Pointdujour", org: "Urgent Action Fund", email: "danielle@urgentactionfund.org", sector: "Human Services", users: "2 - 4", income: "$1,500,00 - $3,000,000", software: "Paychex", love: "I love Paychex's User friendliness and reporting that makes it easy to navigate consistently for employees and managers", dislike: "Separate modules and upgrades for everything when it should be included in the basic product they sell to organizations", tell: "I'd tell them to create a cohesive product similar to Paycom that doesn't involve aggressive upsells.", feel: "7", a1: "0 - Extremely Disagree", a2: "5 - Neutral, Neither Agree nor Disagree", a3: "5 - Neutral, Neither Agree nor Disagree", a4: "4", a5: "0 - Extremely Disagree", recommend: "4 "},
{ category:"HR", id: "11723900281", first: "Fernan", last: "Cepero", org: "The YMCA of Greater Rochester", email: "fernanc@rochesterymca.org", sector: "Human Services", users: "1000+", income: "$10,000,000 - $50,000,000", software: "Paylocity", love: "Keeps abreast of emerging technologies on relatively timely basis. Very Intuitive user friendly system with logical navigation. The functionality weighs both technical and human relations implications.", dislike: "Customization is difficult although not entirely impossible. The design team needs to overcome their resistance to technological change driven by the customer's needs and wants. Does not make effective use of technical support.", tell: "Better customer service", feel: "10 - Extremely Agree", a1: "9", a2: "10 - Extremely Agree", a3: "10 - Extremely Agree", a4: "5 - Neutral, Neither Agree nor Disagree", a5: "0 - Extremely Disagree", recommend: "4 "},
{ category:"HR", id: "11723817369", first: "Sandra", last: "Pettit Weber", org: "Mosaic Child Care and Education Center", email: "sandra.pettitweber@mymlc.com", sector: "Education", users: "10 - 49", income: "$0 - $100,000", software: "Not sure", love: "This question is not applicable. We are overseen by our parent organization.  Our parent organization is Heartland Health dba Mosaic Life Care.", dislike: "This question is not applicable. We are overseen by our parent organization.  Our parent organization is Heartland Health dba Mosaic Life Care.", tell: "This question is not applicable. We are overseen by our parent organization.  Our parent organization is Heartland Health dba Mosaic Life Care.", feel: "5 - Neutral, Neither Agree nor Disagree", a1: "5 - Neutral, Neither Agree nor Disagree", a2: "5 - Neutral, Neither Agree nor Disagree", a3: "5 - Neutral, Neither Agree nor Disagree", a4: "5 - Neutral, Neither Agree nor Disagree", a5: "5 - Neutral, Neither Agree nor Disagree", recommend: "5 "},
{ category:"HR", id: "11723499609", first: "Katie", last: "Siahkoohi", org: "World Food Program USA", email: "ksiahkoohi@wfpusa.org", sector: "Human Services", users: "10 - 49", income: "$10,000,000 - $50,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723377761", first: "Beth", last: "Filla", org: "East Texas Communities Foundation", email: "bfilla@etcf.org", sector: "Foundations", users: "5 - 10", income: "$3,000,000 - $10,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723320936", first: "Andrew", last: "Scammell", org: "AAAS", email: "ascammell@aaas.org", sector: "Associations & Memberships", users: "5 - 10", income: "$1,500,00 - $3,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723304215", first: "Lisa", last: "Daughtry-Weiss", org: "Sojourners", email: "Lisadw@sojo.net", sector: "Faith Based", users: "2 - 4", income: "$3,000,000 - $10,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723290844", first: "Stephanie", last: "Espinoza", org: "Youth Outreach Services", email: "stephaniee@yos.org", sector: "Human Services", users: "100-999", income: "$100,000 - $250,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723284433", first: "Adrienne", last: "S", org: "Face to Face", email: "adrienne@facetofacegermantown.org", sector: "Human Services", users: "5 - 10", income: "$750,000 - $1,500,000", software: "Quickbooks desktop", love: "It does the job needed. I am comfortable with the desktop version. I have been using QB desktop for several years at different locations and it is easy to use.", dislike: "I have used QB's at several jobs since 2010 and the system may be updated in the background by is not updated for the user. I need to review the online version but have heard several reviews that make me Leary of moving to this service.", tell: "accessibility to excel, word and other platforms should be built in. If you do not use the payroll and still want to track employees for HR purposes you should be able. If a person is an employee, customer and maybe a vendor you should be able to use the same name and mark them as all 3.", feel: "9", a1: "10 - Extremely Agree", a2: "6", a3: "4", a4: "5 - Neutral, Neither Agree nor Disagree", a5: "5 - Neutral, Neither Agree nor Disagree", recommend: "7 "},
{ category:"HR", id: "11723279794", first: "Kerry", last: "Bohac", org: "The Centers", email: "kerry.bohac@thecentersohio.org", sector: "Human Services", users: "100-999", income: "$50,000,000 - $250,000,000", software: "Ceridian Dayforce", love: "The ease of use for our employees. We do very little training to get our end users up and started. Process which only take place once or twice a year do not require large efforts to reorient use.", dislike: "It's very sophisticated and a small department like ours can become a bit overwhelmed with maintaining it.", tell: "ease of administration", feel: "8", a1: "6", a2: "8", a3: "7", a4: "8", a5: "6", recommend: "7 "},
{ category:"HR", id: "11723279545", first: "Lori", last: "Meagher", org: "EcoAdapt", email: "lori@ecoadapt.org", sector: "Environmental", users: "1 - Just Me", income: "$1,500,00 - $3,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723264576", first: "Brigitte", last: "Fields", org: "Community Assessment and Treatment Svcs", email: "bfields@communityassessment.org", sector: "Human Services", users: "100-999", income: "$0 - $100,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723263189", first: "Joan", last: "Davis", org: "AHS", email: "jdavis@alamedahealthsystem.org", sector: "Medical & Wellness", users: "1000+", income: "$3,000,000 - $10,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723262789", first: "Donna", last: "Manion", org: "SUncoastHR", email: "secretary@suncoastHR.org", sector: "Associations & Memberships", users: "2 - 4", income: "$0 - $100,000", software: "None", love: "We do not have HRIS as we are totally volunteer staff with the lone  exception of a bookkeeper and webmaster who is a 1099..", dislike: "We do not have HRIS as we are totally volunteer staff with the lone  exception of a bookkeeper and webmaster who is a 1099..", tell: "We do not have HRIS as we are totally volunteer staff with the lone  exception of a bookkeeper and webmaster who is a 1099..", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723258462", first: "Alexander", last: "Sanborn", org: "Deaf and Hard of Hearing Service Center", email: "alexanders@dhhsc.org", sector: "Human Services", users: "1 - Just Me", income: "$1,500,00 - $3,000,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723256499", first: "Candi", last: "Vance", org: "A Plus Senior Care, LLC", email: "cvance@aplusseniorcare.com", sector: "Medical & Wellness", users: "2 - 4", income: "$0 - $100,000", software: "", love: "", dislike: "", tell: "", feel: "", a1: "", a2: "", a3: "", a4: "", a5: "", recommend: " "},
{ category:"HR", id: "11723199488", first: "JonTest", last: "Biedermann", org: "The Biedermann Group", email: "jon@biedermanngroup.com", sector: "Human Services", users: "1 - Just Me", income: "$3,000,000 - $10,000,000", software: "SalesForce", love: "aasdasdasdadddddddddddddddddddddddddsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssd", dislike: "asdasdasdasdssssssssssssssssssssssssssssssssdddddddddddddddddddddddddddddddddddddddddddddddddddddddddddsssssssssssssssssssss", tell: "asdasdasdasddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd", feel: "7", a1: "9", a2: "9", a3: "8", a4: "7", a5: "5 - Neutral, Neither Agree nor Disagree", recommend: "9 "},
  ];
}
