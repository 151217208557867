import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accounting-software',
  templateUrl: './accounting-software.component.html',
  styleUrls: ['./accounting-software.component.css']
})
export class AccountingSoftwareComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  sectorText = '';
  annualText = '';
  usersText = '';
  ratingText = '';
  catText = '';

  onChange(event){

  }

  onSelect(hero: any): void {
  this.selectedHero = hero;
  this.router.navigate(['/detail', this.selectedHero.id]);
  }

  title = 'Marketplace';
  searchText;
  selectedHero: any;
  hero: any;

  categories = [
    // {id: 1, name: 'All'},
    {id: 2, name: 'Software'},
    {id: 3, name: 'Insurance'},
    {id: 4, name: 'Financial'},
    {id: 5, name: 'Grants'},
  ];
   ratings = [
    // {id: 1, name: 'All'},
    {id: 2, name: '8-10'},
    {id: 3, name: '5-7'},
    {id: 4, name: 'Below 5'},
  ];
    users = [
    // {id: 1, name: 'All'},
    {id: 2, name: '1 (Just Me)'},
    {id: 3, name: '2-4'},
    {id: 4, name: '5-10'},
    {id: 5, name: '10-49'},
    {id: 6, name: '50+'},
  ];
  annuals = [
    // {id: 1, name: 'All'},
    {id: 2, name: '$0 - $100k'},
    {id: 3, name: '$100k - $250k'},
    {id: 4, name: '$250 - $750k'},
    {id: 5, name: '$750k+'},
  ];
   sectors = [
    // {id: 1, name: 'All'},
    {id: 2, name: 'Tribal'},
    {id: 3, name: 'Advocacy'},
    {id: 4, name: 'Non-Profit'},
    {id: 5, name: 'Food Bank'},
    {id: 6, name: 'Fiscal Sponsorship'},
    {id: 7, name: 'Community Organizing'},
    {id: 8, name: 'Affordable Housing'},
    {id: 9, name: 'Faith Based'},
    {id: 10, name: 'Education'},
    {id: 11, name: 'Foundations'},
    {id: 12, name: 'Associations & Memberships'},
    {id: 13, name: 'Environmental'},
    {id: 14, name: 'Medical & Wellness'},
    {id: 15, name: 'Software'},
    {id: 16, name: 'Insurance'},
    {id: 17, name: 'Financial'},
    {id: 18, name: 'Grants'},

  ];
  heroes = [
    { id: 11, name: 'InfoGroup', category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Infogroup is a leading provider of data and data-driven marketing solutions; helping customers achieve tangible results that set them up for success.', img: 'https://www.infogroup.com/wp-content/themes/infogroup/dist/assets/img/infogroup-soon-to-be-data-axle.png' },
    { id: 12, name: 'Advancement Resources' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Your strategic partner to drive meaningful philanthropy for your donors, your organization, and your career.', img: 'https://advancementresources.org/app/uploads/2020/01/2019_ARLOGO_Standard_RGB_outline-3x_190708.jpg' },
    { id: 13, name: 'GMS, Inc.' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'GMS is a leading distributor of construction products and provides commercial and residential building supply needs for local contractors in North America.', img: 'https://www.gmsactg.com/uploads/2/5/5/3/25532341/1396877897.png' },
    { id: 15, name: 'LIFT Leadership, Inc.' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Lift Leadership is fully certified and credentialed to teach and inspire you and your team on how to set and exceed your goals. We have global access to several comprehensive teams of mentors, including access to Robert Cialdini (Influence and Persuasion) and John Maxwell (#1 Leadership Expert) and their 80+ years of resources.', img: 'https://lift-leadership.com/wp-content/uploads/lift-leadership-logo-2b.png' },
    { id: 16, name: 'Metafile' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Streamline processes and increase efficiency with MetaViewer document management and accounts payable automation. Experience funding success with ResultsPlus nonprofit constituent relationship management (CRM) solutions. Both are available as installed on-premise or cloud based applications.', img: 'https://metafile.com/img/logo1.png' },
    { id: 17, name: 'Philadelphia Insurance' , category: 'Insurance', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'We design, market, and underwrite commercial Property/Casualty and Professional Liability insurance products and services for select classes of business.', img: 'https://www.phly.com/images/logo.png' },
    { id: 18, name: 'SofterWare/DonorPerfect' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Nonprofits use DonorPerfect Fundraising Software for their Donor Management, Grant & Gift Tracking, Moves Management, Mass Mailing needs and more.', img: 'https://uploads.softerware.com/images/dplogo.png' },
    { id: 19, name: 'ONE HUNDRED AGENCY' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'What if a coalition of marketing agencies united for sustainable change? You’d have us—the world’s first multi-disciplinary service collective for organizations committed to a cause.', img: 'https://resource-alliance.org/wp-content/uploads/2018/09/OneHundredAgency.png' },
    { id: 20, name: 'Crescendo Interactive, Inc.' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Crescendo Interactive is the largest provider of planned giving solutions. With over 30 years of experience, Crescendo has a proven track record of results to help you increase gifts. Contact us to get started with a planned giving website, email, print marketing, analytics, proposal software, training, and more at CrescendoInteractive.com', img: 'https://live-nonprofittimes.pantheonsite.io/wp-content/uploads/2016/01/CrescendoLogo.jpg' },
    { id: 21, name: 'Alexander Haas' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Alexander Haas is widely recognized as one of the leading fundraising consulting firms in the Nation. Our ultimate goal is to help our clients to accomplish and advance their mission by enhancing and expanding their fund raising capacity and the resources available for programs.', img: 'https://fundraisingcounsel.com/wp-content/uploads/2017/05/ah-transforming-1024.png' },
    { id: 20, name: 'Affinity Fundraising Registration' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Up to 41 states require nonprofits to register annually. Learn how to register and maintain compliance for legal fundraising.', img: 'https://www.fundraisingregistration.com/images/CRMlogo.png' },
    { id: 22, name: 'Nonprofits Insurance Alliance (NIA) ' , category: 'Insurance', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Nonprofits Insurance Alliance (NIA) is a group of cooperative 501(c)(3) nonprofit insurance organizations that provide liability and property insurance exclusively to other 501(c)(3) nonprofits.', img: 'https://www.globenewswire.com/news-release/logo/526671/0/526671.png?lastModified=04%2F12%2F2018%2016%3A46%3A21&size=2&v=1469633' },
    { id: 23, name: 'Schultz & Williams' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Nationally recognized, Schultz & Williams (S&W) takes a full-team, integrated approach to helping nonprofits achieve financial stability, meet organization goals and support greater mission impact. Our direct response fundraising and membership campaigns utilize data analytics, digital communications strategies and proven direct mail programs to ensure a client’s greatest potential for success.', img: 'https://nonprofitfederation.org/wp-content/uploads/2018/07/sw_tagline.png' },
    { id: 24, name: 'Salsa Labs' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Best-in-class software built to help nonprofits raise more money for their cause. Unify all marketing, fundraising, & donor management tools with Salsa — Get a free demo! Effortless reporting. Donation tracking. Rich donor profiles. Direct mail fundraising.', img: 'https://www.salsalabs.com/hubfs/1_2018_Salsa_Product_Logos/Salsa-Logo.png' },
    { id: 25, name: 'Marquette University' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Marquette University is a private Jesuit research university in Milwaukee, Wisconsin. Established by the Society of Jesus as Marquette College on August 28, 1881, it was founded by John Martin Henni, the first Bishop of Milwaukee.', img: 'https://media.thenonprofittimes.com/wp-content/uploads/2020/07/17110416/Screen-Shot-2020-07-17-at-11.03.43-AM.png' },
    { id: 26, name: 'Northwestern University - Kellogg' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Join 60K+ Others Who Trust Northwestern to Deliver the Most Opportunities and Development. Curriculum for Tomorrow’s Business Leaders - 100+ Courses Launched in the Last 5 Years. Expansive Alumni Network. Accomplished Faculty. New Global Hub.', img: 'https://i2.wp.com/amylarsoncoaching.com/wp-content/uploads/2017/11/Northwestern-University-Kellogg-School-of-Management-Logo.jpg.html.jpg?ssl=1' },
    { id: 27, name: 'Pearson Embanet' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Pearson online program services: funding, market research, curriculum design, course development, marketing, student recruitment, student support and more.', img: 'https://media.thenonprofittimes.com/wp-content/uploads/2020/07/17110658/Screen-Shot-2020-07-17-at-11.06.47-AM.png' },
    { id: 28, name: 'CFRE-International' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'We are an independent nonprofit organisation whose sole mission is dedicated to setting standards in philanthropy through a valid and reliable certification.', img: 'https://www.cfre.org/wp-content/uploads/2018/10/CFRE_Logo_vector_03.svg' },
    { id: 29, name: 'North Park University' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'As a student at NPU, you can choose from 120+ programs housed in seven colleges and schools, and benefit from a core curriculum designed to forge new and meaningful connections across the sciences, humanities, business, and the arts.', img: 'https://media.thenonprofittimes.com/wp-content/uploads/2020/07/17110822/Screen-Shot-2020-07-17-at-11.08.11-AM.png' },
    { id: 30, name: 'GolfStatus' , category: 'Software & Technology', phone:'(646) 336-4100',  address:'155 W 23rd St, New York, NY 10011', country: 'India', bio: 'Custom websites, online registration, easy hole assignments, live scoring, and more. Streamline tournaments and leagues with simple tech that automates everything. Schedule A Demo. Download Mobile App. Shop Online. Chat Support Available. Find An Event.', img: 'https://images.squarespace-cdn.com/content/563aeb20e4b064c9b546c4f4/1492103399299-8ZBQFHIWF2QHVZ6NSKL3/app-share.jpg?format=1500w&content-type=image%2Fjpeg' },
    { id: 31, name: 'AccuFund' , category: 'Software & Technology', phone:'(800) 893-1074',  address:'400 Hillside Avenue Needham, MA 02494', country: 'https://accufund.com/', bio: 'AccuFund, Inc. provides top-rated financial solutions designated specifically for nonprofit and government entities, available as cloud (software as a service) or on-premise. The Accounting Suite includes all modules organizations expect, plus specialized modules such as Client Accounting, Grants Management and Allocations.', img: 'https://live-nonprofittimes.pantheonsite.io/wp-content/uploads/2016/10/logo-p-accufund-c-01.png' },
  ];

}
