

<nav class="container  text-center">
  <a routerLink="landing" routerLinkActive="landing"><img src="https://www.thenonprofittimes.com/wp-content/themes/twentynineteen-child/nplogo2.png"></a>
 <h1>{{title}}</h1>
 <!--a class="navbar-brand" routerLink="crm" routerLinkActive="crm">Reviews: CRM</a-->
 <a class="navbar-brand" routerLink="crm" routerLinkActive="crm">Fundraising CRM Software</a>
 <!--a class="navbar-brand" routerLink="hr-reviews" routerLinkActive="crm">Reviews: HR</a-->
 <!--a class="navbar-brand" routerLink="hr" routerLinkActive="hr">HR Software</a-->
 <!--a class="navbar-brand" routerLink="crm" routerLinkActive="crm">Reviews: CRM</a-->
 <!--a class="navbar-brand" routerLink="association" routerLinkActive="association">Association Management Software</a-->
<!--a class="navbar-brand" routerLink="crm" routerLinkActive="crm">Reviews: CRM</a-->
 <!--a class="navbar-brand" routerLink="accounting" routerLinkActive="accounting">Accounting Software</a-->


 <button class="btn btn-md btn-outline-success" type="button" (click)="openChatWindow()">Get Expert Advice Now</button>
 <!--a class="navbar-brand" routerLink="crm" routerLinkActive="crm">Assosiation Management Software</a-->
 <!--a class="navbar-brand" routerLink="crm" routerLinkActive="crm">Human Resources Management Software</a-->
 <!--a class="navbar-brand" routerLink="crm" routerLinkActive="crm">Accounting Software </a-->
</nav>

<livechat-widget #liveChatWidget licenseId="12167166" (onChatLoaded)="onChatLoaded($event)" (onChatWindowOpened)="onChatWindowOpened()" (onChatWindowMinimized)="onChatWindowMinimized()" [visitor]="visitor" [params]="params"></livechat-widget>

<router-outlet></router-outlet>

